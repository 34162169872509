import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { clearAlerts } from "../tasks/alertT";
import React, { useEffect } from "react";
import { connect } from "react-redux";

export const Toast = ({ alerts, clearAlerts }) => {
  useEffect(() => {
    // console.log(alerts);
    alerts !== null &&
      alerts.length > 0 &&
      alerts.map((alert) =>
        alert.alertType === "success"
          ? toast.success(alert.msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            })
          : toast.error(alert.msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            })
      );
  }, [alerts]);

  // if (alerts !== null) {
  //    if (alerts.length > 0) {
  //       console.log(alerts);
  //       alerts.map((alert) =>
  //          alert.alertType === "success"
  //             ? toast.success(alert.msg, {
  //                  position: toast.POSITION.TOP_RIGHT,
  //                  autoClose: 5000,
  //               })
  //             : toast.error(alert.msg, {
  //                  position: toast.POSITION.TOP_RIGHT,
  //                  autoClose: 10000,
  //               })
  //       );
  //    }
  // }

  return <ToastContainer className={"sm:w-[300px]  ml-10 mt-4 md:w-[350px]"} />;
};

Toast.propTypes = {
  alerts: PropTypes.array.isRequired,
  clearAlerts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, { clearAlerts })(Toast);

import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Users from "./pages/admin/Users";
import SignUp from "./pages/auth/SignUp";
import Shapes from "./pages/SVGs/Shapes";
import Stores from "./pages/admin/Stores";
import Numbers from "./pages/SVGs/Numbers";
import MsgBox from "./pages/common/MsgBox";
import QRPage from "./pages/common/QRPage";
import StoreReg from "./pages/owner/StoreReg";
import Messages from "./pages/admin/Messages";
import OwnerRoutes from "./utils/OwnerRoutes";
import AdminRoutes from "./utils/AdminRoutes";
import Alphabets from "./pages/SVGs/Alphabets";
import TempMsg from "./pages/locations/TempMsg";
import AnonymousMsg from "./pages/AnonymousMsg";
import Instructions from "./pages/Instructions";
import MsgData from "./pages/franchise/MsgData";
import Profiles from "./pages/locations/Profiles";
import CustomMsg from "./pages/locations/CustomMsg";
import LocationRoutes from "./utils/LocationRoutes";
import OwnerLocRoutes from "./utils/Owner&LocRoutes";
import AdminLocRoutes from "./utils/Admin&LocRoutes";
import OwnerReports from "./pages/owner/OwnerReports";
import LocationInfo from "./pages/owner/LocationInfo";
import FranchiseRoutes from "./utils/FranchiseRoutes";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import MsgTemplate from "./pages/locations/MsgTemplate";
import MyLocations from "./pages/franchise/MyLocations";
import OwnerDashboard from "./pages/owner/OwnerDashboard";
import AdminDashbboard from "./pages/admin/AdminDashbboard";
import ChangePassword from "./pages/common/ChangePassword";
import MessagesLocation from "./pages/locations/MessagesLocation";
import Verification from "./pages/Verification";
import EditInfo from "./pages/owner/EditLocInfo";
import Franchises from "./pages/admin/Franchises";
import FranchisesData from "./pages/admin/FranchisesData";
import Payment from "./pages/Payment";

const routes = [
   {
      path: "/",
      component: <Home />,
      exact: true,
   },
   {
      path: "/payments",
      component: <Payment />,
      exact: true,
   },
   {
      path: "/login",
      component: <Login />,
      exact: true,
   },
   {
      path: "/verify/:token",
      component: <Verification />,
      exact: true,
   },
   {
      path: "/profiles",
      component: (
         <ProtectedRoutes>
            <LocationRoutes>
               <Profiles />
            </LocationRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/msg-template",
      component: (
         <ProtectedRoutes>
            <LocationRoutes>
               <MsgTemplate />
            </LocationRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/qr-page",
      component: (
         <ProtectedRoutes>
            <OwnerLocRoutes>
               <QRPage />
            </OwnerLocRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/template-msg",
      component: (
         <ProtectedRoutes>
            <LocationRoutes>
               <TempMsg />
            </LocationRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/msgs-location",
      component: (
         <ProtectedRoutes>
            <LocationRoutes>
               <MessagesLocation />
            </LocationRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/location/change-password",
      component: (
         <ProtectedRoutes>
            <ChangePassword />
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/custom-msg/:LocationId",
      component: (
         <ProtectedRoutes>
            <LocationRoutes>
               <CustomMsg />
            </LocationRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/anonymous-msg/:LocationId",
      component: (
         // <ProtectedRoutes>
         <AnonymousMsg />
         // </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/instructions",
      component: <Instructions />,
      exact: true,
   },
   {
      path: "/msg-box/:LocationId",
      component: (
         <ProtectedRoutes>
            <LocationRoutes>
               <MsgBox />
            </LocationRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/signup",
      component: <SignUp />,
      exact: true,
   },
   {
      path: "/storeReg",
      component: (
         <ProtectedRoutes>
            <OwnerRoutes>
               <StoreReg />
            </OwnerRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/owner-dash",
      component: (
         <ProtectedRoutes>
            <OwnerRoutes>
               <OwnerDashboard />
            </OwnerRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/owner-reports",
      component: (
         <ProtectedRoutes>
            <OwnerRoutes>
               <OwnerReports />
            </OwnerRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/location-info/:locationId",
      component: (
         <ProtectedRoutes>
            <OwnerRoutes>
               <LocationInfo />
            </OwnerRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/admin-dash",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <AdminDashbboard />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/users",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Users />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/stores",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Stores />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/franchises",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Franchises />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },

   {
      path: "/franchises/data",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <FranchisesData />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },

   {
      path: "/messages",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Messages />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/alphabets",
      component: (
         <ProtectedRoutes>
            <AdminLocRoutes>
               <Alphabets />
            </AdminLocRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/numbers",
      component: (
         <ProtectedRoutes>
            <AdminLocRoutes>
               <Numbers />
            </AdminLocRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/shapes",
      component: (
         <ProtectedRoutes>
            <AdminLocRoutes>
               <Shapes />
            </AdminLocRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/franchise/mylocs",
      component: (
         <ProtectedRoutes>
            <FranchiseRoutes>
               <MyLocations />
            </FranchiseRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
   {
      path: "/franchise/msgs",
      component: (
         <ProtectedRoutes>
            <FranchiseRoutes>
               <MsgData />
            </FranchiseRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },

   {
      path: "/edit-loc-info",
      component: (
         <ProtectedRoutes>
            <OwnerRoutes>
               <EditInfo />
            </OwnerRoutes>
         </ProtectedRoutes>
      ),
      exact: true,
   },
];

export default routes;

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminRoutes = ({ children, loading, isAuthenticated, user }) =>
   !loading && user.role !== "ADMIN" ? <Navigate to="/" /> : children;

AdminRoutes.propTypes = {
   isAuthenticated: PropTypes.bool,
   loading: PropTypes.bool.isRequired,
   user: PropTypes.object,
};

const mapStateToProps = (state) => ({
   isAuthenticated: state.auth.isAuthenticated,
   loading: state.auth.userLoading,
   user: state.auth.user,
});

export default connect(mapStateToProps)(AdminRoutes);

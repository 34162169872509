import { combineReducers } from "redux";
import ANS from "./ANS";
import auth from "./auth";
import users from "./user";
import alert from "./alert";
import profiles from "./profile";
import messages from "./messageR";
import locations from "./location";
import connection from "./connection";
import franchise from "./franchise"
export default combineReducers({
   ANS,
   auth,
   users,
   alert,
   profiles,
   messages,
   locations,
   connection,
   franchise,

});

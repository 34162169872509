import axios from "axios";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";

import TextInput from "./TextInput";
import { LOCAL_SERVER } from "../utils/URL";

const PaypalButton = ({ plan_id }) => {
   const [noOfLocations, setNoOfLocations] = useState(0);

   const navigate = useNavigate();

   const createSubscription = (data, actions) => {
      console.log(noOfLocations);
      return actions.subscription.create({
         plan_id: plan_id, // Replace with the selected plan ID
         quantity: parseInt(noOfLocations),
      });
   };

   const onApprove = async (data, actions) => {
      // Send the plan ID to your server when the payment is approved
      console.log(data);
      try {
         //  setLoading(true);
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };

         const { orderID, subscriptionID, facilitatorAccessToken } = data;
         const body = JSON.stringify({
            orderID,
            subscriptionID,
            facilitatorAccessToken,
         });
         await axios.post(
            LOCAL_SERVER + `api/payments/complete-payment/`,
            body,
            config
         );
         //  setLoading(false);
         toast.success("Subscription Successfull!");
         navigate("/storeReg");
      } catch (error) {
         //  setLoading(false);
         if (error.response) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
         } else {
            console.log(error);
            toast.error(error.message);
         }
      }
   };
   return (
      <div className="w-full flex flex-col gap-y-5 justify-center items-center ">
         <label>Number Of Stores</label>
         <TextInput
            name={"noOfLocations"}
            placeholder={"i.e.7"}
            required={true}
            type={"Number"}
            value={noOfLocations}
            onChange={(e) => setNoOfLocations(parseInt(e.target.value))}
         />
         {noOfLocations > 0 ? (
            <PayPalButtons
               key={noOfLocations}
               style={{
                  disableMaxWidth: true,
                  color: "silver",
                  label: "subscribe",
               }}
               className="w-full"
               createSubscription={createSubscription}
               onApprove={onApprove}
               onError={(err) => console.log(err)}
            />
         ) : (
            <></>
         )}
      </div>
   );
};

export default PaypalButton;

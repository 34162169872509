import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Header from "../../components/Header";
import { setProfile } from "../../tasks/profileT";
import SmallFooter from "../../components/SmallFooter";
import HBD from "../../Assets/images/templates/HBD.png";
import eid from "../../Assets/images/templates/eid.png";
import ester from "../../Assets/images/templates/ester.png";
import andrew from "../../Assets/images/templates/andrew.png";
import george from "../../Assets/images/templates/george.png";
import diwali from "../../Assets/images/templates/diwali.png";
import patrick from "../../Assets/images/templates/patrick.png";
import mehandi from "../../Assets/images/templates/mehandi.png";
import eidUrdu from "../../Assets/images/templates/eidUrdu.png";
import chrismas from "../../Assets/images/templates/chrismas.png";
import mothersday from "../../Assets/images/templates/mothersday.png";
import fathersday from "../../Assets/images/templates/fathersday.png";
import laMultiAni from "../../Assets/images/templates/laMultiAni.png";
import aniverssary from "../../Assets/images/templates/aniverssary.png";
import customMessage from "../../Assets/images/templates/customMessage.png";
import Toast from "../../components/Toast";
import { useWindowSize } from "../../Hooks/useWindowSize";
import useSocket from "../../Hooks/useSocket";
import { toast } from "react-toastify";

const temps = [
   {
      id: 1,
      url: HBD,
      alt: "happy birthday",
   },
   {
      id: 2,
      url: mehandi,
      alt: "Happy mehandi",
   },
   {
      id: 3,
      url: ester,
      alt: "happy easter",
   },
   {
      id: 4,
      url: chrismas,
      alt: "Merry christmas",
   },
   {
      id: 5,
      url: aniverssary,
      alt: "happy aniversary",
   },
   {
      id: 6,
      url: diwali,
      alt: "happy diwali",
   },
   {
      id: 7,
      url: eid,
      alt: "Eid Mubarak",
   },
   {
      id: 8,
      url: george,
      alt: "Happy st.George's day",
   },
   {
      id: 9,
      url: patrick,
      alt: "Happy St.Patrick's day",
   },
   {
      id: 10,
      url: andrew,
      alt: "Happy St.ANdrew's day",
   },
   {
      id: 11,
      url: customMessage,
      alt: "Add a custom Message",
   },
   {
      id: 12,
      url: laMultiAni,
      alt: "La Multi Ani",
   },
   {
      id: 13,
      url: mothersday,
      alt: "happy mother's day",
   },
   {
      id: 14,
      url: fathersday,
      alt: "happy father's day",
   },
   {
      id: 15,
      url: eidUrdu,
      alt: " عید مبارک",
   },
];

function MsgTemplate({ loc, setProfile }) {
   const navigate = useNavigate();
   const { error, myRes, emptyRes } = useSocket();

   useEffect(() => {
      setProfile(localStorage.getItem("profileId"));
      //eslint-disable-next-line
   }, []);

   const onTemplateClick = (e, temp) => {
      if (temp.id === 11) {
         navigate(`/custom-msg/${loc.id}`);
      } else {
         navigate("/template-msg", { state: { tempId: temp.id } });
      }
   };
   const num = temps.length;
   let tempRows = [];
   let row = [];

   const { Width } = useWindowSize();

   for (let i = 0; i < num; i++) {
      row.push(temps[i]);
      if (Width > 1360) {
         if ((i + 1) % 5 === 0 || i === num - 1) {
            tempRows.push(row);
            row = [];
         }
      } else if (Width > 1100) {
         if ((i + 1) % 4 === 0 || i === num - 1) {
            tempRows.push(row);
            row = [];
         }
      } else if (Width > 670) {
         if ((i + 1) % 3 === 0 || i === num - 1) {
            tempRows.push(row);
            row = [];
         }
      } else {
         if ((i + 1) % 2 === 0 || i === num - 1) {
            tempRows.push(row);
            row = [];
         }
      }
   }

   useEffect(() => {
      if (error) {
         toast.error(error.message);
         // setLoadSpin(false);
         console.log("error: " + error.message);
      } else if (myRes) {
         if (myRes.message !== "success") {
            console.log(myRes);
            emptyRes();
            navigate(`/msg-box/${loc.id}`);
         }
      }

      // eslint-disable-next-line
   }, [error, myRes]);

   return (
      <div className="min-h-screen flex flex-col justify-between ">
         <Header showProfile={true} />
         <div className=" py-16 flex flex-grow flex-col gap-y-7 min-h-full justify-center items-center">
            <Toast />
            {tempRows.map((tempRow, rowIndex) => (
               <div
                  key={rowIndex}
                  className="flex h-full items-center justify-center mb-4 gap-x-3"
               >
                  {tempRow.map((temp) => (
                     <img
                        onClick={(e) => onTemplateClick(e, temp)}
                        key={temp.id}
                        src={temp.url}
                        alt={temp.alt}
                        className="hover:cursor-pointer sm:h-[33vw] sm:w-[35vw] sm-md:h-[30vw] sm-md:w-[30vw] md:h-auto md:w-auto"
                     />
                  ))}
               </div>
            ))}
         </div>
         <SmallFooter />
      </div>
   );
}

MsgTemplate.propTypes = {
   loc: PropTypes.object,
   setProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loc: state.auth.user,
});

export default connect(mapStateToProps, { setProfile })(MsgTemplate);

import {
   LOGIN_FAILED,
   LOGIN_SUCCESS,
   USER_LOADED,
   SET_LOADING,
   LOGOUT,
   AUTH_ERROR,
   USER_ERROR,
} from "../tasks/types";

const initialState = {
   token: localStorage.getItem("token"),
   loading: true,
   isAuthenticated: false,
   userLoading: true,
   user: null,
   error: null,
   authError: null,
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case SET_LOADING:
         return {
            ...state,
            loading: payload,
         };
      case LOGIN_SUCCESS:
         if (payload.user.role === "OWNER") {
            if (payload.user.verifiedEmail) {
               localStorage.setItem("token", payload.token);
               localStorage.setItem("user", payload.user.role);
               return {
                  ...state,
                  token: payload.token,
                  isAuthenticated: true,
                  loading: false,
                  user: payload.user,
               };
            } else {
               return {
                  ...state,
                  loading: false,
                  user: payload.user,
               };
            }
         } else {
            localStorage.setItem("token", payload.token);
            localStorage.setItem("user", payload.user.role);
            return {
               ...state,
               token: payload.token,
               isAuthenticated: true,
               loading: false,
               user: payload.user,
            };
         }

      case USER_LOADED:
         return {
            ...state,
            loading: false,
            isAuthenticated: true,
            userLoading: false,
            user: payload.user,
            authError: null,
         };
      case USER_ERROR:
         localStorage.removeItem("token");
         localStorage.removeItem("user");
         return {
            ...state,
            // token: null,
            // loading: false,
            // userLoading: false,
            // user: null,
            authError: payload,
         };
      case LOGIN_FAILED:
      case AUTH_ERROR:
      case LOGOUT:
         localStorage.removeItem("token");
         localStorage.removeItem("user");
         return {
            ...state,
            token: null,
            loading: false,
            isAuthenticated: false,
            userLoading: false,
            user: null,
            error: payload,
         };

      default:
         return state;
   }
}

export const SOCKET_URL = "https://app.write-message.com/";
// export const SOCKET_URL = "http://localhost:5000/";
// export const SOCKET_URL = "http://192.168.8.176:5000/";
// export const SOCKET_URL = "http://192.168.100.32:5000/";

export const SERVER_URL = "https://app.write-message.com/";

export const CLIENT_URL = "https://www.write-message.com/";

export const LOCAL_SERVER = "https://app.write-message.com/";
// export const LOCAL_SERVER = "http://localhost:5000/";
// export const LOCAL_SERVER = "http://192.168.8.176:5000/";
// export const LOCAL_SERVER = "http://192.168.100.32:5000/";

import axios from "axios";
import { USERS, USERS_ERROR } from "./types";
// import { setAlert } from "./alertT";
import { LOCAL_SERVER } from "../utils/URL";

export const loadAllUsers = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/users/");
      console.log(res);
      dispatch({
         type: USERS,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: USERS_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GiCancel } from "react-icons/gi";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import { loadShapes } from "../../tasks/ANST";
import useSocket from "../../Hooks/useSocket";
import Spinner from "../../components/Spinner";
import { LOCAL_SERVER } from "../../utils/URL";
import { setProfile } from "../../tasks/profileT";
import TextInput from "../../components/TextInput";
import SmallFooter from "../../components/SmallFooter";

import none from "../../Assets/images/none.png";
import HBD from "../../Assets/images/templates/HBD.png";
import eid from "../../Assets/images/templates/eid.png";
import ester from "../../Assets/images/templates/ester.png";
import andrew from "../../Assets/images/templates/andrew.png";
import george from "../../Assets/images/templates/george.png";
import diwali from "../../Assets/images/templates/diwali.png";
import patrick from "../../Assets/images/templates/patrick.png";
import mehandi from "../../Assets/images/templates/mehandi.png";
import eidUrdu from "../../Assets/images/templates/eidUrdu.png";
import chrismas from "../../Assets/images/templates/chrismas.png";
import mothersday from "../../Assets/images/templates/mothersday.png";
import fathersday from "../../Assets/images/templates/fathersday.png";
import laMultiAni from "../../Assets/images/templates/laMultiAni.png";
import aniverssary from "../../Assets/images/templates/aniverssary.png";
import customMessage from "../../Assets/images/templates/customMessage.png";
import { titleCase } from "../../utils/TitleCase";

// import { format } from "date-fns";

const temps = [
   {
      id: 1,
      url: HBD,
      pre: "Happy",
      ocaisan: "Birthday",
      alt: "happy birthday",
   },
   {
      id: 2,
      url: mehandi,
      pre: "Mehandi",
      ocaisan: "Mubarak",
      alt: "Mehandi Mubarak",
   },
   {
      id: 3,
      url: ester,
      pre: "Happy",
      ocaisan: "Easter",
      alt: "happy ester",
   },
   {
      id: 4,
      url: chrismas,
      pre: "Merry",
      ocaisan: "Christmas",
      alt: "Merry christmas",
   },
   {
      id: 5,
      url: aniverssary,
      pre: "Happy",
      ocaisan: "Anniversary",
      alt: "happy aniversary",
   },
   {
      id: 6,
      url: diwali,
      pre: "Happy",
      ocaisan: "Dewali",
      alt: "happy diwali",
   },
   {
      id: 7,
      url: eid,
      pre: "Eid",
      ocaisan: "Mubarak",
      alt: "Eid Mubarak",
   },
   {
      id: 8,
      url: george,
      pre: "Happy",
      ocaisan: "St.George's day",
      alt: "Happy st.George's day",
   },
   {
      id: 9,
      url: patrick,
      pre: "Happy",
      ocaisan: "St.Patrick's day",
      alt: "Happy St.Patrick's day",
   },
   {
      id: 10,
      url: andrew,
      pre: "Happy",
      ocaisan: "St.Andrew's day",
      alt: "Happy St.Andrew's day",
   },
   {
      id: 11,
      url: customMessage,
      pre: "noothing",
      ocaisan: "nnothing",
      alt: "Add a custom Message",
   },
   {
      id: 12,
      url: laMultiAni,
      pre: "La Multi",
      ocaisan: "Ani",
      alt: "La Multi Ani",
   },
   {
      id: 13,
      url: mothersday,
      pre: "Happy",
      ocaisan: "Mother's Day",
      alt: "happy mother's day",
   },
   {
      id: 14,
      url: fathersday,
      pre: "Happy",
      ocaisan: "Father's Day",
      alt: "happy father's day",
   },
   {
      id: 15,
      url: eidUrdu,
      pre: " عید ",
      ocaisan: "مبارک",
      alt: "Eid Mubarak in Urdu",
   },
];

const TempMsg = ({
   ANS,
   loading,
   userLoading,
   profile,
   setProfile,
   location,
   connection,
   loadShapes,
   loc,
}) => {
   const imgRef = useRef(null);
   const [loadSpin, setLoadSpin] = useState(false);
   const [myMsg, setMyMsg] = useState("");
   const [myMsgH, setMyMsgH] = useState("");
   const [call, setCall] = useState(false);
   const [pre, setPre] = useState("casual");
   const [allowCon, setAllowCon] = useState(true);
   const [ocaison, setOcaison] = useState("Message");
   const [load, setLoad] = useState(true);
   const [secondName, setSecondName] = useState(false);
   const [msgName, setMsgName] = useState(false);
   const [name2, setName2] = useState("");
   const [nameCon, setNameCon] = useState("");
   const [nameCon2, setNameCon2] = useState("");
   const [data, setData] = useState({
      type: "MSG",
      messenger: "LOCATION",
      font: "font-Monotype_Corsiva",
      today: null,
      msg: "",
      // name: "",
      training: false,
      // shapeName: "",
      // shapeId: "",
      // profileId: "",
      // profileName: "",
   });
   useEffect(() => {
      loadShapes();
      if (!userLoading) {
         if (profile) {
            setData({
               ...data,
               profileName: profile.name,
               profileId: profile.id,
            });
         }
      }
      if (tempId) {
         for (let i = 0; i < temps.length; i++) {
            if (tempId === i + 1) {
               setPre(temps[i].pre);
               setOcaison(temps[i].ocaisan);
            }
         }
      }
      // eslint-disable-next-line
   }, [loadShapes, loading]);

   useEffect(() => {
      setProfile(localStorage.getItem("profileId"));
      //eslint-disable-next-line
   }, []);
   const navigate = useNavigate();
   const { error, myRes, emitEvent, emptyRes } = useSocket();
   const locat = useLocation();
   const { state } = locat;
   const tempId = state?.tempId;

   const { font, name, training, today } = data;

   const onChange = (e) => {
      if (e.target.name === "training") {
         setData({ ...data, [e.target.name]: !training });
      } else if (e.target.name === "name") {
         setData({ ...data, [e.target.name]: titleCase(e.target.value) });
      } else {
         setData({ ...data, [e.target.name]: e.target.value });
      }
   };

   const [tomdate, setTopmdate] = useState("");

   const onChangeToday = () => {
      const vv = new Date().toDateString();
      setData({ ...data, today: vv });
   };

   const onChangeTomorrow = () => {
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setTopmdate(tomorrow);
      setData({ ...data, today: tomorrow });
   };

   const handleMyMsg = (e) => {
      let newValue = e.target.value;
      // Check if the input is a valid number (0 or positive)
      if (/^\d*$/.test(newValue)) {
         setMyMsg(newValue);

         if (newValue !== "11" && newValue !== "12" && newValue !== "13") {
            if (newValue.endsWith("1")) {
               newValue = newValue + "st";
            } else if (newValue.endsWith("2")) {
               newValue = newValue + "nd";
            } else if (newValue.endsWith("3")) {
               newValue = newValue + "rd";
            } else {
               newValue = newValue + "th";
            }
         } else {
            newValue = newValue + "th";
         }

         // switch (newValue) {
         //   case "1":
         //     newValue = "1st";
         //     break;
         //   case "2":
         //     newValue = "2nd";
         //     break;
         //   case "3":
         //     newValue = "3rd";
         //     break;

         //   default:
         //     newValue = newValue + "th";
         //     break;
         // }

         setMyMsgH(newValue);
      }
   };

   const handleShapeChange = (e) => {
      if (ANS) {
         const shape = ANS.find((x) => x.value === e.target.value);
         if (shape) {
            setData({ ...data, shapeName: shape.name, shapeId: shape.id });
            imgRef.current.src = LOCAL_SERVER + e.target.value;
         } else {
            setData({ ...data, shapeName: "", shapeId: "" });
            imgRef.current.src = none;
         }
      }
   };

   const onSubmit = (e) => {
      e.preventDefault();
      if (today !== null) {
         setLoadSpin(true);
         if (!msgName) {
            if (allowCon) {
               if (name !== nameCon || name2 !== nameCon2) {
                  setLoadSpin(false);
                  toast.error("Your Names do not match!");
               } else {
                  let m = "";
                  if (secondName) {
                     m =
                        pre +
                        " " +
                        myMsgH +
                        " " +
                        ocaison +
                        " " +
                        titleCase(name) +
                        " " +
                        "&" +
                        " " +
                        titleCase(name2);
                  } else {
                     m = pre + " " + myMsgH + " " + ocaison + " " + name;
                  }

                  setData({ ...data, msg: m });
               }
            } else {
               let m = "";
               if (secondName) {
                  m =
                     pre +
                     " " +
                     myMsgH +
                     " " +
                     ocaison +
                     " " +
                     titleCase(name) +
                     " " +
                     "&" +
                     " " +
                     titleCase(name2);
               } else {
                  m =
                     pre + " " + myMsgH + " " + ocaison + " " + titleCase(name);
               }

               setData({ ...data, msg: m });
            }
         } else {
            let m = pre + " " + myMsgH + " " + ocaison;

            setData({ ...data, msg: m });
         }
         setCall(!call);
      } else {
         toast.error("Please specify the day");
      }
   };

   useEffect(() => {
      if (data.msg !== "") {
         // if (call) {
         // console.log("connection : " + connection);
         if (connection) {
            if (data) {
               emitEvent("add-message", {
                  locationId: location.id,
                  myMsg: data,
               });
               setLoad(false);
            }
         } else {
            setLoadSpin(false);
            setTimeout(() => {
               toast.error(
                  " Realtime connection problem, Please Wait until the connection is back online!"
               );
            }, 1000);
         }
         // setCall(false);
         // }
      }
      // eslint-disable-next-line
   }, [call]);

   useEffect(() => {
      if (!load) {
         if (error) {
            toast.error(error.message);
            setLoadSpin(false);
            console.log("error: " + error.message);
         } else {
            navigate(`/msg-box/${location.id}`, { replace: true });
         }
      }
      // eslint-disable-next-line
   }, [error, myRes]);

   const handleAddName = (e) => {
      e.preventDefault();
      setSecondName(!secondName);
   };

   useEffect(() => {
      if (error) {
         toast.error(error.message);
         // setLoadSpin(false);
         console.log("error: " + error.message);
      } else if (myRes) {
         if (myRes.message !== "success") {
            emptyRes();
            navigate(`/msg-box/${location.id}`, { replace: true });
         }
      }

      // eslint-disable-next-line
   }, [error, myRes]);

   return loading || loadSpin ? (
      <Spinner />
   ) : (
      <div className="min-h-screen  flex flex-col">
         <Header showProfile={true} />

         <div className="flex py-[5vh] flex-grow flex-col justify-center items-center">
            <Toast />

            <form className="bg-white sm:p-[5vh] py-10 flex flex-col items-center justify-center rounded-lg shadow-xl-all drop-shadow-2xl shadow-black gap-y-4 sm:w-5/6 md:w-6/12 overflow-x-hidden ">
               <div className="flex flex-row w-full  text-primary ">
                  <div className="flex w-full justify-center ">
                     <select
                        name="font"
                        onChange={(e) => onChange(e)}
                        value={font}
                        className="text-black border-2 w-4/6 border-neutral-500 rounded-md"
                     >
                        <option value="" className="text-black text-sm ">
                           Select a Font{" "}
                        </option>
                        <option
                           value="font-Sail"
                           className="text-black text-sm "
                        >
                           Sail
                        </option>
                        <option
                           value="font-Gabriel"
                           className="text-black text-sm "
                        >
                           Gabriel
                        </option>
                        <option
                           value="font-Monotype_Corsiva"
                           className="text-black text-sm "
                        >
                           Monotype Corsiva
                        </option>
                        <option
                           value="font-Walt_Disney_Script"
                           className="text-black text-sm "
                        >
                           Walt Disney Scrip
                        </option>
                     </select>
                  </div>
                  <GiCancel
                     className="h-10 w-10 rounded-full hover:cursor-pointer"
                     onClick={(e) => navigate("/msg-template")}
                  />
               </div>
               <div className="w-full h-full flex flex-col items-center gap-y-4">
                  <div className="flex justify-around sm:flex-col px5:flex-row  w-4/6">
                     <div>
                        <input
                           type="radio"
                           name="today"
                           checked={data.today === new Date().toDateString()}
                           defaultValue={true}
                           onChange={() => onChangeToday()}
                           className="hover:cursor-pointer"
                        />

                        <span className="ml-2 text-gray-700 text-[16px] font-semibold">
                           Today
                        </span>
                     </div>
                     <div>
                        <input
                           type="radio"
                           name="today"
                           checked={data.today === tomdate}
                           defaultValue={false}
                           onChange={() => onChangeTomorrow()}
                           className="hover:cursor-pointer"
                        />
                        <span className="ml-2 text-gray-700 text-[16px] font-semibold">
                           Tomorrow
                        </span>
                     </div>
                     <div>
                        <input
                           type="checkbox"
                           name="check"
                           value="option1"
                           checked={allowCon}
                           onChange={(e) => setAllowCon(!allowCon)}
                        />
                        <span className="ml-2 text-gray-7 text-[16px] font-semibold">
                           Confirm Name
                        </span>
                     </div>
                  </div>

                  <label className="w-full flex  justify-center text-3xl text-black font-bold">
                     {pre}
                  </label>

                  {tempId !== 2 ? (
                     <div className="flex flex-row text-xs w-full bg-white justify-center">
                        <div className="sm:w-2/6 sm-md:w-1/6">
                           <TextInput
                              classnames={"text-center text-base h-4"}
                              hidden={false}
                              name={"age"}
                              placeholder={"Number"}
                              type={"text"}
                              value={myMsg}
                              required={false}
                              onChange={(e) => handleMyMsg(e)}
                           />
                        </div>
                     </div>
                  ) : (
                     <></>
                  )}
                  <label className="w-full  flex text-3xl justify-center text-black font-bold">
                     {ocaison}
                  </label>

                  {!msgName ? (
                     <div className="flex flex-col gap-y-4 sm:w-5/6 sm-md:w-3/6 items-center">
                        <TextInput
                           classnames={"h-5"}
                           placeholder="Name here"
                           name={"name"}
                           hidden={false}
                           required={false}
                           type={"text"}
                           value={name}
                           onChange={(e) => onChange(e)}
                        ></TextInput>
                        {allowCon ? (
                           <TextInput
                              classnames={"h-5"}
                              placeholder="Confirm Name"
                              name={"nameR"}
                              hidden={false}
                              required={false}
                              type={"text"}
                              value={nameCon}
                              onChange={(e) =>
                                 setNameCon(titleCase(e.target.value))
                              }
                           ></TextInput>
                        ) : (
                           <></>
                        )}
                        {secondName ? (
                           <>
                              <h1 className="text-5xl font-bold">&</h1>
                              <TextInput
                                 classnames={"h-4"}
                                 placeholder="2nd Name here"
                                 name={"name2"}
                                 hidden={false}
                                 required={false}
                                 type={"text"}
                                 value={name2}
                                 onChange={(e) =>
                                    setName2(titleCase(e.target.value))
                                 }
                              ></TextInput>
                              {allowCon ? (
                                 <TextInput
                                    classnames={"h-4"}
                                    placeholder="Confirm Name"
                                    name={"nameR"}
                                    hidden={false}
                                    required={false}
                                    type={"text"}
                                    value={nameCon2}
                                    onChange={(e) =>
                                       setNameCon2(titleCase(e.target.value))
                                    }
                                 ></TextInput>
                              ) : (
                                 <></>
                              )}
                           </>
                        ) : (
                           <></>
                        )}

                        <button
                           onClick={(e) => handleAddName(e)}
                           className="bg-primary text-white rounded-lg py-1 px-3 text-lg font-semibold hover:cursor-pointer hover:bg-neutral-700 duration-300"
                        >
                           {secondName ? "Remove Name" : "Add Name"}
                        </button>
                     </div>
                  ) : (
                     <></>
                  )}

                  <div className="flex justify-around w-full sm:flex-col px5:flex-row ">
                     <div>
                        <input
                           type="checkbox"
                           name="check"
                           value="option1"
                           checked={msgName}
                           onChange={(e) => setMsgName(!msgName)}
                        />
                        <span className="ml-2 text-[16px] font-semibold text-gray-700 ">
                           Continue without name
                        </span>
                     </div>
                     <div>
                        <input
                           type="checkbox"
                           name="training"
                           checked={training}
                           onChange={(e) => onChange(e)}
                        />
                        <span className="ml-2 text-gray-700 text-[16px] font-semibold">
                           Training
                        </span>
                     </div>
                  </div>

                  <div className="flex flex-row text-base w-full justify-around gap-x-4">
                     <div className="sm:w-full px5:w-2/6 flex items-center">
                        <select
                           name="shape"
                           className=" py-2 px-2 w-full border-2 border-neutral-500 rounded-md"
                           onChange={(e) => handleShapeChange(e)}
                        >
                           <option value={"none"}>{"None"}</option>
                           {ANS &&
                              ANS.map((shape) => (
                                 <option value={shape.value} key={shape.id}>
                                    {shape.name}
                                 </option>
                              ))}
                        </select>
                     </div>

                     <div className="flex sm:w-full px5:w-1/5 px5:h-1/5 bg-black p-5 rounded-lg">
                        <img
                           ref={imgRef}
                           src={none}
                           alt="shape"
                           className="flex h-full w-full"
                        />
                     </div>
                  </div>

                  <button
                     onClick={(e) => onSubmit(e)}
                     className="bg-primary px-4 py-1 text-lg rounded-md hover:cursor-pointer hover:bg-sky-700 duration-300 xs:w-2/6 text-white font-bold mb-1"
                  >
                     Submit Message
                  </button>
               </div>
            </form>
         </div>
         <SmallFooter />
      </div>
   );
};

TempMsg.propTypes = {
   loading: PropTypes.bool,
   ANS: PropTypes.array,
   profile: PropTypes.object,
   location: PropTypes.object,
   loadShapes: PropTypes.func.isRequired,
   userLoading: PropTypes.bool,
   setProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   userLoading: state.auth.userLoading,
   loading: state.ANS.loading,
   ANS: state.ANS.ANS,
   profile: state.profiles.currentProfile,
   location: state.auth.user,
   connection: state.connection.connection,
   loc: state.auth.user,
});

export default connect(mapStateToProps, { loadShapes, setProfile })(TempMsg);

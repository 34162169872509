import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { FiImage } from "react-icons/fi";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import TextInput from "../../components/TextInput";
import { addLocation } from "../../tasks/locationT";
import SmallFooter from "../../components/SmallFooter";
// import { useWindowSize } from "../../Hooks/useWindowSize";
import { toast } from "react-toastify";

const StoreReg = ({ user, addLocation }) => {
   // const { Width } = useWindowSize();
   // const height = window.innerHeight;

   const [img, setImg] = useState(null);
   const [formdata, setFormdata] = useState({
      userName: "",
      email: "",
      phone: "",
      password: "",
      re_password: "",
      address: "",
      franchiseName: "",
   });

   const navigate = useNavigate();
   const {
      userName,
      email,
      phone,
      password,
      re_password,
      address,
      franchiseName,
   } = formdata;

   const onChange = (e) => {
      if (e.target.name === "franchiseName") {
         if (e.target.value.includes(" ")) {
            const s = e.target.value.replace(/ /g, "");
            setFormdata({ ...formdata, [e.target.name]: s });
         } else {
            setFormdata({ ...formdata, [e.target.name]: e.target.value });
         }
      } else {
         setFormdata({ ...formdata, [e.target.name]: e.target.value });
      }
   };

   const handleFileChange = (event) => {
      setImg(event.target.files[0]);
   };

   const data = new FormData();
   const onSubmit = (e) => {
      e.preventDefault();
      if (img) {
         data.append("Img", img);
      }

      if (formdata.password === formdata.re_password) {
         data.append("userName", userName);
         data.append("email", email);
         data.append("phone", phone);
         data.append("password", password);
         data.append("address", address);
         data.append("franchiseName", franchiseName);
         addLocation(data, navigate);
      } else {
         toast.error("Passwords do not match");
      }
   };
   return (
      <div className="flex flex-col  min-h-screen">
         <Header />
         <div
            className="flex  bg-login flex-grow bg-cover justify-center items-center 
            sm:py-5 
            px15:py-16
            "
         >
            <Toast />
            <form
               className="flex flex-col bg-white  rounded-lg justify-center items-center
               sm:p-5 
               md:py-16
               md:px-16
               sm:gap-y-5
                "
               onSubmit={(e) => onSubmit(e)}
            >
               <h1
                  className="text-primary  font-semibold font-kodch
                  sm:text-[7vw]
                  md:text-[3vw]"
               >
                  Store Registeration
               </h1>
               <div className="flex pl-16 sm-md:justify-center gap-x-3 gap-y-3   sm:flex-col sm-md:flex-row">
                  <h1 className="font-semibold  text-primary text-xl">
                     Add Company Logo:
                  </h1>
                  <input
                     type="file"
                     accept="image/*"
                     name="Img"
                     onChange={handleFileChange}
                     className=""
                  />
               </div>

               <div className="w-9/12 gap-y-5 flex flex-col items-center justify-center">
                  <TextInput
                     type={"text"}
                     name={"userName"}
                     value={userName}
                     onChange={(e) => onChange(e)}
                     placeholder={"Store Location"}
                  />
                  <TextInput
                     type={"email"}
                     placeholder={"Email"}
                     name={"email"}
                     value={email}
                     onChange={(e) => onChange(e)}
                  />
                  <TextInput
                     type={"text"}
                     placeholder={"Franchise Name"}
                     name={"franchiseName"}
                     value={franchiseName}
                     onChange={(e) => onChange(e)}
                  />
                  <TextInput
                     type={"text"}
                     placeholder={"Store Phone Number"}
                     name={"phone"}
                     value={phone}
                     onChange={(e) => onChange(e)}
                  />

                  <TextInput
                     type={"password"}
                     placeholder={"Password"}
                     name={"password"}
                     value={password}
                     required={true}
                     hidden={true}
                     onChange={(e) => onChange(e)}
                  />

                  <TextInput
                     type={"password"}
                     placeholder={"Confirm Password"}
                     name={"re_password"}
                     value={re_password}
                     required={true}
                     hidden={true}
                     onChange={(e) => onChange(e)}
                  />

                  <textarea
                     type="text"
                     placeholder="Address"
                     name="address"
                     value={address}
                     required
                     onChange={(e) => onChange(e)}
                     className="p-3 h-full  w-full border-2 border-neutral-400 outline-none rounded-lg"
                  />

                  {/* {Width > 1000 ? (
                        <>
                           <div
                              className="flex w-full justify-center 
                        sm:flex-col 
                        md:flex-row px17:py-4
                        "
                           >
                              <div className="flex flex-col md:gap-y-3 px17:gap-y-5 justify-center   w-1/2  px-5 ml-5 py-0 ">
                                 <TextInput
                                    type={"text"}
                                    name={"userName"}
                                    value={userName}
                                    onChange={(e) => onChange(e)}
                                    placeholder={"Store Name"}
                                 />
                                 <TextInput
                                    type={"text"}
                                    placeholder={"Contact Number"}
                                    name={"phone"}
                                    value={phone}
                                    onChange={(e) => onChange(e)}
                                 />
                              </div>

                              <div className="flex flex-col md:gap-y-3 px17:gap-y-5 justify-center  w-1/2  px-5 mr-5 py-0 ">
                                 <TextInput
                                    type={"emai"}
                                    placeholder={"Email"}
                                    name={"email"}
                                    value={email}
                                    onChange={(e) => onChange(e)}
                                 />
                                 <TextInput
                                    type={"password"}
                                    placeholder={"Password"}
                                    name={"password"}
                                    value={password}
                                    required={true}
                                    hidden={true}
                                    onChange={(e) => onChange(e)}
                                 />
                              </div>
                           </div>
                        </>
                     ) : (
                        <>
                           <div className="flex flex-col w-full justify-center ">
                              <div
                                 className="flex flex-col  h-full w-full gap-y-5 justify-center px-5 
                              sm:py-5
                              sm-md:gap-y-7"
                              >
                                 <TextInput
                                    type={"text"}
                                    name={"userName"}
                                    value={userName}
                                    onChange={(e) => onChange(e)}
                                    placeholder={"Store Name"}
                                 />
                                 <TextInput
                                    type={"emai"}
                                    placeholder={"Email"}
                                    name={"email"}
                                    value={email}
                                    onChange={(e) => onChange(e)}
                                 />
                                 <TextInput
                                    type={"text"}
                                    placeholder={"Store Number"}
                                    name={"phone"}
                                    value={phone}
                                    onChange={(e) => onChange(e)}
                                 />

                                 <TextInput
                                    type={"password"}
                                    placeholder={"Password"}
                                    name={"password"}
                                    value={password}
                                    required={true}
                                    hidden={true}
                                    onChange={(e) => onChange(e)}
                                 />
                              </div>
                           </div>
                        </>
                     )} */}

                  {/* <div className="h-20 flex justify-center sm:w-full   md:px-5">
                        <textarea
                           type="text"
                           placeholder="Address"
                           name="address"
                           value={address}
                           required
                           onChange={(e) => onChange(e)}
                           className="mr-5 ml-5  mb-5 p-3 h-full  w-full border-2 border-neutral-400 outline-none rounded-lg"
                        />
                     </div> */}

                  <input
                     type="submit"
                     className="bg-primary rounded-md font-kodch font-semibold p-1 px-3 text-white hover:cursor-pointer hover:bg-blue-500 "
                  />
               </div>
            </form>
         </div>
         <SmallFooter />
      </div>
   );
};

StoreReg.propTypes = {
   user: PropTypes.object,
   addLocation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   user: state.auth.user,
});

export default connect(mapStateToProps, { addLocation })(StoreReg);

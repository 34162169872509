export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_LOADING = "SET_LOADING";
export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_ERROR = "USER_ERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const USER_LOADED = "USER_LOADED";
export const LOCATIONS = "LOCATIONS";
export const LOCATIONS_ERROR = "LOCATIONS_ERROR";
export const LOCATIONS_INIT_STATE = "LOCATIONS_INIT_STATE";
export const PROFILES = "PROFILES";
export const PROFILE_IMAGES = "PROFILE_IMAGES";
export const CURRENT_PROFILES = "CURRENT_PROFILES";
export const PROFILES_ERROR = "PROFILES_ERROR";
export const PROFILES_INIT_STATE = "PROFILES_INIT_STATE";
export const FIGURES = "FIGURES";
export const FIGURES_ERROR = "FIGURES_ERROR";
export const FIGURES_INIT_STATE = "FIGURES_INIT_STATE";
export const MESSAGES = "MESSAGES";
export const MESSAGES_ERROR = "MESSAGES_ERROR";
export const USERS = "USERS";
export const USERS_ERROR = "USERS_ERROR";
export const SET_CONNECTION = "SET_CONNECTION";
export const DASH_DATA = "DASH_DATA";
export const FRANCHISES = "FRANCHISES";
export const FRANCHISES_INIT_STATE = "FRANCHISES_INIT_STATE";
export const FRANCHISE_LOCATIONS = "FRANCHISE_LOCATIONS";
export const FRANCHISE_ERROR = "FRANCHISE_ERROR";
export const FRANCHISE_MSGS = "FRANCHISE_MSGS";
export const UPDATE_FRANCHISES = "UPDATE_FRANCHISES";

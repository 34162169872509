import {
   LOCATIONS,
   LOCATIONS_ERROR,
   LOCATIONS_INIT_STATE,
} from "../tasks/types";

const initialState = {
   locations: null,
   loading: true,
   error: {},
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case LOCATIONS:
         return {
            ...state,
            locations: payload.locations,
            loading: false,
         };
      case LOCATIONS_INIT_STATE:
         return {
            ...initialState,
         };
      case LOCATIONS_ERROR:
         return {
            ...state,
            error: payload,
            loading: false,
         };
      default:
         return state;
   }
}

import React from "react";

const Button = ({ className, title, onClick }) => {
  return (
    <button
      onClick={(e) => onClick(e)}
      className={`bg-primary rounded-[45px]  flex justify-center item-center px-5 py-2  text-text-white   md:font-[700px] sm:font-[500px] md:text-xl  sm:text-[15px] font-kodch hover:bg-blue-500 ${className} `}
    >
      {title}
    </button>
  );
};

export default Button;

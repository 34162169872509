import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";

import { loadAllMessages } from "../../tasks/messageT";
import SmallFooter from "../../components/SmallFooter";
import Header from "../../components/Header";
import Toast from "../../components/Toast";
import Table from "../../components/Table";
import Spinner from "../../components/Spinner";

const COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Messenger",
    accessor: "messenger",
  },
  {
    Header: "Message",
    accessor: "msg",
  },
  {
    Header: "Location Id",
    accessor: "LocationId",
  },
  {
    Header: "Time",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return format(new Date(value), "dd/MM/yyyy hh:mm");
    },
  },
];

const Messages = ({ loading, msgs, loadAllMessages }) => {
  const [numberOptions] = useState([10, 15, 20, 30]);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(
    () => msgs,
    // eslint-disable-next-line
    [loading]
  );
  const eventHandler = () => {};
  useEffect(() => {
    loadAllMessages();
  }, [loadAllMessages]);
  return loading ? (
    <Spinner />
  ) : (
    <div className="w-screen h-screen flex flex-col flex-grow overflow-x-hidden">
      <Header />
      <Toast />
      <div className="h-full pb-0 flex flex-col justify-between py-10 overflow-y-scroll overflow-x-hidden">
        <Table
          columns={columns}
          data={data}
          numberOptions={numberOptions}
          eventHandler={eventHandler}
        />
      </div>
      <SmallFooter />
    </div>
  );
};

Messages.propTypes = {
  loading: PropTypes.bool,
  msgs: PropTypes.array,
  loadAllMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.messages.loading,
  msgs: state.messages.msgs,
});

export default connect(mapStateToProps, { loadAllMessages })(Messages);

import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";
import Table from "../../components/Table";

import Spinner from "../../components/Spinner";
import { loadMessage } from "../../tasks/franchiseT";

const COLUMNS = [
   {
      Header: "Type",
      accessor: "type",
   },
   {
      Header: "Messenger",
      accessor: "messenger",
   },
   {
      Header: "Submitted date",
      accessor: "today",
      Cell: ({ value }) => {
         return format(new Date(value), "dd/MM/yyyy");
      },
   },
   {
      Header: "Message",
      accessor: "msg",
   },
   {
      Header: "Name",
      accessor: "name",
   },

   {
      Header: "Training",
      accessor: "training",
      Cell: ({ value }) => {
         return value.toString();
      },
   },

   {
      Header: "Shape Name",
      accessor: "shapeName",
   },

   {
      Header: "Guest Email",
      accessor: "guestEmail",
   },
   {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ value }) => {
         return format(new Date(value), "dd/MM/yyyy");
      },
   },
];

const MsgData = ({ loading, msgs, loadMessage }) => {
   const [numberOptions] = useState([10, 15, 20, 30]);
   const columns = useMemo(() => COLUMNS, []);
   const data = useMemo(
      () => msgs,
      // eslint-disable-next-line
      [loading, msgs]
   );

   useEffect(() => {
      loadMessage();
   }, [loadMessage]);
const eventHandler=()=>{}
   return loading ? (
      <Spinner />
   ) : (
      <div className="w-screen h-screen flex flex-col flex-grow overflow-x-hidden">
         <Header />
         <div className=" flex h-full flex-col justify-between py-5 overflow-y-scrool">
            {msgs && msgs.length > 0 ? (
               <Table
                  columns={columns}
                  data={data ? data : <Spinner />}
                  numberOptions={numberOptions}
                  eventHandler={eventHandler}
               />
            ) : (
               <div>No data!</div>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

// export default MsgData;
MsgData.propTypes = {
   loading: PropTypes.bool,
   msgs: PropTypes.array,
   loadMessage: PropTypes.func.isRequired,
};

const mapStateToProops = (state) => ({
   loading: state.franchise.msgLoading,
   msgs: state.franchise.msgs,
});

export default connect(mapStateToProops, { loadMessage })(MsgData);

import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";

import routes from "./router";
import { loadUser } from "./tasks/authT";
import setAuthToken from "./utils/setAuthToken";
import InActivityMoniter from "./components/InActivityMoniter";
// import useSocket from "./Hooks/useSocket";

const App = ({ loadUser, isAuthenticated, user }) => {
   // const { emitEvent } = useSocket();
   if (localStorage.token) {
      setAuthToken(localStorage.token);
   }

   useEffect(() => {
      // if (localStorage.token) {
      loadUser();
      // }
      // if (user) {
      //    if (user.role === "LOCATION") {
      //       emitEvent("login", { locationId: user.id });
      //    }s
      // }
      //eslint-disable-next-line
   }, [loadUser, isAuthenticated]);
   return (
      <HashRouter>
         <InActivityMoniter>
            <Routes>
               {routes.map((route) => (
                  <Route
                     exact
                     key={route.path}
                     path={route.path}
                     element={route.component}
                  />
               ))}
            </Routes>
         </InActivityMoniter>
      </HashRouter>
   );
};

App.propTypes = {
   loadUser: PropTypes.func.isRequired,
   isAuthenticated: PropTypes.bool,
   user: PropTypes.object,
};

const mapStateToProps = (state) => ({
   isAuthenticated: state.auth.isAuthenticated,
   user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(App);

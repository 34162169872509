import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../tasks/authT";
import logo from "../Assets/images/Write Message 1.svg";
import WMlogo from "../Assets/images/WMlogo.png";
import hamburger from "../Assets/images/Hamburger.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { LOCAL_SERVER } from "../utils/URL";

const SmallHeader = ({ links, isAuthenticated, user, logout }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setOpen(!open);
  };

  const handleAuthClick = () => {
    setAuth(!auth);
  };

  return (
    <>
      <div className="flex  justify-between items-center px-6  w-full shadow-xl">
        {isAuthenticated ? (
          <img
            className="sm:w-14 sm:h-12"
            src={WMlogo}
            alt="logo"
            // onClick={(e) => navigate("/")}
          />
        ) : (
          <img
            className="sm:w-32 sm:h-16"
            src={logo}
            alt="logo"
            // onClick={(e) => navigate("/")}
          />
        )}

        {open ? (
          <div
            className={`transition-all z-50 duration-500 -translate-x-6 top-[60px] ease-in-out absolute flex flex-col justify-center items-center w-full bg-neutral-400 bg-opacity-90 py-2 rounded-md ${
              open ? "opacity-100 scale-100" : "opacity-0 scale-0"
            }`}
            style={{ transformOrigin: "top" }}
          >
            {links.map((item) => {
              return (
                <li
                  className="inline-block  hover:text-neutral-500 hover:cursor-pointer font-kodch font-semibold text-xl text-primary py-2"
                  key={item.name}
                >
                  <h1
                    className="hover:cursor-pointer"
                    // onClick={(e) => navigate(item.link)}

                    onClick={
                      location.pathname === "/" && !isAuthenticated
                        ? item.onclick
                        : () => navigate(item.link)
                    }
                  >
                    {item.name}
                  </h1>
                </li>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {isAuthenticated ? (
          <>
            <div className="w-full h-full flex justify-end ">
              <div className="flex border-4 border-primary rounded-full overflow-hidden hover:cursor-pointer h-12 w-12 mr-5">
                <img
                  onClick={handleAuthClick}
                  alt="profile"
                  src={LOCAL_SERVER + user.Img}
                  className="flex h-12 w-12"
                />
              </div>
              {auth ? (
                <div
                  className={` z-50 transition-all duration-500 ease-in-out translate-x-14 top-[60px] absolute flex flex-col justify-center items-center w-full  bg-neutral-400 bg-opacity-90 py-2 rounded-md ${
                    auth ? "opacity-100 scale-100" : "opacity-0 scale-0"
                  }`}
                  style={{ transformOrigin: "top" }}
                >
                  <li className="flex flex-col  font-kodch font-semibold text-xl text-primary py-2">
                    <h1 className="py-5 hover:text-neutral-500 hover:cursor-pointer">
                      My Profile
                    </h1>
                    <h1
                      className="py-5 hover:text-neutral-500 hover:cursor-pointer"
                      onClick={(e) => logout(navigate)}
                    >
                      Logout
                    </h1>
                  </li>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className="flex gap-x-3 w-full justify-end 
                  sm:mt-2 sm:mr-3
                  px450:mt-0 px450:mr-4 px450:gap-x-4 
                   "
            >
              <button
                onClick={() => navigate("/signup")}
                className="font-kodch text-blue font-[700px] bg-primary px-3 py-1 text-white text-md rounded-md
                        sm:text-[9px] 
                        px450:text-[12px] "
              >
                Sign Up
              </button>
              <button
                onClick={() => navigate("/login")}
                className="font-kodch text-blue font-[700px] bg-primary px-3 py-1 text-white text-md rounded-md
                        sm:text-[9px] 
                        px450:text-[12px] "
              >
                Login
              </button>
            </div>
          </>
        )}
        <img
          className=" h-[50px] w-[35px] mt-3 hover:cursor-pointer
               px450:h-[58px] px450:mt-0
               "
          src={hamburger}
          onClick={handleClick}
          alt="logo"
        />
      </div>
      {/* {open ? (
            <div
               className={`transition-all z-50 duration-500 mt-20 ease-in-out absolute flex flex-col justify-center items-center w-full md:mt-32 bg-neutral-400 bg-opacity-90 py-2 rounded-md ${
                  open ? "opacity-100 scale-100" : "opacity-0 scale-0"
               }`}
               style={{ transformOrigin: "top" }}
            >
               {links.map((item) => {
                  return (
                     <li
                        className="inline-block  hover:text-neutral-500 hover:cursor-pointer font-kodch font-semibold text-xl text-primary py-2"
                        key={item.name}
                     >
                        <h1
                           className="hover:cursor-pointer"
                           onClick={(e) => navigate(item.link)}
                        >
                           {item.name}
                        </h1>
                     </li>
                  );
               })}
            </div>
         ) : (
            ""
         )} */}
    </>
  );
};

SmallHeader.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(SmallHeader);

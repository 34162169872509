import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Country } from "country-state-city";
// import { FiImage } from "react-icons/fi";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../components/Spinner";
import { addUser } from "../../tasks/authT";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import TextInput from "../../components/TextInput";
import Toast from "../../components/Toast";

import { useWindowSize } from "../../Hooks/useWindowSize";
import Select from "react-select";

function SignUp({ addUser }) {
   const { Width } = useWindowSize();

   const [countries, setCountries] = useState([]);
   // const [selectedImage, setSelectedImage] = useState(null);
   // const [selectedCountry, setSelectedCountry] = useState("");
   const [img, setImg] = useState(null);
   const [loading, setLoading] = useState(false);
   // const fileInputRef = useRef(null);
   const [data, setData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      conformPassword: "",
      country: "",
      city: "",
      phone: "",
      organization: "",
      numberOfLocations: "",
   });

   useEffect(() => {
      setCountries(Country.getAllCountries());
   }, []);

   const navigate = useNavigate();
   const handleFileChange = (event) => {
      setImg(event.target.files[0]);
      // if (event.target.files[0]) {
      //    const reader = new FileReader();
      //    reader.onload = () => {
      //       setSelectedImage(reader.result);
      //    };
      //    reader.readAsDataURL(event.target.files[0]);
      // }
   };

   // const handleLogoButtonClick = () => {
   //    fileInputRef.current.click();
   // };

   const {
      firstName,
      lastName,
      email,
      password,
      conformPassword,
      organization,
      numberOfLocations,
      country,
      city,
      phone,
   } = data;

   const onChange = (e) => {
      if (e.target.name === "organization") {
         setData({
            ...data,
            [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
         });
      } else {
         setData({ ...data, [e.target.name]: e.target.value });
      }
   };

   const handleStoreNumberChange = (e) => {
      const inputValue = e.target.value;

      // Check if the input is a positive number
      if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
         setData({ ...data, numberOfLocations: inputValue });
      } else {
         toast.error("Please enter a positive number");
      }
   };

   const formData = new FormData();

   const onSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      if (password === conformPassword) {
         // navigate("/storeReg");
         if (img) {
            formData.append("Img", img);
         }

         formData.append("firstName", firstName);
         formData.append("lastName", lastName);
         formData.append("email", email);
         formData.append("password", password);
         formData.append("organization", organization);
         formData.append("noOfLocations", numberOfLocations);
         formData.append("country", country);
         formData.append("city", city);
         formData.append("contactNo", phone);
         addUser(formData, navigate, setLoading);
      } else {
         setLoading(false);
         toast.error("Your Passwords do not match!", {
            position: toast.POSITION.TOP_RIGHT,
         });
      }
   };

   const countryOptions = countries.map((country) => ({
      value: country.isoCode,
      label: country.flag + "  " + country.name,
      flg: country.flag,
   }));
   const handleCountryChange = (selectedOption) => {
      const isoCode = selectedOption.value;
      const myCountry = countries.find(
         (country) => country.isoCode === isoCode
      );
      setData({
         ...data,
         country: myCountry.name,
         phone: myCountry.phonecode,
      });
   };

   return loading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex h-full flex-col  min-h-screen">
            <Header />
            <div className="flex bg-login flex-grow bg-cover justify-center items-center md:py-10 sm:py-5 ">
               <Toast />
               <form
                  className="flex flex-col  bg-white rounded-xl justify-center items-center gap-y-6 pt-5"
                  onSubmit={(e) => onSubmit(e)}
                  encType="multipart/form-data"
               >
                  <h1
                     className="text-primary font-semibold 
                     sm:text-[7vw]
                     md:text-[3vw]"
                  >
                     Register Now
                  </h1>
                  <div className="flex  justify-center items-center gap-x-5 gap-y-3   sm:flex-col sm-md:flex-row">
                     <h1 className="font-semibold text-primary">
                        Add an Image:
                     </h1>
                     <input
                        type="file"
                        accept="image/*"
                        name="Img"
                        onChange={handleFileChange}
                        className=""
                     />
                  </div>
                  {/* <input
                           type="file"
                           accept="image/*"
                           onChange={handleFileChange}
                           className="hidden"
                           ref={fileInputRef}
                        />
                        {selectedImage ? (
                           <img
                              src={selectedImage}
                              alt="Selected"
                              onClick={handleLogoButtonClick}
                              className="rounded-full md:h-52 md:w-52 sm:h-32  sm:w-32  mx-20  hover:cursor-pointer"
                           />
                        ) : (
                           <div
                              onClick={handleLogoButtonClick}
                              className="flex rounded-full md:h-52 md:w-52 sm:h-32  sm:w-32  mx-20 justify-center  hover:cursor-pointer items-center bg-neutral-600 text-white"
                           >
                              <FiImage className="h-10 w-10" />
                           </div>
                        )} */}

                  {Width > 1200 ? (
                     <>
                        <div className="flex md:flex-row sm:flex-col h-full w-full">
                           <div className="flex flex-col h-full md:w-1/2 sm:w-full md:px-10 md:ml-10 md:py-10 md:gap-y-5 sm:px-5 sm:py-5 sm:gap-y-3">
                              <TextInput
                                 type={"text"}
                                 name={"firstName"}
                                 value={firstName}
                                 onChange={(e) => onChange(e)}
                                 placeholder={"First Name"}
                              />
                              <TextInput
                                 type={"emai"}
                                 placeholder={"Email"}
                                 name={"email"}
                                 value={email}
                                 onChange={(e) => onChange(e)}
                              />

                              <Select
                                 name="country"
                                 value={country.isoCode}
                                 options={countryOptions}
                                 isSearchable={true}
                                 onChange={handleCountryChange}
                                 className="w-full  border-2 border-neutral-400 rounded-md outline-none"
                              />

                              {/* <input
                                 type="text"
                                 placeholder="Filter countries"
                                 value={filterText}
                                 onChange={handleFilterTextChange}
                              /> */}

                              {/* <select
                                 name="country"
                                 value={
                                    // selectedCountry.flag +
                                    // "  " +
                                    selectedCountry.name
                                 }
                                 onChange={(e) => onChangeCountry(e)}
                                 className="w-full px-2 py-2 border-2 border-neutral-400 rounded-md outline-none"
                              >
                                 {filteredCountries.map((country) => (
                                    <option
                                       key={country.isoCode}
                                       value={country.isoCode}
                                    >
                                       {country.name}
                                    </option>
                                 ))}
                              </select> */}

                              <TextInput
                                 type={"text"}
                                 placeholder={"Contact Number"}
                                 name={"phone"}
                                 value={phone}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"password"}
                                 placeholder={"Password"}
                                 name={"password"}
                                 value={password}
                                 required={true}
                                 hidden={true}
                                 onChange={(e) => onChange(e)}
                              />
                           </div>

                           <div className="flex flex-col h-full md:w-1/2 sm:w-full md:px-10 md:mr-10  md:py-10 md:ml-5 md:gap-y-5 sm:px-10 sm:py-0 sm:pb-5 sm:gap-y-3">
                              <TextInput
                                 type={"text"}
                                 placeholder={"Last Name"}
                                 name={"lastName"}
                                 value={lastName}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"text"}
                                 placeholder={"Franchise Company Name"}
                                 name={"organization"}
                                 value={organization}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"text"}
                                 placeholder={"City"}
                                 name={"city"}
                                 value={city}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"number"}
                                 placeholder={"Number of Stores"}
                                 name={"numberOfLocations"}
                                 value={numberOfLocations}
                                 onChange={(e) => handleStoreNumberChange(e)}
                              />
                              <TextInput
                                 type={"password"}
                                 placeholder={"Confirm Password"}
                                 name={"conformPassword"}
                                 value={conformPassword}
                                 required={true}
                                 hidden={true}
                                 onChange={(e) => onChange(e)}
                              />
                           </div>
                        </div>
                     </>
                  ) : (
                     <>
                        <div className="flex md:flex-row sm:flex-col h-full w-full sm-md:px-16 ">
                           <div className="flex flex-col h-full  sm:w-full sm:px-5 sm:py-5 sm:gap-y-3 sm-md:gap-y-5">
                              <TextInput
                                 type={"text"}
                                 name={"firstName"}
                                 value={firstName}
                                 onChange={(e) => onChange(e)}
                                 placeholder={"First Name"}
                              />
                              <TextInput
                                 type={"text"}
                                 placeholder={"Last Name"}
                                 name={"lastName"}
                                 value={lastName}
                                 onChange={(e) => onChange(e)}
                              />

                              <TextInput
                                 type={"emai"}
                                 placeholder={"Email"}
                                 name={"email"}
                                 value={email}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"text"}
                                 placeholder={"Organization Name"}
                                 name={"organization"}
                                 value={organization}
                                 onChange={(e) => onChange(e)}
                              />

                              <Select
                                 name="country"
                                 value={country.isoCode}
                                 options={countryOptions}
                                 isSearchable={true}
                                 onChange={handleCountryChange}
                                 className="w-full  border-2 border-neutral-400 rounded-md outline-none"
                              />

                              {/* <select
                                 name="country"
                                 value={
                                    // selectedCountry.flag +
                                    // "  " +
                                    selectedCountry.name
                                 }
                                 onChange={(e) => onChangeCountry(e)}
                                 className="w-full px-2 py-2 border-2 border-neutral-400 rounded-md outline-none"
                              >
                                 <option value="">{"Country"}</option>
                                 {countries.map((option) => (
                                    <option
                                       key={option.isoCode}
                                       value={option.isoCode}
                                    >
                                       {option.flag + "  " + option.name}
                                    </option>
                                 ))}
                              </select> */}

                              <TextInput
                                 type={"text"}
                                 placeholder={"City"}
                                 name={"city"}
                                 value={city}
                                 onChange={(e) => onChange(e)}
                              />

                              <TextInput
                                 type={"text"}
                                 placeholder={"Contact Number"}
                                 name={"phone"}
                                 value={phone}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"number"}
                                 placeholder={"Number of Stores"}
                                 name={"numberOfLocations"}
                                 value={numberOfLocations}
                                 onChange={(e) => handleStoreNumberChange(e)}
                              />

                              <TextInput
                                 type={"password"}
                                 placeholder={"Password"}
                                 name={"password"}
                                 value={password}
                                 required={true}
                                 hidden={true}
                                 onChange={(e) => onChange(e)}
                              />
                              <TextInput
                                 type={"password"}
                                 placeholder={"Confirm Password"}
                                 name={"conformPassword"}
                                 value={conformPassword}
                                 required={true}
                                 hidden={true}
                                 onChange={(e) => onChange(e)}
                              />
                           </div>

                           {/* <div className="flex flex-col h-full md:w-1/2 sm:w-full md:px-10 md:mr-10  md:py-10 md:ml-5 md:gap-y-5 sm:px-5 sm:py-0 sm:pb-5 sm:gap-y-3">
                           <TextInput
                              type={"text"}
                              placeholder={"Last Name"}
                              name={"lastName"}
                              value={lastName}
                              onChange={(e) => onChange(e)}
                           />
                           <TextInput
                              type={"text"}
                              placeholder={"Organization Name"}
                              name={"organization"}
                              value={organization}
                              onChange={(e) => onChange(e)}
                           />
                           <TextInput
                              type={"text"}
                              placeholder={"City"}
                              name={"city"}
                              value={city}
                              onChange={(e) => onChange(e)}
                           />
                           <TextInput
                              type={"number"}
                              placeholder={"Number of Stores"}
                              name={"numberOfLocations"}
                              value={numberOfLocations}
                              onChange={(e) => onChange(e)}
                           />
                           <TextInput
                              type={"password"}
                              placeholder={"Conform Password"}
                              name={"conformPassword"}
                              value={conformPassword}
                              required={true}
                              hidden={true}
                              onChange={(e) => onChange(e)}
                           />
                        </div> */}
                        </div>
                     </>
                  )}

                  <input
                     type="submit"
                     className="bg-primary py-2 px-5 rounded-md font-kodch font-semibold text-xl text-white hover:cursor-pointer hover:bg-blue-500 mb-10"
                  />
               </form>
            </div>
            <SmallFooter />
         </div>
      </>
   );
}

SignUp.propTypes = {
   addUser: PropTypes.func.isRequired,
};

export default connect(null, { addUser })(SignUp);

import {
   PROFILES,
   CURRENT_PROFILES,
   PROFILE_IMAGES,
   PROFILES_ERROR,
   PROFILES_INIT_STATE,
} from "../tasks/types";

const initialState = {
   loading: true,
   profiles: null,
   images: null,
   imagesLoading: true,
   currentProfile: null,
   profileLoading: true,
   error: {},
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case PROFILES:
         return {
            ...state,
            profiles: payload.profiles,
            loading: false,
         };
      case PROFILE_IMAGES:
         return {
            ...state,
            images: payload.profileImgs,
            imagesLoading: false,
         };
      case CURRENT_PROFILES:
         return {
            ...state,
            currentProfile: payload,
            profileLoading: false,
         };
      case PROFILES_INIT_STATE:
         return {
            ...initialState,
         };
      case PROFILES_ERROR:
         return {
            ...state,
            error: payload,
            loading: false,
         };
      default:
         return state;
   }
}

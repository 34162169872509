import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GiCancel } from "react-icons/gi";
import React, { useEffect, useState } from "react";
import { PiMinusBold, PiPlusBold } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";

import useSocket from "../../Hooks/useSocket";
import { LOCAL_SERVER } from "../../utils/URL";
import { getDifferenceFromPast } from "../../utils/TimeHelpers";
import Draggable from "react-draggable";
const MsgBox = ({ location, loading }) => {
   const { LocationId } = useParams();
   const { error, myRes, msg, emitEvent, emptyRes } = useSocket();
   const [navBack, setNavBack] = useState(true);
   const [box, setBox] = useState({});
   const [time, setTime] = useState(10);
   const [key, setKey] = useState(1);
   const [trig, setTrig] = useState(0);
   const [disMsg, setDisMsg] = useState("");
   const [wholeMsg, setWholeMsg] = useState(null);
   const [words, setWords] = useState([]);
   const [shapeId, setShapeId] = useState(-1);
   const [font, setFont] = useState("");
   const [shapeAdd, setShapeAdd] = useState("");
   const [zoomLevel, setZoomLevel] = useState(1);
   const navigate = useNavigate();
   useEffect(() => {
      if (!loading) {
         // console.log("calling get box");
         emitEvent("get-box", { locationId: LocationId });
         if (error) {
            console.log("error: " + error.message);
         }
      }
      //eslint-disable-next-line
   }, [loading, error, key]);

   useEffect(() => {
      if (myRes) {
         if (myRes.box) {
            console.log("settingb");
            setBox(myRes.box);
            emptyRes();
         } else if (myRes.msg) {
            if (myRes.msg.shapeId) {
               setShapeId(myRes.msg.shapeId);
            }
            setDisMsg(myRes.msg.msg);
            setWholeMsg(myRes.msg);
            setFont(myRes.msg.font);
            setNavBack(false);
            emptyRes();
         } else if (myRes.shape) {
            setShapeAdd(LOCAL_SERVER + myRes.shape.value);
            console.log(shapeAdd);
            emptyRes();
         } else {
            setDisMsg("");
            setWholeMsg(null);
            setShapeAdd("");
            emptyRes();
            // console.log("setting empty msg");
         }
      }
      //eslint-disable-next-line
   }, [myRes]);

   useEffect(() => {
      if (box) {
         // if (box !== {}) {
         if (box.currentMessageID) {
            // if (myRes.box.currentMessageID) {
            // console.log(box);
            let t = getDifferenceFromPast(box.currentMessageTime);
            // console.log("calculated time : " + t);
            setTime(t);
            // setTime(12000);
            emitEvent("get-msg", { msgId: box.currentMessageID });
         } else {
            if (navBack) {
               setNavBack(false);
               console.log("no box.currMsg setting empy msg");
               setDisMsg("");
               setShapeAdd("");
            } else {
               navigate("/msg-template", { replace: true });
            }
         }
         // }
         // }
      }
      // eslint-disable-next-line
   }, [box]);

   useEffect(() => {
      if (shapeId !== -1) {
         emitEvent("get-fig", { shapeId: shapeId });
      }
      let arrayOfStrings = [];
      if (disMsg) {
         // console.log(disMsg);
         arrayOfStrings = disMsg.split(" ");
         setWords(arrayOfStrings);
      } else {
         arrayOfStrings.push("");
         setWords(arrayOfStrings);
      }

      // console.log(arrayOfStrings);

      //eslint-disable-next-line
   }, [disMsg]);

   useEffect(() => {
      if (msg) {
         // console.log("here");
         if (msg.box.currentMessageID) {
            // console.log(msg.box.currentMessageID);
            setBox(msg.box);
         } else {
            console.log("exiting");
            navigate("/msg-template", { replace: true });
         }
      }
      // eslint-disable-next-line
   }, [msg]);

   const handleTimerEnd = () => {
      // setTime(10);
      setKey((prevKey) => prevKey + 1);
      // setTrig(!trig);
   };

   const handleCenter = (e) => {
      setTrig(trig + 1);
   };

   const handleZoomIn = () => {
      if (zoomLevel + 0.1 < 3.6) {
         setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
         // console.log(zoomLevel);
      }
   };

   const handleZoomOut = () => {
      if (zoomLevel - 0.1 > 0.1) {
         setZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
      }
   };

   const handleCancel = (e) => {
      emitEvent("erase", { locationId: LocationId });
      // navigate("/msg-template", { replace: true });
   };

   window.onhashchange = function () {
      emitEvent("erase", { locationId: LocationId });
      navigate(-1);
   };

   return (
      <div className="flex flex-col h-screen w-screen bg-neutral-950 text-white justify-center items-start overflow-hidden">
         <div className="w-full flex justify-between border-b-2 border-white text-white px-4 z-50 bg-neutral-950">
            <div className="flex flex-row items-center sm:gap-x-3 sm-md:gap-x-5">
               {box.nextMessageID ? (
                  <div className="bg-primary rounded-3xl text-white font-semibold text-xl flex justify-center items-center px-4 my-4 py-2">
                     1 Msg in que
                  </div>
               ) : (
                  <div className="bg-primary rounded-3xl text-white font-semibold sm:text-sm sm-md:text-xl flex justify-center items-center sm:px-2 sm-md:px-4 my-4 py-2">
                     Que is Empty
                  </div>
               )}
               <button
                  onClick={(e) => handleCenter(e)}
                  className="bg-primary rounded-3xl text-white font-semibold sm:text-sm sm-md:text-xl flex justify-center items-center px-4 my-4 py-2 hover:bg-neutral-600 duration-300"
               >
                  Center
               </button>
               <PiPlusBold
                  onClick={handleZoomIn}
                  className=" px500:block  sm:m-0 sm:h-8 sm:w-8 sm:py-0   sm-md:m-2 sm-md:h-16 sm-md:w-16 sm-md:py-2 text-white hover:cursor-pointer"
               >
                  Zoom In
               </PiPlusBold>
               <PiMinusBold
                  onClick={handleZoomOut}
                  className=" px500:block  sm:m-0 sm:h-8 sm:w-8 sm:py-0   sm-md:m-2 sm-md:h-16 sm-md:w-16 sm-md:py-2 text-white hover:cursor-pointer"
               >
                  Zoom Out
               </PiMinusBold>
            </div>
            <div className="flex flex-row sm-md:gap-x-5 py-2">
               <CountdownTimer
                  key={key}
                  seconds={time}
                  onTimerEnd={handleTimerEnd}
               />
               {/* <Redirect */}
               <GiCancel
                  className="sm:h-10 sm:w-10 sm:p-1 sm:m-2 sm-md:h-14 sm-md:w-14 sm-md:p-1 sm-md:m-2 bg-rose-700 rounded-full hover:cursor-pointer"
                  onClick={(e) => handleCancel(e)}
               />
            </div>
         </div>
         <div
            key={key}
            className="h-full w-full flex flex-col justify-center items-center transform transition-transform duration-300 z-0 bg-neutral-950 overflow-hidden"
            // style={{ transform: `scale(${zoomLevel})` }}
         >
            {wholeMsg && wholeMsg.type === "MSG" ? (
               words.length > 0 ? (
                  words.map((x, index) => (
                     <>
                        <DraggableText
                           key={index}
                           text={x}
                           zoomLevel={zoomLevel}
                           font={font}
                           trig={trig}
                           startPosition={{ x: -850, y: index * 40 - 170 }}
                        />
                        {index + 1 === words.length && shapeAdd !== "" ? (
                           <DraggableImage
                              key={shapeAdd}
                              imgSrc={shapeAdd}
                              zoomLevel={zoomLevel}
                              trig={trig}
                              startPosition={{ x: 0, y: 0 }}
                           />
                        ) : (
                           <></>
                        )}
                     </>
                  ))
               ) : (
                  <h1 className={`text-7xl font-semibold`}>No Messages</h1>
               )
            ) : shapeAdd !== "" ? (
               <DraggableImage
                  key={shapeAdd}
                  imgSrc={shapeAdd}
                  zoomLevel={zoomLevel}
                  trig={trig}
                  startPosition={{ x: -180, y: 1 * 90 }}
               />
            ) : (
               <h1 className={`sm:text-[13vw] px8:text-7xl font-semibold`}>
                  No Messages
               </h1>
            )}
         </div>
      </div>
   );
};

MsgBox.proopTypes = {
   location: PropTypes.object,
   loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
   location: state.auth.user,
   loading: state.auth.userLoading,
});

export default connect(mapStateToProps)(MsgBox);

const CountdownTimer = ({ seconds, onTimerEnd }) => {
   const [countdown, setCountdown] = useState(seconds);

   useEffect(() => {
      let interval;
      if (countdown > 0) {
         interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
         }, 1000); // Update every 1 second (1000ms)
      } else {
         // Call the event function when the countdown reaches zero
         onTimerEnd();
      }

      return () => {
         clearInterval(interval); // Clear the interval on component unmount
      };
   }, [countdown, onTimerEnd]);

   // Helper function to format the remaining time
   const formatTime = (seconds) => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins.toString().padStart(2, "0")}:${secs
         .toString()
         .padStart(2, "0")}`;
   };

   useEffect(() => {
      // Reset the countdown whenever the seconds prop changes
      setCountdown(seconds);
   }, [seconds]);

   return (
      <div className="bg-primary flex justify-center items-center sm:px-4 sm-md:px-6 my-4 rounded-2xl text-xl font-semibold text-white">
         {formatTime(countdown)}
      </div>
   );
};

const DraggableText = ({ text, startPosition, trig, font, zoomLevel }) => {
   const [position, setPosition] = useState(startPosition);
   const [dragging, setDragging] = useState(false);
   const [startPos, setStartPos] = useState({ x: 0, y: 0 });

   useEffect(() => {
      setPosition(startPosition);
      handleResetPosition();
      //eslint-disable-next-line
   }, [trig]);

   const handleDragStart = (e) => {
      e.preventDefault();
      if (e.type === "touchstart") {
         setStartPos({
            x: e.touches[0].clientX - position.x,
            y: e.touches[0].clientY - position.y,
         });
      } else {
         setStartPos({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
         });
      }
      setDragging(true);
   };

   const handleDrag = (e) => {
      e.preventDefault();
      if (!dragging) return;
      if (e.type === "touchmove") {
         setPosition({
            x: e.touches[0].clientX - startPos.x,
            y: e.touches[0].clientY - startPos.y,
         });
      } else {
         setPosition({
            x: e.clientX - startPos.x,
            y: e.clientY - startPos.y,
         });
      }
   };

   const handleDragEnd = () => {
      setDragging(false);
   };

   const targetRef = React.useRef(null);

   useEffect(() => {
      const target = targetRef.current;
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mouseup", handleDragEnd);

      if (target) {
         target.addEventListener("touchmove", handleDrag);
         target.addEventListener("touchend", handleDragEnd);

         return () => {
            window.removeEventListener("mousemove", handleDrag);
            window.removeEventListener("mouseup", handleDragEnd);
            target.removeEventListener("touchmove", handleDrag);
            target.removeEventListener("touchend", handleDragEnd);
         };
      }
      //eslint-disable-next-line
   }, [dragging]);
   const bounds = "parent";
   const [controlledPosition, setControlledPosition] = useState(position);

   const handleStop = (e, data) => {
      setControlledPosition({ x: data.x, y: data.y });
   };

   const handleResetPosition = () => {
      setControlledPosition({ x: 0, y: 0 });

      if (setPosition) {
         setPosition({ x: 0, y: 0 });
      }
   };

   return (
      <Draggable
         bounds={bounds}
         position={controlledPosition}
         defaultPosition={{ x: 0, y: 0 }}
         onStop={handleStop}
      >
         <div>
            <div
               ref={targetRef}
               style={{
                  userSelect: "none",
                  cursor: dragging ? "grabbing" : "grab",
                  transform: `scale(${zoomLevel})`,
                  transition: dragging ? "none" : "transform 0.0s ease",
               }}
            >
               <h1
                  className={`text-5xl font-semibold ${font}`}
                  onMouseDown={handleDragStart}
                  onTouchStart={handleDragStart}
               >
                  {text}
               </h1>
            </div>
         </div>
      </Draggable>
   );
};

const DraggableImage = ({ imgSrc, startPosition, trig, zoomLevel }) => {
   const [position, setPosition] = useState(startPosition);
   const [dragging, setDragging] = useState(false);
   const [startPos] = useState({ x: 0, y: 0 });

   useEffect(() => {
      setPosition(startPosition);
      handleResetPosition();
      //eslint-disable-next-line
   }, [trig]);

   // const handleDragStart = (e) => {
   //    e.preventDefault();
   //    if (e.type === "touchstart") {
   //       setStartPos({
   //          x: e.touches[0].clientX - position.x,
   //          y: e.touches[0].clientY - position.y,
   //       });
   //    } else {
   //       setStartPos({
   //          x: e.clientX - position.x,
   //          y: e.clientY - position.y,
   //       });
   //    }
   //    setDragging(true);
   // };

   const handleDrag = (e) => {
      e.preventDefault();
      if (!dragging) return;
      if (e.type === "touchmove") {
         setPosition({
            x: e.touches[0].clientX - startPos.x,
            y: e.touches[0].clientY - startPos.y,
         });
      } else {
         setPosition({
            x: e.clientX - startPos.x,
            y: e.clientY - startPos.y,
         });
      }
   };

   const handleDragEnd = () => {
      setDragging(false);
   };

   const targetRef = React.useRef(null);

   useEffect(() => {
      const target = targetRef.current;
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mouseup", handleDragEnd);

      if (target) {
         target.addEventListener("touchmove", handleDrag);
         target.addEventListener("touchend", handleDragEnd);

         return () => {
            window.removeEventListener("mousemove", handleDrag);
            window.removeEventListener("mouseup", handleDragEnd);
            target.removeEventListener("touchmove", handleDrag);
            target.removeEventListener("touchend", handleDragEnd);
         };
      }
      //eslint-disable-next-line
   }, [dragging]);
   const bounds = "parent";
   const [controlledPosition, setControlledPosition] = useState(position);

   const handleStop = (e, data) => {
      setControlledPosition({ x: data.x, y: data.y });
   };

   const handleResetPosition = () => {
      setControlledPosition({ x: 0, y: 0 });

      if (setPosition) {
         setPosition({ x: 0, y: 0 });
      }
   };

   return (
      <Draggable
         bounds={bounds}
         defaultPosition={{ x: 0, y: 0 }}
         position={controlledPosition}
         onStop={handleStop}
      >
         <div
            ref={targetRef}
            // className="draggable-container relative transform transition-transform duration-300"
            style={{
               userSelect: "none",
               cursor: dragging ? "grabbing" : "grab",
               transform: `scale(${zoomLevel})`,
               height: "5%",
               width: "5%",
               transition: dragging ? "none" : "transform 0.1s ease",
            }}
         >
            <div
               style={{
                  // transform: `scale(${zoomLevel})`,
                  transition: dragging ? "none" : "transform 0.0s ease",
               }}
            >
               <img
                  src={imgSrc}
                  alt="Draggable"
                  className="w-full h-full object-cover"
                  style={{
                     transform: `scale(${zoomLevel})`,
                  }}
               />
            </div>
         </div>
      </Draggable>
   );
};

import { useState, useEffect } from "react";

function ImageSlider({ children, show }) {
  const [currentValue, setCurrentValue] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const [time, setTime] = useState(5000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentValue((prevValue) => (prevValue + 1) % children.length);
    }, time);

    return () => clearInterval(interval);
  }, [children.length, time]);

  const slideStyles = {
    position: "relative",
    animation: "Animations 5s infinite",
  };
const clicindex=(index)=>{

  setCurrentValue(index);
  setTime(5000);
}
  return (
    <div className="h-full w-full flex flex-col  md:pb-24">
      <div
        className="flex justify-center items-center h-full w-full  "
        onMouseEnter={() => setShowArrows(true)}
        onMouseLeave={() => setShowArrows(false)}
      >
        <div
          className=" h-full w-full flex justify-center items-center"
          style={slideStyles}
        >
          {show ? (
            <button
              className={`text-white absolute top-1/2 left-4 transform -translate-y-1/2 p-5 cursor-pointer hover:bg-gray-200 hover:bg-opacity-25 text-2xl ${
                showArrows ? "" : "hidden"
              }`}
              onClick={() => {
                setCurrentValue(
                  (prevVal) => (prevVal + children.length - 1) % children.length
                );
                setTime(5000);
              }}
              style={{ zIndex: 1 }}
            >
              ❮
            </button>
          ) : (
            <></>
          )}

          {children.map((item, index) => (
            <div
              className={`w-full h-full flex justify-center items-center ${
                currentValue === index ? "block" : "hidden"
              }`}
              key={index}
              style={{
                animation: `Animations${currentValue} 5s infinite`,
                position: "absolute",
              }}
            >
              <style>
                {` @keyframes Animations${currentValue} {
                                       0% { left: 100%; top: 0px; }
                                      15% { left: 0%; top: 0px; }
                                      50% { left: 0%; top: 0px; }
                                      85% { left: 0%; top: 0px; } 
                                     100% { left:-100%; top: 0px; } 
                                    }`}
              </style>
              {item}
            </div>
          ))}
          {show ? (
            <button
              className={`text-white absolute top-1/2 right-4 transform -translate-y-1/2 p-5 cursor-pointer hover:bg-gray-200  hover:bg-opacity-25 text-2xl
                    ${showArrows ? "" : "hidden"}`}
              onClick={() =>
                setCurrentValue((prevVal) => (prevVal + 1) % children.length)
              }
            >
              {" "}
              ❯{" "}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex flex-row gap-x-2 md:mt-4 sm:mt-0 justify-center ">
        {children.map((item, index) => (
          <div
          onClick={()=>clicindex(index)}
            className={`md:h-4 md:w-4 sm:h-3 sm:w-3 sm:mb-12  md:mt-5  sm:mt-0 rounded-full ${
              currentValue === index ? " bg-white" : "bg-blue-800"
            }`}
            key={index}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;

import { MESSAGES, MESSAGES_ERROR, DASH_DATA } from "../tasks/types";

const initialState = {
   msgs: null,
   dashData: null,
   dashLoading: true,
   loading: true,
   error: {},
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case MESSAGES:
         return {
            ...state,
            msgs: payload.msgs,
            loading: false,
         };
      case DASH_DATA:
         return {
            ...state,
            dashData: payload,
            dashLoading: false,
         };
      case MESSAGES_ERROR:
         return {
            ...state,
            error: payload,
            loading: false,
         };
      default:
         return state;
   }
}

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import Card from "../components/Card";
import Toast from "../components/Toast";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LOCAL_SERVER } from "../utils/URL";
import gear from "../Assets/icons/gear.png";
import hands from "../Assets/icons/hands.svg";
import sheild from "../Assets/icons/sheild.png";
import yearly from "../Assets/images/yearly.png";
import slider1 from "../Assets/images/slide1.png";
import slider2 from "../Assets/images/slide2.png";
import slider3 from "../Assets/images/slide3.jpeg";
import monthly from "../Assets/images/monthly.png";
import pricing from "../Assets/images/pricing.png";
import ImageSlider from "../components/ImageSlider";
import Testimonial from "../components/Testimonial";
import { useWindowSize } from "../Hooks/useWindowSize";
import icons from "../Assets/images/side social media icon.svg";

const Home = () => {
   const { Width } = useWindowSize();
   const [pkg, setPkg] = useState(true);
   const navigate = useNavigate();

   const slideStyles = {
      position: "relative",
      animation: "Animation 5s infinite",
   };
   const slideStyles2 = {
      position: "relative",
      animation: "Animation2 5s infinite",
   };
   const slideStyles3 = {
      position: "relative",
      animation: "Animation3 9s infinite",
   };

   const refs = {
      homeref: useRef(null),
      pricingref: useRef(null),
      whychoseusref: useRef(null),
      contectusref: useRef(null),
   };

   const scrollFunction = (refName) => {
      console.log("scrool function calling");
      const ref = refs[refName];
      if (ref && ref.current) {
         ref.current.scrollIntoView({ behavior: "smooth" });
      }
   };

   const [userData, setUserData] = useState({
      name: "",
      companyName: "",
      email: "",
      contactNo: "",
      msg: "",
   });
   const change = (e) => {
      setUserData({ ...userData, [e.target.name]: e.target.value });
   };

   const submit = async (e) => {
      e.preventDefault();
      const { email, name, companyName, contactNo, msg } = userData;
      try {
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };
         const body = JSON.stringify({
            email,
            name,
            companyName,
            contactNo,
            msg,
         });
         const res = await axios.post(
            LOCAL_SERVER + "api/auth/sendMail/",
            body,
            config
         );
         toast.success(res.data.message);
      } catch (e) {
         console.log("Error:", e);
      }
   };

   return (
      <div className="h-full w-full overflow-hidden">
         <meta name="viewport" content="width=device-width, initial-scale=1" />
         <Header scrollFunction={scrollFunction} />
         {/*  hero section */}

         <div
            ref={refs.homeref}
            className="relative bg-hero  bg-no-repeat bg-cover flex  md:min-h-screen flex-row gap-x-1  "
         >
            <Toast />

            {/* Left side */}

            <div
               className=" flex flex-col w-1/2  md:min-h-full  justify-center items-center
           sm:gap-y-4 sm:py-10           
      "
            >
               <div className=" sm:gap-y-[3vh] px15:gap-y-[8vh] flex flex-col w-[40vw]">
                  <h1 className="text-text-white font-inter font-bold sm:text-[3.4vw] px15:text-[3.3vw]">
                     What is{" "}
                     <span className="text-blue-500 ">Write Message</span>?
                  </h1>

                  <p className="text-white font-inter font-[300px] sm:text-[9px] sm-md:text-[14px] md:text-2xl sm:w-[40vw] px15:w-[38vw] text-justify ">
                     Unlock the power of seamless date-to-date operations with
                     this digital solution, crafted to elevate your customer
                     experience to sheer delight! It has the potential to cut
                     costs by £1,500 to £3,000 annually. This tool enables users
                     to compose personalized messages in over 15+ languages of
                     their choice.
                  </p>

                  <button
                     className={` rounded-[45px]  flex justify-center item-center p-2 text-white   md:font-[700px] sm:font-[0px] md:text-2xl font-kodch hover:bg-blue-500 bg-blue_clr items-center
                  sm:text-[10px] sm:h-[18px] sm:mt-8 sm:w-[80px] 
                  sm-md:h-[40px] sm-md:w-[150px]  sm-md:text-[19px] sm-md:mt-20 
                  md:w-[210px] md:py-7 md:text-[25px] md:mt-9  px17:font-semibold`}
                  >
                     Get Started
                  </button>
               </div>
            </div>

            {/* Right Side */}

            <div className="flex h-ful w-1/2 bg-green-20 flex-grow overflow-x-clip bg-blue-20 ">
               <ImageSlider show={false}>
                  <img
                     className="h-full w-full "
                     src={slider1}
                     alt="hero img"
                  />

                  <img className="h-full w-full" src={slider2} alt="hero img" />
                  <img
                     className="h-full w-full sm:p-10 md:p-16 px15:p-32 rounded-md"
                     src={slider3}
                     alt="hero img"
                  />
               </ImageSlider>
            </div>
            <img
               className="absolute top-0 right-0 
               sm:h-[75px] sm:w-[27px] 
               md:h-44 md:w-16 md:mt-20"
               src={icons}
               alt="icons"
            />
         </div>

         {/* Why Choose Us section */}

         <div
            ref={refs.whychoseusref}
            className="flex flex-col justify-center  sm:h-full sm:w-full md:h-screen"
         >
            <h1
               className="text-blue-700 font-bold   flex justify-center flex-row py-10
         sm:text-[9vw]      
         px14:text-[6vw]
         sm-md:py-12              
         md:mt-0 md:py-3            
         xl:mt-5
         px17:py-12              
          "
            >
               Why Choose Us?
            </h1>
            <div
               className="h-full w-full flex items-center 
            sm:flex-col sm:mt-0 
            md:flex-row md:mt-0            
             "
            >
               <div
                  style={slideStyles}
                  className="w-full flex justify-center items-center "
               >
                  <Card
                     vector={sheild}
                     head={"Highly Secured"}
                     desc={
                        "Fortified with advanced security measures, this app ensures an ironclad shield for your data, providing a highly secure environment that prioritizes your privacy and protection."
                     }
                  />
                  <style>
                     {` @keyframes Animation {
                                       0% { top: 0px; }
                                     
                                      50% { top: -25px; } 
                                     
                                     100% { top: 0px; } 
                                    }`}
                  </style>
               </div>
               <div
                  style={slideStyles2}
                  className="w-full flex justify-center items-center"
               >
                  <Card
                     vector={hands}
                     head={"Trust Worthy"}
                     desc={
                        "This app prides itself on being a trustworthy companion, dedicated to earning and maintaining your trust. With a commitment to reliability and integrity, it's designed to be your dependable ally in the digital realm."
                     }
                  />
                  <style>
                     {` @keyframes Animation2 {
                                       0% { top: 15px; left:15px }
                                     
                                       50% {top: -15px; left:-15px } 
                                     
                                     100% {  top:15px; left:15px } 
                                    }`}
                  </style>
               </div>
               <div
                  style={slideStyles3}
                  className="w-full flex justify-center items-center sm:pb-5"
               >
                  <Card
                     vector={gear}
                     head={"Performance"}
                     desc={
                        "Experience top-notch performance with this app that's finely tuned for efficiency. Enjoy swift responsiveness and seamless functionality, ensuring a smooth and satisfying user experience every time."
                     }
                  />
                  <style>
                     {` @keyframes Animation3 {
                                       0% { top: 0px; }
                                     
                                      50% { top: 25px; } 
                                     
                                     100% { top: 0px; } 
                                    }`}
                  </style>
               </div>
            </div>
         </div>

         {/* Pricing */}

         <section
            ref={refs.pricingref}
            className="grid md:grid-cols-2 overflow-hidden
             sm:grid-cols-1  sm:h-full
             md:h-screen md:w-full"
         >
            <div
               className=" flex justify-center items-center flex-col py-10  
               sm:gap-y-5
               md:gap-y-9 "
            >
               {Width > 1000 ? (
                  <>
                     <div className=" bg-primary rounded-3xl px-5 py-3 my-5 text-white font-semibold md:text-2xlg px17:text-3xl ">
                        <button
                           onClick={() => setPkg(false)}
                           className={`px-5  py-2 rounded-2xl ${
                              pkg ? `` : `bg-white text-primary`
                           }`}
                        >
                           Monthly
                        </button>
                        <button
                           onClick={() => setPkg(true)}
                           className={`px-7 py-2 rounded-2xl ${
                              pkg ? `bg-white text-primary` : ``
                           }`}
                        >
                           Yearly
                        </button>
                     </div>
                     <h1
                        className=" text-primary font-semibold sm:text-4xl px450:text-[40px] px450:py-5 
              sm-md:text-6xl md:text-5xl px17:text-[4rem] px-20"
                     >
                        {" "}
                        Save 25% when you take a yearly subscription
                     </h1>
                     <img className="px-10 " src={pricing} alt="our pricing" />
                  </>
               ) : (
                  <></>
               )}

               {Width < 1000 ? (
                  <>
                     <img className="px-10 " src={pricing} alt="our pricing" />
                     <div className="bg-primary rounded-3xl px-5 py-3 my-5 sm-md:text-2xl px8:text-3xl text-white font-semibold">
                        <button
                           onClick={() => setPkg(false)}
                           className={`px-5  py-2 rounded-2xl ${
                              pkg ? `` : `bg-white text-primary`
                           }`}
                        >
                           Monthly
                        </button>
                        <button
                           onClick={() => setPkg(true)}
                           className={`px-7 py-2 rounded-2xl ${
                              pkg ? `bg-white text-primary` : ``
                           }`}
                        >
                           Yearly
                        </button>
                     </div>
                     <h1
                        className="text-primary font-semibold sm:text-4xl px450:text-[45px] px450:py-5 
              sm-md:text-6xl md:text-5xl px17:text-[6rem] flex w-full justify-center items-center pl-10"
                     >
                        {" "}
                        Save 25% when you take a yearly subscription
                     </h1>
                  </>
               ) : (
                  <></>
               )}
            </div>

            <div className=" flex justify-center items-center overflow-x-hidden">
               <div
                  className={`transition-transform duration-500 ${
                     pkg
                        ? " transform translate-x-[1500px]"
                        : "transform translate-x-1/2"
                  }`}
               >
                  <img
                     className="md:ms-20 cursor-pointer"
                     src={monthly}
                     alt="our pricing"
                  />
               </div>
               <div
                  className={`transition-transform duration-500 ${
                     pkg
                        ? "transform -translate-x-1/2"
                        : "transform translate-x-[1500px] "
                  }`}
               >
                  <img
                     onClick={(e) => navigate("/signup")}
                     className="md:ms-20 cursor-pointer "
                     src={yearly}
                     alt="our pricing"
                  />
               </div>
            </div>
         </section>

         {/* Testimonial */}

         {Width > 1000 ? (
            <section className="text-white flex bg-testimonial bg-cover justify-center items-center h-screen  w-screen overflow-clip md:py-10">
               <div
                  className="bg-blueBack bg-no-repeat bg-cover flex flex-col items-center pt-14
                 sm:w-11/12   
                 md:w-[65rem] md:h-[35rem] 
                 xl:w-[75rem] xl:h-[45rem] 
                 xxl:w-[85rem] xxl:h-[50rem] 
                  "
               >
                  {/* <h1 className="font-extrabold   w-full flex justify-center
                     sm:text-3xl
                     md:text-5xl 
                     xl:text-6xl 
                     ">Testimonial</h1> */}

                  <div className="h-5/6 w-5/6 flex md:flex-row sm:flex-col">
                     {Width > 1000 ? (
                        <div className=" flex w-2/6 h-full text-7xl flex-col justify-center ">
                           <h1>They Trust Us!</h1>
                        </div>
                     ) : (
                        <></>
                     )}
                     <div className=" flex w-full  px-10 mt-20 overflow-clip">
                        <ImageSlider show={false}>
                           <Testimonial
                              image={
                                 "https://i.pinimg.com/originals/48/35/b5/4835b5f9c52fd733eb26fb2c2b47bdc7.jpg"
                              }
                              name={"Jhony"}
                              designation={"web developer"}
                              description={
                                 "Hello there! I'm Jhony, an enthusiastic and seasoned Web Developer with a passion for creating elegant, functional, and user-friendly websites. With [X+] years of hands-on experience, I've honed my skills."
                              }
                           />
                           <Testimonial
                              image={
                                 "https://jamesgilberdphotography.weebly.com/uploads/1/3/6/5/13650410/emma-not-smiling-35x45_1_orig.jpg"
                              }
                              name={"Jhony"}
                              designation={"web developer"}
                              description={
                                 "Hello there! I'm Jhony, an enthusiastic and seasoned Web Developer with a passion for creating elegant, functional, and user-friendly websites. With [X+] years of hands-on experience, I've honed my skills."
                              }
                           />
                           <Testimonial
                              image={
                                 "https://www.pngitem.com/pimgs/m/264-2640465_passport-size-photo-sample-hd-png-download.png"
                              }
                              name={"Jhony"}
                              designation={"web developer"}
                              description={
                                 "Hello there! I'm Jhony, an enthusiastic and seasoned Web Developer with a passion for creating elegant, functional, and user-friendly websites. With [X+] years of hands-on experience, I've honed my skills."
                              }
                           />
                        </ImageSlider>
                     </div>
                  </div>
               </div>
            </section>
         ) : (
            <>
               <div className="flex flex-col items-center h-full w-full">
                  <h1 className="font-extrabold sm:text-3xl px450:text-[45px] sm-md:text-[55px] text-blue-600 w-full flex justify-center py-10 ">
                     Testimonial
                  </h1>
                  <div className="h-[500px] w-5/6 rounded-xl bg-blue-500 flex justify-center flex-col overflow-x-clip ">
                     <ImageSlider show={false}>
                        <Testimonial
                           image={
                              "https://i.pinimg.com/originals/48/35/b5/4835b5f9c52fd733eb26fb2c2b47bdc7.jpg"
                           }
                           name={"Jhony"}
                           designation={"web developer"}
                           description={
                              "Hello there! I'm Jhony, an enthusiastic and seasoned Web Developer with a passion for creating elegant, functional, and user-friendly websites. With [X+] years of hands-on experience, I've honed my skills."
                           }
                        />
                        <Testimonial
                           image={
                              "https://jamesgilberdphotography.weebly.com/uploads/1/3/6/5/13650410/emma-not-smiling-35x45_1_orig.jpg"
                           }
                           name={"Jhony"}
                           designation={"web developer"}
                           description={
                              "Hello there! I'm Jhony, an enthusiastic and seasoned Web Developer with a passion for creating elegant, functional, and user-friendly websites. With [X+] years of hands-on experience, I've honed my skills."
                           }
                        />
                        <Testimonial
                           image={
                              "https://www.pngitem.com/pimgs/m/264-2640465_passport-size-photo-sample-hd-png-download.png"
                           }
                           name={"Jhony"}
                           designation={"web developer"}
                           description={
                              "Hello there! I'm Jhony, an enthusiastic and seasoned Web Developer with a passion for creating elegant, functional, and user-friendly websites. With [X+] years of hands-on experience, I've honed my skills."
                           }
                        />
                     </ImageSlider>
                  </div>
               </div>
            </>
         )}

         <section
            ref={refs.contectusref}
            className="bg-hello flex justify-center items-center  
            sm:p-8 
            md:p-10 "
         >
            <h1 className=" text-primary font-bold font-Sail sm:text-5xl px450:text-[45px] px500:text-[50px] sm-md:text-[55px] px8:text-[75px] md:text-6xl  ">
               {" "}
               Say Hello!
            </h1>
         </section>

         <form onSubmit={submit}>
            <section className="bg-testimonial bg-cover flex flex-col h-full justify-center items-center">
               <div
                  className="bg-white flex flex-col gap-y-3 md:w-7/12 px14:w-5/12 rounded-xl sm:w-11/12  
          md:my-16 md:py-20  sm:my-10 sm:py-10 justify-center items-center shadow-xl"
               >
                  <input
                     type="text"
                     placeholder="*Name"
                     name="name"
                     onChange={change}
                     className=" p-2 px-3 md:w-4/6 sm:w-11/12 border-solid border-2 border-gray-400 rounded-lg md:text-xl "
                  />
                  <input
                     type="text"
                     placeholder="*Company Name"
                     name="companyName"
                     onChange={change}
                     className="p-2 px-3 md:w-4/6  sm:w-11/12 border-solid border-2 border-gray-400 rounded-lg md:text-xl "
                  />
                  <input
                     type="text"
                     placeholder="*Contact No"
                     name="contactNo"
                     onChange={change}
                     className="p-2 px-3 md:w-4/6  sm:w-11/12 border-solid border-2 border-gray-400 rounded-lg md:text-xl "
                  />
                  <input
                     type="text"
                     placeholder="*Email"
                     name="email"
                     onChange={change}
                     className="p-2 px-3 md:w-4/6  sm:w-11/12 border-solid border-2 border-gray-400 rounded-lg md:text-xl "
                  />
                  <textarea
                     placeholder="Message"
                     name="msg"
                     onChange={change}
                     className="p-2 px-3 md:w-4/6  sm:w-11/12 border-solid border-2 border-gray-400 rounded-lg md:text-xl "
                  />
                  <input
                     type="submit"
                     className="bg-sky-800 rounded-xl py-2 px-5 mt-16 text-white font-semibold md:text-xl px15:text-2xl  "
                     title="Submit"
                  />
               </div>

               <div className="flex w-full h-full relative">
                  {Width < 1000 ? (
                     <></>
                  ) : (
                     <div className="ocean">
                        <div className="wave" />
                        <div className="wave" />
                        <div className="wave" />
                     </div>
                  )}
               </div>
            </section>
            <Footer />
         </form>
      </div>
   );
};

export default Home;

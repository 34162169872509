import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Table from "../../components/Table";

import Spinner from "../../components/Spinner";
import { loadLocation } from "../../tasks/franchiseT";
import { LOCAL_SERVER } from "../../utils/URL";

const COLUMNS = [
   {
      Header: "Image",
      accessor: "Img",
      Cell: ({ value }) => {
         return (
            <div className="flex items-center justify-center">
               <img className="h-16 " src={LOCAL_SERVER + value} alt=""></img>
            </div>
         );
      },
   },
   {
      Header: "Username",
      accessor: "userName",
   },
   {
      Header: "Email",
      accessor: "email",
   },
   {
      Header: "Phone",
      accessor: "phone",
   },
   {
      Header: "Address",
      accessor: "address",
   },
];

const MyLocations = ({ loading, locations, loadLocation }) => {
   const [numberOptions] = useState([10, 15, 20, 30]);
   const columns = useMemo(
      () => COLUMNS,
      // eslint-disable-next-line
      []
   );
   const data = useMemo(
      () => locations,
      // eslint-disable-next-line
      [loading]
   );

   useEffect(() => {
      loadLocation();
   }, [loadLocation]);
   const eventHandler = () => {};
   return loading ? (
      <Spinner />
   ) : (
      <div className="w-screen h-screen flex flex-col flex-grow overflow-x-hidden">
         <Header />
         <div className=" flex h-full flex-col justify-between py-5 overflow-y-scrool">
            {locations ? (
               <Table
                  columns={columns}
                  data={data}
                  numberOptions={numberOptions}
                  eventHandler={eventHandler}
               />
            ) : (
               <h1> No data</h1>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

MyLocations.propTypes = {
   loading: PropTypes.bool,
   locations: PropTypes.array,
   loadLocation: PropTypes.func.isRequired,
};

const mapStateToProops = (state) => ({
   loading: state.franchise.locationsLoading,
   locations: state.franchise.locations,
});

export default connect(mapStateToProops, { loadLocation })(MyLocations);

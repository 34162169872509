import axios from "axios";
import {
   FRANCHISES,
   FRANCHISE_LOCATIONS,
   FRANCHISE_MSGS,
   FRANCHISE_ERROR,
   FRANCHISES_INIT_STATE,
} from "./types";
import { LOCAL_SERVER } from "../utils/URL";
import { setAlert } from "./alertT";

// Load all franchises
export const loadFranchises = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/franchises/");
      dispatch({
         type: FRANCHISES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: FRANCHISE_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

// Load franchise's Locations
export const loadLocation = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/franchises/mylocs");
      console.log(res);
      dispatch({
         type: FRANCHISE_LOCATIONS,
         payload: res.data,
      });
   } catch (error) {
      if (error.response) {
         console.log(error.response.data.message);
         dispatch({
            type: FRANCHISE_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         console.log(error.message);
      }
   }
};

// Load franchise's messages
export const loadMessage = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/franchises/msgData/");
      dispatch({
         type: FRANCHISE_MSGS,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: FRANCHISE_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

// Change Franchises Admin data
export const updateFranchiseData = (newdata, navigate) => async (dispatch) => {
   const { email, phone, status, id, password } = newdata;
   const config = {
      headers: {
         "Content-Type": "application/json",
      },
   };
   let body;

   if (password) {
      body = JSON.stringify({ email, phone, status, password });
   } else {
      body = JSON.stringify({ email, phone, status });
   }
   try {
      await axios.put(LOCAL_SERVER + `api/franchises/${id}`, body, config);

      dispatch(setAlert("Franchises Data Updated", "success"));
      dispatch({ type: FRANCHISES_INIT_STATE });
      navigate(-1);
   } catch (error) {
      if (error.response) {
         dispatch(setAlert(error.response.data.message, "error"));
         console.log(error.response.data.message);
         dispatch({
            type: FRANCHISE_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         console.log(error.message);
      }
   }
};

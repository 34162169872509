import axios from "axios";
import {
   PROFILES,
   PROFILES_ERROR,
   PROFILE_IMAGES,
   PROFILES_INIT_STATE,
   CURRENT_PROFILES,
} from "./types";
import { setAlert } from "./alertT";
import { LOCAL_SERVER } from "../utils/URL";

export const loadProfiles = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/profiles/getMine/");
      dispatch({
         type: PROFILES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: PROFILES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const loadProfileImages = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/profiles/getImgs/");
      dispatch({
         type: PROFILE_IMAGES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: PROFILES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const addProfile =
   (name, avatar, revert, navigate) => async (dispatch) => {
      try {
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };

         const body = JSON.stringify({ name, avatar });

         await axios.post(LOCAL_SERVER + `api/profiles/`, body, config);

         dispatch(setAlert("Profile Added!", "success"));
         revert();
         dispatch({
            type: PROFILES_INIT_STATE,
         });
         navigate("/profiles");
      } catch (err) {
         console.log(err.response.data.message);
         const errors = err.response.data.message;
         if (errors) {
            dispatch(setAlert(errors, "danger"));
         }
         dispatch({
            type: PROFILES_ERROR,
            payload: {
               msg: err.response.data.message,
               status: err.response.status,
            },
         });
      }
   };

export const setProfile = (profileId, navigate) => async (dispatch) => {
   try {
      const res = await axios.get(
         LOCAL_SERVER + `api/profiles/getOne/${profileId}`
      );
      dispatch({
         type: CURRENT_PROFILES,
         payload: res.data.profile,
      });
      localStorage.setItem("profileId", res.data.profile.id);
      if (navigate) {
         navigate("/msg-template");
      }
   } catch (err) {
      console.log(err.response.data.message);
      const errors = err.response.data.message;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: PROFILES_ERROR,
         payload: {
            msg: err.response.data.message,
            status: err.response.status,
         },
      });
   }
};

export const removeProfile = (id, navigate) => async (dispatch) => {
   try {
      const con = window.confirm(
         "Are you sure you want to delete this profile?"
      );
      if (con) {
         await axios.delete(LOCAL_SERVER + `api/profiles/${id}`);
         dispatch({
            type: PROFILES_INIT_STATE,
         });
         dispatch(setAlert("Profile Removed!", "success"));
         navigate("/profiles");
      }
   } catch (err) {
      console.log(err.response.data.message);
      const errors = err.response.data.message;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: PROFILES_ERROR,
         payload: {
            msg: err.response.data.message,
            status: err.response.status,
         },
      });
   }
};

import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "./Button";
import { logout } from "../tasks/authT";
import { LOCAL_SERVER } from "../utils/URL";
import useSocket from "../Hooks/useSocket";
import store from "../Assets/images/store.png";
import WMlogo from "../Assets/images/WMlogo.png";
import hamburger from "../Assets/images/Hamburger.svg";
import { useWindowSize } from "../Hooks/useWindowSize";
import logo from "../Assets/images/Write Message 1.svg";

const MenuAssets = ({
  links,
  isAuthenticated,
  user,
  profile,
  logout,
  showProfile,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { emitEvent } = useSocket();
  const { Width } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState(false);

  const handleAuthClick = () => {
    setAuth(!auth);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {Width > 1250 ? (
        <div className=" flex justify-between items-center w-full py-1 pr-10 pl-10 shadow-xl">
          {isAuthenticated ? (
            <img
              // onClick={(e) => navigate("/")}
              src={WMlogo}
              alt="logo"
              className="w-[244px] h-[83px] object-contain  hover:cursor-pointer"
            />
          ) : (
            <img
              // onClick={(e) => navigate("/")}
              src={logo}
              alt="logo"
              className="w-[244px] h-[83px] object-contain  hover:cursor-pointer"
            />
          )}
          {/* MENU */}
          {
            <div
              className={`flex ${
                user && user.role === "FRANCHISE"
                  ? "justify-around items-center"
                  : "justify-between items-center"
              } w-[669px] h-[53px] rounded-[45px] bg-primary px-[51px] py-[13px] mx-3          `}
            >
              {links.map((item) => {
                return (
                  <li
                    className="inline-block  hover:text-neutral-500 font-kodch font-semibold text-[21px] text-white  "
                    key={item.name}
                  >
                    <h1
                      className="hover:cursor-pointer"
                      // onClick={(e) => navigate(item.link)}
                      onClick={
                        location.pathname === "/" && !isAuthenticated
                          ? item.onclick
                          : () => navigate(item.link)
                      }
                    >
                      {item.name}
                    </h1>
                  </li>
                );
              })}
            </div>
          }

          {isAuthenticated ? (
            <>
              {user.role === "LOCATION" && profile && showProfile ? (
                <div className="flex flex-col justify-center items-center">
                  <div className="flex overflow-hidden">
                    <img
                      alt="profile"
                      src={LOCAL_SERVER + profile.avatar}
                      className="flex h-14 w-14 hover:cursor-pointer"
                    />
                  </div>
                  <h5 className="font-semibold mt-1">{profile.name}</h5>
                </div>
              ) : (
                <></>
              )}

              <div className="flex flex-col justify-center items-center ">
                {user && user.role === "FRANCHISE" ? (
                  <img
                    alt="profile"
                    onClick={handleAuthClick}
                    src={store}
                    className="flex h-13 w-13 hover:cursor-pointer"
                  />
                ) : (
                  <div className="flex border-4 border-primary rounded-full overflow-hidden">
                    <img
                      alt="profile"
                      onClick={handleAuthClick}
                      src={LOCAL_SERVER + user.Img}
                      className="flex h-16 w-16 hover:cursor-pointer"
                    />
                  </div>
                )}
                {auth ? (
                  <div
                    className={`transition-all duration-500 top-[105px] px-20 right-10 z-50 ease-in-out absolute flex flex-col justify-center items-center bg-neutral-400 bg-opacity-90 py-2 rounded-md ${
                      auth ? "opacity-100 scale-100" : "opacity-0 scale-0"
                    }`}
                    style={{ transformOrigin: "top" }}
                  >
                    <li className="flex flex-col justify-center items-center font-kodch font-semibold text-xl text-primary py-2">
                      <h1
                        className="py-5 hover:text-neutral-500 hover:cursor-pointer"
                        onClick={(e) => navigate("/location/change-password")}
                      >
                        Change Password
                      </h1>
                      <h1
                        className="py-5 hover:text-neutral-500 hover:cursor-pointer"
                        onClick={(e) => logout(user.role, emitEvent, user.id)}
                      >
                        Logout
                      </h1>
                    </li>
                  </div>
                ) : (
                  <></>
                )}
                <h5 className="font-semibold mt-0">
                  {user.role === "LOCATION" ? user.userName : user.firstName}
                </h5>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-x-3">
                <Button
                  className={"font-kodch font-semibold"}
                  title={"Sign Up"}
                  onClick={() => navigate("/signup")}
                />
                <Button
                  className={"font-kodch font-semibold "}
                  title={"Login"}
                  onClick={() => navigate("/login")}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className="flex justify-between w-screen pr-28 ps-10 py-5  items-center shadow-xl
            sm-md:px-5"
        >
          {isAuthenticated ? (
            <img
              // onClick={(e) => navigate("/")}
              src={WMlogo}
              alt="logo"
              className="w-[244px] h-[83px] object-contain  hover:cursor-pointer"
            />
          ) : (
            <img
              // onClick={(e) => navigate("/")}
              src={logo}
              alt="logo"
              className="w-[244px] h-[83px] object-contain  hover:cursor-pointer"
            />
          )}

          <div className="flex flex-row gap-x-10 justify-center items-center ">
            {isAuthenticated ? (
              <div className="flex flex-col justify-center items-center z-50 ">
                <div className="flex border-4 border-primary rounded-full overflow-hidden">
                  <img
                    onClick={handleAuthClick}
                    alt="profile"
                    src={LOCAL_SERVER + user.Img}
                    className="flex h-20 w-20 hover:cursor-pointer"
                  />
                  {auth ? (
                    <div
                      className={`transition-all duration-500 top-32 px-20 ease-in-out absolute flex flex-col justify-center items-center right-16  bg-neutral-400 bg-opacity-90 py-2 rounded-md ${
                        auth ? "opacity-100 scale-100" : "opacity-0 scale-0"
                      }`}
                      style={{ transformOrigin: "top" }}
                    >
                      <li className="flex flex-col  font-kodch font-semibold text-xl text-primary py-2">
                        <h1
                          className="py-5 hover:text-neutral-500 hover:cursor-pointer"
                          onClick={(e) => navigate("/location/change-password")}
                        >
                          Change Password
                        </h1>
                        <h1
                          className="py-5 hover:text-neutral-500 hover:cursor-pointer"
                          onClick={(e) => logout(user.role, emitEvent, user.id)}
                        >
                          Logout
                        </h1>
                      </li>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <h5 className="font-semibold mt-2">
                           {user.role === "LOCATION" 
                              ? user.userName
                              : user.name}
                        </h5> */}
              </div>
            ) : (
              <>
                <div className="flex gap-x-3">
                  <Button
                    title={"Sign Up"}
                    onClick={() => navigate("/signup")}
                  />
                  <Button title={"Login"} onClick={() => navigate("/login")} />
                </div>
              </>
            )}

            <img
              className=" h-[50px] w-[35px] mt-3 hover:cursor-pointer"
              src={hamburger}
              onClick={handleClick}
              alt="logo"
            />
          </div>
          {open ? (
            <div
              className={`transition-all duration-500 ease-in-out absolute right-0 flex flex-col justify-center items-center top-[124px] w-full bg-neutral-400 bg-opacity-90 py-2 rounded-md z-50 ${
                open ? "opacity-100 scale-100" : "opacity-0 scale-0"
              }`}
              style={{ transformOrigin: "top" }}
            >
              {links.map((item) => {
                return (
                  <li
                    className="inline-block hover:text-neutral-500 hover:cursor-pointer font-kodch font-semibold text-xl text-primary py-2"
                    key={item.name}
                  >
                    <h1
                      className="hover:cursor-pointer"
                      onClick={
                        location.pathname === "/" && !isAuthenticated
                          ? item.onclick
                          : () => navigate(item.link)
                      }
                    >
                      {item.name}
                    </h1>
                  </li>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

MenuAssets.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(MenuAssets);

import { USERS, USERS_ERROR } from "../tasks/types";

const initialState = {
   users: null,
   loading: true,
   error: {},
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case USERS:
         return {
            ...state,
            users: payload.users,
            loading: false,
         };
      case USERS_ERROR:
         return {
            ...state,
            error: payload,
            loading: false,
         };
      default:
         return state;
   }
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import goog from "../Assets/images/goog.png";
import Toast from "../components/Toast";
import Button from "../components/Button";
import useSocket from "../Hooks/useSocket";
import { LOCAL_SERVER } from "../utils/URL";
import logo from "../Assets/images/Logo.png";
import CustomModal from "../components/Modal";
import { titleCase } from "../utils/TitleCase";
import TextInput from "../components/TextInput";
import successImg from "../Assets/images/success.png";
import { useWindowSize } from "../Hooks/useWindowSize";
import completeLogo from "../Assets/images/completeLogo.png";

import Select from "react-select";

const langs = [
   { value: "ur", label: "Urdu" },
   { value: "ar", label: "Arabic" },
   { value: "en", label: "English" },
   { value: "hy", label: "Armenian" },
   { value: "bn", label: "Bengali" },
   { value: "bg", label: "Bulgarian" },
   { value: "da", label: "Danish" },
   { value: "de", label: "German" },
   { value: "el", label: "Greek" },
   { value: "ga", label: "Irish" },
   { value: "la", label: "Latin" },
   { value: "lv", label: "Latvian" },
   { value: "mt", label: "Maltese" },
   { value: "mr", label: "Marathi" },
   { value: "ne", label: "Nepali" },
   { value: "ps", label: "Pashto" },
   { value: "pes", label: "Persian" },
   { value: "pt-BR", label: "Portuguese" },
   { value: "pa", label: "Punjabi" },
   { value: "ro", label: "Romanian" },
   { value: "ru", label: "Russian" },
   { value: "sr-atn", label: "Serbian" },
   { value: "sd", label: "Sindhi" },
   { value: "ta", label: "Tamil" },
   { value: "te", label: "Telugu" },
   { value: "tr", label: "Turkish" },
   { value: "uk", label: "Ukrainian" },
   { value: "vi", label: "Vietnamese" },
   { value: "fr", label: "French" },
   { value: "cs", label: "Czech" },
   { value: "es", label: "Spanish" },
   { value: "it", label: "Italian" },
   { value: "ja", label: "Japenese" },
   { value: "ko", label: "Korean" },
   { value: "zh-N", label: "Chinese" },
   { value: "hi", label: "Hindi" },
];

const AnonymousMsg = () => {
   const { LocationId } = useParams();
   const [link, setLink] = useState([""]);
   const [inputFields, setInputFields] = useState([""]);
   const [translated, setTranslated] = useState("");
   const [success, setSuccess] = useState(false);
   const [font, setFont] = useState("font-Monotype_Corsiva");
   const [lang, setLang] = useState(langs[2]);
   const [agree, setAgree] = useState(false);
   const [msg, setMsg] = useState("");
   const [fields, setFields] = useState([]);
   const [modal, setModal] = useState(false);
   const [data, setData] = useState({
      type: "MSG",
      messenger: "GUEST",
      today: Date.now(),
      training: false,
   });

   const { error, myRes, emitEvent } = useSocket();
   const { Width } = useWindowSize();

   useEffect(() => {
      const getLink = async () => {
         try {
            const res = await axios.get(
               LOCAL_SERVER + `api/locations/reviewLink/${LocationId}`
            );
            setLink(res.data.link.reviewLink);
         } catch (error) {
            if (error.response) {
               console.log(error.response.data.message);
               toast.error(error.response.data.message);
            } else {
               console.log(error.message);
               toast.error(error.message);
            }
         }
      };
      getLink();
      //eslint-disable-next-line
   }, []);

   const handleAddInputField = () => {
      if (inputFields[inputFields.length - 1] !== "") {
         const t = "";
         setInputFields([...inputFields, t]);
      }
   };

   const changeFields = (e, index) => {
      const arr = [...inputFields];
      arr[index] = titleCase(e.target.value);
      setInputFields(arr);
   };

   const DeleteInputField = (index) => {
      const updatedFields = [...inputFields];
      updatedFields.splice(index, 1);
      setInputFields(updatedFields);
   };

   const handlePreview = async (e) => {
      const com = inputFields.join(" ");
      if (lang.value === "en") {
         setTranslated(com);
      } else {
         await translate(com, lang.value);
      }
   };

   useEffect(() => {
      console.log(translated);
      const sep = translated.split(" ");
      setFields(sep);
      setMsg(translated);
      setData({
         ...data,
         font: font,
         msg: translated,
      });
      //eslint-disable-next-line
   }, [translated]);

   const handleSubmit = (e) => {
      if (msg === "") {
         toast.error("You need to add a msg to preview box!");
      } else if (!agree) {
         toast.error("You need to agree to our terms of conduct!");
      } else {
         setModal(true);
      }
   };

   const onClose = (e) => {
      setModal(false);
   };
   const onSucClose = (e) => {
      setSuccess(false);
   };

   const handlePost = (e) => {
      emitEvent("add-message", { locationId: LocationId, myMsg: data });
      setModal(false);
      setFields([]);
      setData({
         ...data,
         msg: "",
      });
      setInputFields([""]);
      setAgree(false);
      setSuccess(true);
   };

   useEffect(() => {
      if (error) {
         toast.error(error.message);
         console.log("error: " + error.message);
      }
      if (myRes) {
         console.log(myRes);
         toast.success(myRes.message);
         //  navigate(`/msg-box/${LocationId}`);
      }

      // eslint-disable-next-line
   }, [error, myRes]);

   const translate = async (text, lang) => {
      try {
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };

         const body = JSON.stringify({ text, lang });

         const res = await axios.post(
            LOCAL_SERVER + "api/msgs/translate/",
            body,
            config
         );
         setTranslated(res.data.lated.translation);
      } catch (error) {
         console.log(error);
         console.log(error.response.data.text);
         toast.error("Server is unable to translate at this time!");
      }
   };

   return (
      <div className={` flex flex-col min-h-screen`}>
         <div className="flex justify-center  bg-white w-full py-2 border-b-[0.3vw] border-blue-600 drop-shadow-2xl shadow-black">
            <img src={logo} alt="logo" className="sm:h-[12vw] md:h-[4.4vw]" />
         </div>
         <Toast />
         {/* Guest Moodal */}
         <CustomModal
            isOpen={modal}
            closeBtn={true}
            onClose={(e) => onClose(e)}
         >
            <div className="h-full w-full mx-10">
               <h1 className="w-full py-10 text-2xl font-semibold text-neutral-700">
                  Enter Your Email
               </h1>
               <TextInput
                  name={"guestEmail"}
                  placeholder={"john@doe.com"}
                  type={"email"}
                  value={data.guestEmail}
                  onChange={(e) =>
                     setData({ ...data, guestEmail: e.target.value })
                  }
               />
               <Button
                  className={
                     "flex my-10 py-2 px-2 w-full rounded-md justify-center items-center font-semibold"
                  }
                  title={"Post Message"}
                  onClick={(e) => handlePost(e)}
               />
            </div>
         </CustomModal>
         <CustomModal
            isOpen={success}
            closeBtn={true}
            onClose={(e) => onSucClose(e)}
         >
            <div className="h-full w-full mx-10 flex flex-col justify-center items-center sm:gap-y-2 xxl:gap-y-10">
               {Width > 1000 ? (
                  <img src={completeLogo} alt="logo" className="my-10" />
               ) : (
                  <img
                     src={logo}
                     alt="logo"
                     className="my-0 sm:h-44 md:h-auto"
                  />
               )}
               {link === "" ? (
                  <></>
               ) : (
                  <>
                     <h1 className="w-full text-2xl font-semibold text-neutral-700 text-center px-10">
                        Please give us a review on google
                     </h1>
                     <a
                        href={link}
                        className="w-full flex justify-center items-center py-10 text-2xl font-semibold text-neutral-700 text-center px-10 underline"
                     >
                        <div className="hover:bg-neutral-300  rounded-lg p-10">
                           <img
                              src={goog}
                              alt="review link"
                              className="h-20 w-60"
                           />
                        </div>
                     </a>
                  </>
               )}
               <img src={successImg} alt="tick" className="md:h-24 sm:h-20 " />
               <h1 className="w-full mb-10 mt-4 text-2xl font-semibold text-neutral-700 text-center px-10">
                  Your Message Has been Posted on the store's Message Board.
               </h1>
            </div>
         </CustomModal>

         <div className="flex flex-grow w-full bg-login bg-cover sm:flex-col md:flex-row ">
            {/* left side */}
            <div className="justify-center sm:w-full md:w-1/2 flex flex-col  sm:px-5 lg:px-32 px15:gap-y-7 gap-y-6 sm:py-10 md:py-0">
               <label className="font-bold  text-gray-800 w-full sm:text-xl md:text-2xl pl-3 px15:text-3xl">
                  Select your font
               </label>

               <select
                  name="font"
                  value={font}
                  onChange={(e) => setFont(e.target.value)}
                  placeholder="Select a font"
                  className=" py-[0.5vw] px-[0.5vw] w-full border-[0.1vw] border-neutral-500 rounded-[1vh] hover:cursor-pointer"
               >
                  <option value="">Select a Font </option>

                  <option value="font-Sail" className="text-black text-sm ">
                     Sail
                  </option>
                  <option value="font-Gabriel">Gabriel</option>
                  <option value="font-Monotype_Corsiva">
                     Monotype Corsiva
                  </option>
                  <option value="font-Walt_Disney_Script">
                     Walt Disney Scrip
                  </option>
               </select>

               <label className="font-bold sm:text-xl text-gray-800 w-full md:text-2xl px15:text-3xl pl-3">
                  Select your language
               </label>

               <Select
                  value={lang}
                  options={langs}
                  isSearchable={true}
                  onChange={(e) => setLang(e)}
                  className="w-full  border-2 border-neutral-400 rounded-md outline-none"
               />

               <label className=" text-gray-600 sm:text-xl  md:text-2xl sm-md:pr-28">
                  Please write a message in below feilds
               </label>

               <div className="flex flex-col sm:gap-y-[3vw] md:gap-y-[0.4vw] w-full">
                  {inputFields.map((field, index) => (
                     <div
                        key={index}
                        className="flex flex-row sm:gap-x-[0.5vw] md:gap-x-[0.2vw] w-full"
                     >
                        <input
                           className="bg-primary text-white w-full sm:py-[1vw] md:py-[0.4vw] sm:text-[5vw] md:text-[1vw] font-semibold px-[1vh] outline-none rounded-[0.5vh]"
                           name="field"
                           value={field}
                           placeholder="Enter message"
                           onChange={(e) => changeFields(e, index)}
                        />

                        {index === inputFields.length - 1 ? (
                           <button
                              onClick={handleAddInputField}
                              className="bg-primary text-white sm:w-[13vw] md:w-[4.1vw] font-semibold sm:text-[5vw] md:text-[1vw] hover:cursor-pointer hover:bg-neutral-500 duration-200 rounded-[0.5vh]"
                           >
                              +
                           </button>
                        ) : (
                           <button
                              onClick={() => {
                                 DeleteInputField(index);
                              }}
                              className="bg-primary text-white sm:w-[13vw] md:w-[4.1vw] font-semibold sm:text-[5vw] md:text-[1vw] hover:cursor-pointer hover:bg-neutral-500 duration-200 rounded-[0.5vh]"
                           >
                              Del
                           </button>
                        )}
                     </div>
                  ))}
               </div>

               <div className="justify-center items-center flex w-full">
                  <button
                     onClick={(e) => handlePreview(e)}
                     className="bg-primary text-white font-bold sm:text-[4vw] md:text-[1vw] sm:px-[2vw] md:px-[1vw] sm:py-[0.6vw] md:py-[0.3vw] hover:cursor-pointer hover:bg-neutral-600 duration-300 rounded-xl"
                  >
                     Preview
                  </button>
               </div>
            </div>

            {/* right side */}
            <div className="w-full  justify-center items-center sm:w-full md:w-1/2 flex flex-col  sm:px-5 gap-y-5 py-8">
               {font && (
                  <div
                     // key={font}
                     className={`flex justify-center items-center md:w-2/3 md:h-4/6 sm:w-full sm:h-96 bg-black text-white
                         ${
                            font === "" ? "" : font
                         } text-5xl text-center font-semibold rounded-md`}
                  >
                     <h5>
                        {fields.length === 0 ? (
                           <></>
                        ) : (
                           fields.map((myText, index) => (
                              <h1 key={index}>{myText}</h1>
                           ))
                        )}
                     </h5>
                  </div>
               )}
               {!font && (
                  <div
                     // key={font}
                     className={`flex justify-center items-center md:w-2/3 md:h-4/6 sm:w-full sm:h-96 bg-black text-white
                         ${
                            font === "" ? "" : font
                         } text-5xl text-center font-semibold rounded-md`}
                  >
                     <h5>
                        {fields.length === 0 ? (
                           <></>
                        ) : (
                           fields.map((myText, index) => (
                              <h1 key={index}>{myText}</h1>
                           ))
                        )}
                     </h5>
                  </div>
               )}
               <div className="flex items-center gap-x-2  ">
                  <label className="sm:text-[4vw] md:text-[1vw]  font-medium text-gray-900 ">
                     Agree the terms & conditions
                  </label>
                  <input
                     type="checkbox"
                     checked={agree}
                     onChange={(e) => setAgree(!agree)}
                     className="h-6 w-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
               </div>
               <div className="flex justify-center rounded-[0.5vh]  w-full  ">
                  <button
                     onClick={(e) => handleSubmit(e)}
                     className="bg-primary  text-white font-bold sm:text-[4vw] md:text-[1vw] sm:px-[3vw] md:px-[1vw] sm:py-[0.7vw] md:py-[0.3vw] hover:cursor-pointer hover:bg-neutral-600 duration-300 rounded-xl"
                  >
                     {" "}
                     Submit{" "}
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AnonymousMsg;

import { FIGURES, FIGURES_ERROR, FIGURES_INIT_STATE } from "../tasks/types";

const initialState = {
   loading: true,
   ANS: null,
   error: {},
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case FIGURES:
         return {
            ...state,
            ANS: payload.figures,
            loading: false,
         };
      case FIGURES_INIT_STATE:
         return {
            ...initialState,
         };
      case FIGURES_ERROR:
         return {
            ...state,
            error: payload,
            loading: false,
         };
      default:
         return state;
   }
}

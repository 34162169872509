import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateFranchiseData } from "../../tasks/franchiseT";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Toast from "../../components/Toast";

function FranchisesData({ updateFranchiseData }) {
   const navigate = useNavigate();
   const { state } = useLocation();
   const [con_password, setConPassword] = useState("");
   const [newdata, setNewdata] = useState({
      phone: "",
      email: "",
      password: "",
      status: "",
   });

   useEffect(() => {
      if (state) {
         setNewdata({
            ...newdata,
            phone: state.phone ? state.phone : "",
            status: state.status ? state.status : "",
            email: state.email ? state.email : "",
            id: state.id ? state.id : " ",
            createdAt: state.createdAt ? state.createdAt : " ",
            role: state.role ? state.role : " ",
            updatedAt: state.updatedAt ? state.updatedAt : " ",
            name: state.name ? state.name : " ",
         });
      }
      //eslint-disable-next-line
   }, [state]);

   const { phone, email, password, status } = newdata;

   const onChange = (e) => {
      setNewdata({ ...newdata, [e.target.name]: e.target.value });
   };

   const updateData = () => {
      if (password === con_password) {
         updateFranchiseData(newdata, navigate);
      } else {
         toast.error("Your Passwords do not match!", {
            position: toast.POSITION.TOP_RIGHT,
         });
      }
   };
   return (
      <div className="flex flex-col min-h-screen">
         <Header />
         <Toast />

         <div className="bg-login bg-cover flex flex-grow h-full w-full justify-center items-center sm:p-7 md:p-0">
            <table className="bg-gray-200 sm:w-full sm-md:w-2/3 md:w-1/3 h-full flex flex-col p-10 rounded-lg shadow-lg gap-y-2 ">
               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Name</td>
                  <td className="flex w-full p-1  justify-start">
                     {newdata.name}
                  </td>
               </tr>
               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Email</td>
                  <input
                     name="email"
                     onChange={(e) => onChange(e)}
                     className=" rounded-lg flex w-full p-1  justify-start"
                     value={email}
                  />
               </tr>
               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Password</td>
                  <input
                     name="password"
                     placeholder="New Password"
                     onChange={(e) => onChange(e)}
                     className=" rounded-lg flex w-full p-1  justify-start"
                     value={password}
                  />
               </tr>

               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">
                     Confirm Password
                  </td>
                  <input
                     name="con_password"
                     placeholder="Confrom Password"
                     onChange={(e) => setConPassword(e.target.value)}
                     className=" rounded-lg flex w-full p-1  justify-start"
                     value={con_password}
                  />
               </tr>

               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Phone</td>
                  <input
                     name="phone"
                     placeholder="Contact Number"
                     onChange={(e) => onChange(e)}
                     className=" rounded-lg flex w-full p-1  justify-start"
                     value={phone}
                  />
               </tr>
               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Status</td>
                  <select
                     name="status"
                     onChange={(e) => onChange(e)}
                     className=" rounded-lg flex w-full p-1  justify-start"
                     value={status}
                  >
                     <option value={"ACTIVE"}>ACTIVE</option>
                     <option value={"BLOCKED"}>BLOCKED</option>
                  </select>
               </tr>
               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Created At</td>
                  <td className="flex w-full p-1  justify-start">
                     {state.updatedAt &&
                        format(new Date(state.createdAt), "dd/MM/yyyy")}
                  </td>
               </tr>
               <tr className="flex sm:flex-col md:flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-start">Updated At</td>
                  <td className="flex w-full p-1  justify-start">
                     {state.updatedAt &&
                        format(new Date(state.updatedAt), "dd/MM/yyyy")}
                  </td>
               </tr>
               <tr className="flex flex-row gap-5  w-full justify-center items-center">
                  <td className="flex w-full p-1  justify-end">
                     <button
                        onClick={(e) => navigate(-1)}
                        className="bg-red-800 text-white p-1 px-4 px15:text-xl md:text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-500 duration-300"
                     >
                        Cancel
                     </button>
                  </td>
                  <td className="flex w-full p-1  justify-start">
                     <button
                        onClick={updateData}
                        className=" bg-green-700 text-white p-1 px-4 px15:text-xl md:text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-500 duration-300"
                     >
                        Update
                     </button>
                  </td>
               </tr>
            </table>
         </div>
         <SmallFooter />
      </div>
   );
}

FranchisesData.propTypes = {
   updateFranchiseAdminData: PropTypes.func.isRequired,
};

export default connect(null, { updateFranchiseData })(FranchisesData);

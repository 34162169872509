import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Add from "../../Assets/images/Add.png";
import useSocket from "../../Hooks/useSocket";
import Sidebar from "../../components/Sidebar";
import { LOCAL_SERVER } from "../../utils/URL";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import CustomModal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SmallFooter from "../../components/SmallFooter";
import { loadANS, addShape, removeShape } from "../../tasks/ANST";
import { useWindowSize } from "../../Hooks/useWindowSize";

export const Shapes = ({
   loading,
   userLoading,
   ANS,
   loadANS,
   user,
   addShape,
   removeShape,
   profile,
}) => {
   const [hoveredProfileIndex, setHoveredProfileIndex] = useState(null);
   const [createModal, setCreateModal] = useState(false);
   const [action, setAction] = useState(false);
   const [name, setName] = useState("");
   const [img, setImg] = useState();
   const { Width } = useWindowSize();

   const [data, setData] = useState({
      type: "SHAPE",
      messenger: "LOCATION",
      today: Date.now(),
      training: false,
      shapeName: "",
      shapeId: "",
      profileId: "",
      profileName: "",
   });
   const { error, myRes, emitEvent, emptyRes } = useSocket();
   const navigate = useNavigate();
   useEffect(() => {
      loadANS();
      if (profile) {
         setData({ ...data, profileId: profile.id, profileName: profile.name });
      }
      // eslint-disable-next-line
   }, [loadANS, profile, loading]);

   useEffect(() => {
      if (action) {
         if (error) {
            toast.error(error.message);
            console.log("error: " + error.message);
         } else {
            console.log(myRes);
            navigate(`/msg-box/${user.id}`);
         }
      }

      // eslint-disable-next-line
   }, [error, myRes]);

   const onAddClicked = (e) => {
      setCreateModal(true);
   };

   const onClose = () => setCreateModal(false);

   const formdata = new FormData();

   const onSubmit = (e) => {
      e.preventDefault();
      formdata.append("svg", img);
      formdata.append("type", "SHAPE");
      formdata.append("name", name);
      addShape(formdata, navigate, "/shapes");
      setImg();
      setName("");
      setCreateModal(false);
   };

   const onFigureClick = (e, fig) => {
      if (user.role === "LOCATION") {
         setData({ ...data, shapeId: fig.id, shapeName: fig.name });
      }
   };

   const handleDeleteFig = (e, fig) => {
      e.stopPropagation();
      removeShape(fig.id, navigate, "/shapes");
   };

   const handleSubmit = (e) => {
      console.log(data);
      if (user.role === "LOCATION" && data.shapeName !== "") {
         emitEvent("add-message", { locationId: user.id, myMsg: data });
         setAction(true);
      }
   };

   let num = 0;
   let tempRows = [];
   let row = [];

   if (ANS && !loading) {
      let numbers = ANS.filter((x) => x.type === "SHAPE");
      num = numbers.length;
      if (num !== 0) {
         for (let i = 0; i < num; i++) {
            row.push(numbers[i]);
            if (i === num - 1) {
               row.push({ id: "end" });
            }
            if (Width < 550) {
               if ((i + 1) % 2 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            } else if (Width < 650) {
               if ((i + 1) % 3 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            } else if (Width < 900) {
               if ((i + 1) % 4 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            } else if (Width < 1100) {
               if ((i + 1) % 5 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            } else if (Width < 1400) {
               if ((i + 1) % 6 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            } else if (Width < 1800) {
               if ((i + 1) % 7 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            } else {
               if ((i + 1) % 8 === 0 || i === num - 1) {
                  tempRows.push(row);
                  row = [];
               }
            }
         }
      } else {
         row.push({ id: "end" });
         tempRows.push(row);
      }
   }

   useEffect(() => {
      if (user !== null) {
         if (user.role === "LOCATION") {
            if (error) {
               toast.error(error.message);
               // setLoadSpin(false);
               console.log("error: " + error.message);
            } else if (myRes) {
               if (myRes.message !== "success") {
                  console.log(myRes);
                  emptyRes();
                  navigate(`/msg-box/${user.id}`);
               }
            }
         }
      }
      // eslint-disable-next-line
   }, [error, myRes]);

   return loading ? (
      <Spinner />
   ) : (
      <div className="h-screen   flex flex-col justify-between">
         <Header showProfile={true} />
         <div
            className={`  flex flex-grow  ${
               Width > 670 ? `flex-row` : `flex-col`
            } `}
         >
            <Sidebar />
            <div className="w-full h-full flex flex-col items-center justify-start">
               <Toast />
               <CustomModal
                  isOpen={createModal}
                  onClose={onClose}
                  closeBtn={true}
               >
                  <form
                     className="flex flex-col gap-y-10 items-center"
                     onSubmit={(e) => onSubmit(e)}
                  >
                     <h1 className="font-semibold text-5xl ">Add a Shape</h1>
                     <TextInput
                        hidden={false}
                        name={"name"}
                        placeholder={"Name"}
                        required={true}
                        type={"text"}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                     />
                     <input
                        type="file"
                        name="svg"
                        accept="image/*"
                        onChange={(e) => setImg(e.target.files[0])}
                     />
                     <input
                        type="submit"
                        title="Submit"
                        className="bg-neutral-700 w-1/3 rounded-lg py-2 text-xl font-semibold text-white hover:cursor-pointer hover:bg-primary"
                     />
                  </form>
               </CustomModal>
               {tempRows.map((tempRow, rowIndex) => (
                  <div
                     key={rowIndex}
                     className="flex sm:py-5 md:py-2 items-start justify-center mb-4 gap-x-5"
                  >
                     {tempRow.map((fig, index) => (
                        <>
                           {!userLoading &&
                           fig.id === "end" &&
                           user.role === "ADMIN" ? (
                              <div
                                 key={fig.id}
                                 className="p-2 h-full flex flex-col gap-y-2 justify-center items-center hover:cursor-pointer"
                              >
                                 <img
                                    src={Add}
                                    onClick={(e) => onAddClicked(e)}
                                    alt="add profile"
                                    className="hover:cursor-pointer w-28 h-28"
                                 />
                              </div>
                           ) : (
                              <>
                                 <div
                                    key={Math.random(3)}
                                    onClick={(e) => onFigureClick(e, fig)}
                                    onMouseEnter={() =>
                                       setHoveredProfileIndex(index)
                                    }
                                    onMouseLeave={() =>
                                       setHoveredProfileIndex(null)
                                    }
                                    className={`p-2 flex flex-col gap-y-2 hover:cursor-pointer relative ${
                                       data.shapeId === fig.id
                                          ? " border-4 border-primary"
                                          : ""
                                    }`}
                                 >
                                    {fig.id === "end" ? (
                                       <></>
                                    ) : (
                                       <>
                                          {!userLoading &&
                                          user.role === "ADMIN" ? (
                                             <div className="relative flex w-full justify-end">
                                                {hoveredProfileIndex ===
                                                   index && (
                                                   <BiTrash
                                                      className="h-8 w-8 mt-2 text-rose-700 bg-neutral-300 rounded-md p-1 absolute cursor-pointer"
                                                      onClick={(e) =>
                                                         handleDeleteFig(e, fig)
                                                      }
                                                   />
                                                )}
                                             </div>
                                          ) : (
                                             <></>
                                          )}
                                          <div className="bg-black p-3">
                                             <img
                                                src={LOCAL_SERVER + fig.value}
                                                alt={`figure`}
                                                className="h-32 w-32 "
                                             />
                                          </div>
                                       </>
                                    )}
                                 </div>
                              </>
                           )}
                        </>
                     ))}
                  </div>
               ))}
               {!userLoading && user.role === "LOCATION" ? (
                  <button
                     onClick={(e) => handleSubmit(e)}
                     className="bg-primary py-2 px-5 rounded-xl mb-10 text-3xl font-semibold text-white hover:cursor-pointer hover:bg-neutral-700 duration-300"
                  >
                     Submit
                  </button>
               ) : (
                  <></>
               )}
            </div>
         </div>
         <SmallFooter />
      </div>
   );
};

Shapes.propTypes = {
   ANS: PropTypes.array,
   user: PropTypes.object,
   loading: PropTypes.bool,
   profile: PropTypes.object,
   userLoading: PropTypes.bool,
   loadANS: PropTypes.func.isRequired,
   addShape: PropTypes.func.isRequired,
   removeShape: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   ANS: state.ANS.ANS,
   user: state.auth.user,
   loading: state.ANS.loading,
   userLoading: state.auth.userLoading,
   profile: state.profiles.currentProfile,
});

export default connect(mapStateToProps, { loadANS, addShape, removeShape })(
   Shapes
);

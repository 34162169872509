import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import { loadAllUsers } from "../../tasks/userT";
import SmallFooter from "../../components/SmallFooter";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { SERVER_URL } from "../../utils/URL";

const COLUMNS = [
  {
    Header: "Image",
    accessor: "Img",
    Cell: ({ value }) => {
      return (
        <div className="w-full flex justify-center items-center">
          <img src={SERVER_URL + value} alt="profile" className="h-16 w-16" />
        </div>
      );
    },
  },
  {
    Header: "Name",
    accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Contact No",
    accessor: "contactNo",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

const Users = ({ loading, users, loadAllUsers }) => {
  const [numberOptions] = useState([10, 15, 20, 30]);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(
    () => users,
    // eslint-disable-next-line
    [loading]
  );
  useEffect(() => {
    loadAllUsers();
  }, [loadAllUsers]);
  const eventHandler = () => {};
  return loading ? (
    <Spinner />
  ) : (
    <div className="w-screen h-screen flex flex-col flex-grow">
      <Header />
      <Toast />
      <div className="overflow-y-scroll overflow-x-hidden h-full  flex flex-col justify-between py-5">
        <Table
          columns={columns}
          data={data}
          numberOptions={numberOptions}
          eventHandler={eventHandler}
        />
      </div>
      <SmallFooter />
    </div>
  );
};

Users.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array,
  loadAllUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.users.loading,
  users: state.users.users,
});

export default connect(mapStateToProps, { loadAllUsers })(Users);

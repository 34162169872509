import React from "react";
import Header from "../components/Header";
import logo from "../Assets/images/completeLogo.png"
import img1 from "../Assets/images/googleImg_1.png"
import img2 from "../Assets/images/googleimg_2.png"
import img3 from "../Assets/images/googleImg_3.png"
import img4 from "../Assets/images/googleImg_4.png"
import img5 from "../Assets/images/googleImg_5.png"
const Instructions = () => {


   return (
      <>

         <Header />

         <div className="bg-login gap-y-7 py-10 flex flex-col bg-cover sm:p-8 md:p-0 md:h-screen w-full justify-center items-center">
            <img src={logo} alt="" className="sm:h-10 md:h-20 " />
            <img src={img1} alt="" className="shadow-custom h-5/6 " />
         </div>
         <div className="bg-login  flex bg-cover sm:p-8 md:p-0 md:h-screen w-full justify-center items-center">
            <img src={img2} alt="" className="shadow-custom h-5/6 " />
         </div>
         <div className="bg-login  flex bg-cover sm:p-8 md:p-0 md:h-screen w-full justify-center items-center">
            <img src={img3} alt="" className="shadow-custom h-5/6 " />
         </div>
         <div className="bg-login  flex bg-cover sm:p-8 md:p-0 md:h-screen w-full justify-center items-center">
            <img src={img4} alt="" className="shadow-custom h-5/6 " />
         </div>
         <div className="bg-login  flex bg-cover sm:p-8 md:p-0 md:h-screen w-full justify-center items-center">
            <img src={img5} alt="" className="shadow-custom h-5/6 " />
         </div>






      </>);
};

export default Instructions;

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import store from "./store";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <Provider store={store}>
         <PayPalScriptProvider
            options={{
               clientId:
                  "Ab4qdZiMVN2vwlStKJBrnuUU3pVJZHIfvBpVDb2WF8wLgzdcpG0WIvYNFPxPc3QBty1CeDUWaFN4HcQA",
               currency: "GBP",
               vault: true,
               intent: "subscription",
            }}
         >
            <App />
         </PayPalScriptProvider>
      </Provider>
   </React.StrictMode>
);

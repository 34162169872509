import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import { GiCancel } from "react-icons/gi";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import useSocket from "../../Hooks/useSocket";
import { loadShapes } from "../../tasks/ANST";
import Spinner from "../../components/Spinner";
import { titleCase } from "../../utils/TitleCase";
import SmallFooter from "../../components/SmallFooter";

const CustomMsg = ({
  isAuthenticated,
  loading,
  loadShapes,
  ANS,
  user,
  profile,
  loc,
}) => {
  const { LocationId } = useParams();
  const [load, setLoad] = useState(false);
  const [font, setFont] = useState("font-Monotype_Corsiva");
  const [msg, setMsg] = useState("");
  const [trig, setTrig] = useState(false);
  const [training, setTraining] = useState(false);
  const [inputData, setInputData] = useState([""]);
  const [data, setData] = useState({
    type: "MSG",
    messenger: "LOCATION",
    today: null,
  });

  useEffect(() => {
    loadShapes();
  }, [loadShapes]);

  const { error, myRes, emitEvent, emptyRes } = useSocket();
  const navigate = useNavigate();

  const [tomDate, setTomDate] = useState("");
  const onChangeToday = (val) => {
    let d = new Date();
    if (val === true) {
      d = d.toDateString();
    } else {
      d.setDate(d.getDate() + 1);
      setTomDate(d);
    }
    setData({ ...data, today: d });
  };

  const { today } = data;
  const handleShapeChange = (e) => {
    if (ANS) {
      const shape = ANS.find((x) => x.value === e.target.value);
      if (shape) {
        setData({ ...data, shapeName: shape.name, shapeId: shape.id });
        // imgRef.current.src = LOCAL_SERVER + e.target.value;
      } else {
        setData({ ...data, shapeName: "", shapeId: "" });
        // imgRef.current.src = eid;
      }
    }
  };

  const inputFun = (e) => {
    if (inputData[inputData.length - 1] !== "") {
      const t = "";
      setInputData([...inputData, t]);
    }
  };

  const changeMsgArray = (e, index) => {
    const arr = [...inputData];
    arr[index] = titleCase(e.target.value);
    setInputData(arr);
  };

  useEffect(() => {
    setMsg(inputData.join(" "));
  }, [inputData]);

  function delField(index) {
    const fields = [...inputData];
    fields.splice(index, 1);
    setInputData(fields);
  }

  const handleSubmit = (e) => {
    if (msg === "") {
      toast.error("You need to add a msg!");
    } else {
      if (today !== null) {
        setLoad(true);
        if (profile) {
          setData({
            ...data,
            font: font,
            msg: msg,
            training: training,
            profileName: profile.name,
            profileId: profile.id,
          });
        } else {
          setData({
            ...data,
            font: font,
            msg: msg,
            training: training,
          });
        }
        setTrig(!trig);
      } else {
        toast.error("Please specify the day");
      }
    }
  };

  useEffect(() => {
    if (trig) {
      emitEvent("add-message", { locationId: user.id, myMsg: data });
    }
    setTrig(false);
    //eslint-disable-next-line
  }, [trig]);

  useEffect(() => {
    console.log(" in use");
    if (error) {
      toast.error(error.message);
      setLoad(false);
      console.log("error: " + error.message);
    } else if (myRes) {
      console.log(myRes);
      if (myRes.message !== "success") {
        emptyRes();
        navigate(`/msg-box/${LocationId}`);
      }
    }

    // eslint-disable-next-line
  }, [error, myRes]);

  return loading || load ? (
    <Spinner />
  ) : (
    <>
      <div className="min-h-screen w-full bg-neutral-100 flex flex-col">
        <Header showProfile={true} />
        <Toast />
        <div className="bg-neutral-10 flex flex-grow justify-center items-center p-5">
          <div className="shadow-2xl bg-white  flex flex-col md:w-2/4 px5:w-2/3 sm:p-5 md:p-10 px14:w-1/3 gap-y-7 rounded-xl">
            <div className="w-full flex text-primary justify-end ">
              <GiCancel
                className="h-10 w-10 rounded-full hover:cursor-pointer"
                onClick={(e) => navigate("/msg-template")}
              />
            </div>

            <select
              name="font"
              value={font}
              onChange={(e) => setFont(e.target.value)}
              className=" py-2 px-2  w-full text-lg border-2 border-neutral-500 rounded-lg hover:cursor-pointer text-black"
            >
              <option value="">Select a Font</option>
              <option value="font-Sail" className="text-black text-sm ">
                Sail
              </option>
              <option value="font-Gabriel">Gabriel</option>
              <option value="font-Monotype_Corsiva">Monotype Corsiva</option>
              <option value="font-Walt_Disney_Script">Walt Disney Scrip</option>
            </select>

            <div className="flex flex-row w-full sm:gap-x-10 sm:justify-center">
              <div className="flex  flex-row  px8:w-1/2  bg-white md:justify-around ">
                <div>
                  <input
                    type="radio"
                    name="today"
                    // value={true}
                    checked={data.today === new Date().toDateString()}
                    defaultValue={true}
                    onChange={() => onChangeToday(true)}
                    className="hover:cursor-pointer"
                  />
                  <span className="ml-2 text-gray-900">Today</span>
                </div>
              </div>
              <div className="flex  flex-row  px8:w-1/2 bg-white md:justify-around">
                <div>
                  <input
                    type="radio"
                    name="today"
                    // value={false}
                    checked={data.today === tomDate}
                    defaultValue={false}
                    onChange={() => onChangeToday(false)}
                    className="hover:cursor-pointer"
                  />
                  <span className="ml-2 text-gray-900">Tomorrow</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full">
              <div className="flex flex-col w-full">
                {inputData.map((value, index) => (
                  <>
                    <div className="flex flex-row w-full mb-3 border-2 border-neutral-500 rounded-md">
                      <input
                        className="outline-none w-full my-1 px-2 text-xl "
                        value={value}
                        placeholder="Enter message"
                        onChange={(e) => changeMsgArray(e, index)}
                      />
                      {index === inputData.length - 1 ? (
                        <button
                          className="bg-primary text-white px-2"
                          onClick={(e) => inputFun(e)}
                        >
                          <BiPlus className="h-6 w-6" />
                        </button>
                      ) : (
                        <button
                          className="bg-primary text-white px-2"
                          onClick={(e) => delField(index)}
                        >
                          <BiMinus className="h-6 w-6" />
                        </button>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="flex md:flex-row sm:flex-col  items-center sm:gap-y-3">
              <select
                name="shape"
                className=" py-2 px-2 w-2/3 text-lg border-2 border-neutral-500 rounded-md"
                onChange={(e) => handleShapeChange(e)}
              >
                <option value={"none"}>{"No Shape"}</option>
                {ANS &&
                  ANS.map((shape) => (
                    <option value={shape.value} key={shape.id}>
                      {shape.name}{" "}
                    </option>
                  ))}
              </select>
              <div className="flex w-2/3 ">
                <input
                  type="checkbox"
                  className="md:ml-9 sm:p-2 md:p-1"
                  checked={training}
                  onClick={(e) => {
                    setTraining(!training);
                  }}
                />
                <label className="ml-3 text-xl ">Training</label>
              </div>
            </div>

            <div className="flex justify-center items-center w-full pt-14">
              <button
                className="bg-primary text-white py-2 px-5 rounded-lg text-xl font-semibold hover:cursor-pointer hover:bg-neutral-500"
                onClick={handleSubmit}
              >
                Submit Message
              </button>
            </div>
          </div>
        </div>
        <SmallFooter />
      </div>
    </>
  );
};

CustomMsg.propTypes = {
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  profile: PropTypes.object,
  user: PropTypes.object,
  ANS: PropTypes.array,
  loadShapes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.ANS.loading,
  ANS: state.ANS.ANS,
  profile: state.profiles.currentProfile,
  user: state.auth.user,
  loc: state.auth.user,
});

export default connect(mapStateToProps, { loadShapes })(CustomMsg);

import axios from "axios";
import { DASH_DATA, MESSAGES, MESSAGES_ERROR } from "./types";
// import { setAlert } from "./alertT";
import { LOCAL_SERVER } from "../utils/URL";

export const loadAllMessages = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/msgs/");
      dispatch({
         type: MESSAGES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: MESSAGES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const loadMessages = () => async (dispatch) => {
   try {
      const res = await axios.get(
         LOCAL_SERVER + "api/msgs/owner/locations/all/"
      );
      dispatch({
         type: MESSAGES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: MESSAGES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const loadLocMessages = () => async (dispatch) => {
   try {
      const res = await axios.get(
         LOCAL_SERVER + "api/msgs/location/self/test/mine/"
      );
      dispatch({
         type: MESSAGES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: MESSAGES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const getDashData = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/msgs/dashboard/owner/");
      dispatch({
         type: DASH_DATA,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: MESSAGES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const getAdminDashData = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/msgs/dashboard/admin/");
      dispatch({
         type: DASH_DATA,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: MESSAGES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiTrash } from "react-icons/bi";
// import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../tasks/authT";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";
import { setAlert } from "../../tasks/alertT";
import useSocket from "../../Hooks/useSocket";
import Add from "../../Assets/images/Add.png";
import Spinner from "../../components/Spinner";
import { LOCAL_SERVER } from "../../utils/URL";
import TextInput from "../../components/TextInput";
import SmallFooter from "../../components/SmallFooter";
import { useWindowSize } from "../../Hooks/useWindowSize";

import {
   addProfile,
   setProfile,
   loadProfiles,
   removeProfile,
   loadProfileImages,
} from "../../tasks/profileT";

import { toast } from "react-toastify";

const Profiles = ({
   loc,
   images,
   logout,
   loading,
   profiles,
   setAlert,
   addProfile,
   setProfile,
   loadProfiles,
   removeProfile,
   imagesLoading,
   loadProfileImages,
}) => {
   const [createModal, setCreateModal] = useState(false);
   const [hoveredProfileIndex, setHoveredProfileIndex] = useState(null);
   const { error, myRes, emptyRes, emitEvent } = useSocket();
   const [data, setData] = useState({
      name: "",
      avatar: "",
   });

   useEffect(() => {
      loadProfiles();
      loadProfileImages();
   }, [loadProfiles, loadProfileImages, loading]);
   const navigate = useNavigate();
   const onAddClicked = (e) => {
      setCreateModal(true);
   };
   const handleDeleteProfile = (e, profile) => {
      e.stopPropagation();
      removeProfile(profile.id, navigate);
   };
   const onClose = () => setCreateModal(false);
   const onProfileSelection = (e, profile) => {
      setProfile(profile.id, navigate);
   };

   const onChange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
   };
   const onAvatarSelect = (value) => {
      setData({ ...data, avatar: value });
   };
   const { name, avatar } = data;

   const revert = () => {
      setData({
         name: "",
         avatar: "",
      });
      setCreateModal(false);
   };

   const onSubmit = (e) => {
      e.preventDefault();
      addProfile(name, avatar, revert, navigate);
   };

   let numProfiles;
   let profileRows = [];
   let row = [];

   const { Width } = useWindowSize();

   if (profiles) {
      numProfiles = profiles.length;
      // Iterate through the images and create rows of four images each
      for (let i = 0; i < numProfiles; i++) {
         row.push(profiles[i]);
         if (i === numProfiles - 1) {
            row.push({ id: "end" });
         }

         if (Width > 670) {
            if (Width > 1000) {
               if ((i + 1) % 4 === 0 || i === numProfiles - 1) {
                  profileRows.push(row);
                  row = [];
               }
            } else {
               if ((i + 1) % 3 === 0 || i === numProfiles - 1) {
                  profileRows.push(row);
                  row = [];
               }
            }
         } else {
            if ((i + 1) % 2 === 0 || i === numProfiles - 1) {
               profileRows.push(row);
               row = [];
            }
         }
      }
   }

   const handelLogout = (e) => {
      logout(loc.role, emitEvent, loc.id);
   };

   // useEffect(() => {
   //    if (error) {
   //       // toast.error(error.message);
   //       // setLoadSpin(false);
   //       console.log("error: " + error.message);
   //       // if (error.message === "Store account already in use!") {
   //       //    setAlert(error.message);
   //       //    handelLogout();
   //       // }
   //    } else if (myRes) {
   //       if (myRes.message !== "success") {
   //          console.log(myRes);
   //          emptyRes();
   //          navigate(`/msg-box/${loc.id}`);
   //       }
   //    }

   //    // eslint-disable-next-line
   // }, [error, myRes]);

   useEffect(() => {
      if (error) {
         if (error.message !== "Store account already in use!") {
            toast.error(error.message);
         }
         // setLoadSpin(false);
         console.log("error: " + error.message);
      } else if (myRes) {
         if (myRes.message !== "success") {
            console.log(myRes);
            emptyRes();
            navigate(`/msg-box/${loc.id}`);
         }
      }
      // eslint-disable-next-line
   }, [error, myRes]);

   return loading ? (
      <Spinner />
   ) : (
      <div className="min-h-screen flex flex-col justify-between">
         <div className="bg-login bg-cover py-20  flex flex-grow flex-col gap-y-20 min-h-full justify-center items-center px-5">
            <button
               onClick={(e) => handelLogout(e)}
               className="bg-primary rounded-md py-2 px-6 text-[2vmax] text-white font-semibold duration-200 absolute top-20 right-20 hover:cursor-pointer hover:bg-neutral-600"
            >
               Logout
            </button>
            <Toast />
            <Modal isOpen={createModal} onClose={onClose} closeBtn={true}>
               <form
                  className="flex flex-col flex-grow sm:gap-y-2 md:gap-y-10 md:p-16 sm:mx-10 md:mx-0 w-full h-full justify-center items-center sm:py-5"
                  onSubmit={(e) => onSubmit(e)}
               >
                  <h1 className="sm:text-[6vw] md:text-5xl text-primary font-semibold ">
                     Add Profile
                  </h1>
                  <div className="flex flex-col flex-grow gap-y-6 m-6">
                     <h1 className="text-xl text-neutral-600 font-semibold ">
                        Profile Name
                     </h1>
                     <TextInput
                        type={"text"}
                        name={"name"}
                        placeholder={"Name"}
                        value={name}
                        required={true}
                        onChange={onChange}
                     />
                  </div>
                  <h1 className="text-xl text-neutral-600 font-semibold  sm:pb-3 ">
                     Chooose an Avatar for the profile:
                  </h1>
                  <div className="flex flex-row sm:gap-x-3 md:gap-x-5 mb-10 md:mx-10">
                     {!imagesLoading &&
                        images.map((i) => (
                           <img
                              src={LOCAL_SERVER + i}
                              alt="profile"
                              key={Math.random(3)}
                              className={`px450:h-[6vw] px450:w-[6vw] sm:h-[13vw] sm:w-[13vw]  cursor-pointer ${
                                 data.avatar === i
                                    ? `border-4 border-neutral-700 rounded-lg`
                                    : "border-0"
                              }`}
                              onClick={(e) => onAvatarSelect(i)}
                           />
                        ))}
                  </div>
                  <input
                     type="submit"
                     value="Submit"
                     className="px-5 py-2 bg-primary text-white text-xl font-semibold rounded-lg shadow hover:hover:bg-blue-500 hover:cursor-pointer duration-300 focus:outline-none"
                  />
               </form>
            </Modal>
            {profiles && profiles.length > 0 ? (
               <>
                  {profileRows.map((profileRow, rowIndex) => (
                     <div
                        key={Math.random(3)}
                        className="flex h-full items-center justify-center mb-4 gap-x-[6vw]"
                     >
                        {profileRow.map((profile, index) => (
                           <>
                              {profile.id === "end" ? (
                                 <div
                                    key={profile.id}
                                    className="p-2 flex flex-col gap-y-2 justify-center items-center hover:cursor-pointer"
                                 >
                                    <img
                                       src={Add}
                                       onClick={(e) => onAddClicked(e)}
                                       alt="add profile"
                                       className="hover:cursor-pointer sm:w-[25vw] sm:h-[25vw] px8:w-[20vw] px8:h-[20vw] md:w-[10vw] md:h-[10vw]"
                                    />
                                    <h1 className="text-neutral-700 text-xl font-semibold">
                                       Add New
                                    </h1>
                                 </div>
                              ) : (
                                 <>
                                    <div
                                       key={Math.random(3)}
                                       onClick={(e) =>
                                          onProfileSelection(e, profile)
                                       }
                                       onMouseEnter={() =>
                                          setHoveredProfileIndex(index)
                                       }
                                       onMouseLeave={() =>
                                          setHoveredProfileIndex(null)
                                       }
                                       className="p-2 flex flex-col gap-y-2 justify-center items-center hover:cursor-pointer relative"
                                    >
                                       <div className="relative flex w-full justify-end">
                                          {hoveredProfileIndex === index && (
                                             <BiTrash
                                                className="h-8 w-8 mt-2 text-rose-700 bg-neutral-300 rounded-md p-1 absolute cursor-pointer"
                                                onClick={(e) =>
                                                   handleDeleteProfile(
                                                      e,
                                                      profile
                                                   )
                                                }
                                             />
                                          )}
                                       </div>
                                       <img
                                          src={LOCAL_SERVER + profile.avatar}
                                          alt={`profile`}
                                          className="sm:w-[25vw] sm:h-[25vw] px8:w-[20vw] px8:h-[20vw] md:w-[10vw] md:h-[10vw]"
                                       />
                                       <h1 className="text-neutral-700 text-xl font-semibold">
                                          {profile.name}
                                       </h1>
                                    </div>
                                 </>
                              )}
                           </>
                        ))}
                     </div>
                  ))}
               </>
            ) : (
               <>
                  <h1 className="text-[5vmax] text-neutral-700 font-semibold">
                     Create Profile
                  </h1>
                  <img
                     src={Add}
                     onClick={(e) => onAddClicked(e)}
                     alt="add profile"
                     className="hover:cursor-pointer"
                  />{" "}
               </>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

Profiles.propTypes = {
   loading: PropTypes.bool,
   loc: PropTypes.object,
   profiles: PropTypes.array,
   logout: PropTypes.func.isRequired,
   setAlert: PropTypes.func.isRequired,
   addProfile: PropTypes.func.isRequired,
   setProfile: PropTypes.func.isRequired,
   loadProfiles: PropTypes.func.isRequired,
   removeProfile: PropTypes.func.isRequired,
   loadProfileImages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loc: state.auth.user,
   profiles: state.profiles.profiles,
   loading: state.profiles.loading,
   images: state.profiles.images,
   imagesLoading: state.profiles.imagesLoading,
});

export default connect(mapStateToProps, {
   logout,
   setAlert,
   addProfile,
   setProfile,
   loadProfiles,
   removeProfile,
   loadProfileImages,
})(Profiles);

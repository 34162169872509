import React from "react";

const Card = ({ vector, head, desc }) => {
   return (
      <div
         className="flex p-5 items-center justify-center bg-card bg-cover overflow-clip bg-no-repeat

          sm:h-[135vw] sm:w-[100vw]
          sm-md:h-[90vw] sm-md:w-[67vw]
          md:h-[40vw] md:w-[30vw]
          px15:h-[34vw] px15:w-[25vw]
          "
      >
         <div
            className="h-full flex flex-col justify-center items-center 
         sm:gap-y-2 sm:ml-[5vw]
         sm-md:gap-y-3 
         md:ml-0 md:gap-y-1
         px15:gap-y-2 px15:pl-5
         "
         >
            <img
               className="
               sm:h-[16vw] sm:w-[16vw]
               sm-md:h-[13vw] sm-md:w-[13vw]
               md:h-[7vw] md:w-[7vw]
               px15:h-[4.5vw] px15:w-[4.5vw]
               
              "
               src={vector}
               alt="vector"
            />

            <h2 className="font-semibold 
            sm:text-[6vw] 
            sm-md:text-[4.5vw] 
            md:text-[2.5vw] 
            px15:text-[2vw]          
            ">
               {head}
            </h2>

            <div className="sm:h-[2px]  bg-blue-800 md:h-[2px] w-3/6 sm-md:w-8/12 md:my-2 xl:h-[3px] xxl:h-[3px] "></div>

            <p
               className="text-center text-gray-600 font-inter font-medium overflow-y-auto 
               sm:text-[4vw] sm:w-[57vw]
               sm-md:text-[2.7vw] sm-md:w-[40vw]
               md:text-[1.1vw] md:w-[15vw]
               px15:text-[1vw] px15:w-[13vw]
               "
            >
               {desc}
            </p>
         </div>
      </div>
   );
};

export default Card;

import {
   FRANCHISES,
   FRANCHISE_ERROR,
   FRANCHISE_LOCATIONS,
   FRANCHISE_MSGS,
   FRANCHISES_INIT_STATE,
} from "../tasks/types";

const initialState = {
   franchises: null,
   loading: true,
   msgs: null,
   msgLoading: true,
   locations: null,
   locationsLoading: true,
   error: {},
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case FRANCHISES:
         return {
            ...state,
            franchises: payload.franchises,
            loading: false,
         };
      case FRANCHISE_LOCATIONS:
         return {
            ...state,
            locations: payload.locations,
            locationsLoading: false,
         };
      case FRANCHISE_MSGS:
         return {
            ...state,
            msgs: payload.msgs,
            msgLoading: false,
         };
      case FRANCHISE_ERROR:
         return {
            error: payload,
         };
      case FRANCHISES_INIT_STATE:
         return {
            ...state,
            franchises: null,
            loading: true,
         };
      default:
         return state;
   }
}

import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERTS } from "./types";

export const setAlert = (msg, alertType) => (dispatch) => {
   const id = Math.random();
   dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
   });

   setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 10000);
};

export const clearAlerts = () => (dispatch) => {
   console.log("clear");
   dispatch({ type: CLEAR_ALERTS });
};

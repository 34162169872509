import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import ReactToPrint from "react-to-print";

import { loadLocations } from "../../tasks/locationT";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import useSocket from "../../Hooks/useSocket";
import SmallFooter from "../../components/SmallFooter";
import { CLIENT_URL, LOCAL_SERVER } from "../../utils/URL";
import { useWindowSize } from "../../Hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../../components/Toast";

const QRPage = ({
   loading,
   isAuthenticated,
   user,
   locations,
   loadLocations,
}) => {
   const { Width } = useWindowSize();
   const [store, setStore] = useState(null);
   const printComponentRef = useRef();
   const navigate = useNavigate();
   const { error, myRes, emptyRes } = useSocket();
   useEffect(() => {
      if (user && user.role === "OWNER") {
         loadLocations();
      }
      //eslint-disable-next-line
   }, [loadLocations, user]);

   useEffect(() => {
      if (user && user.role === "LOCATION") {
         if (error) {
            toast.error(error.message);
            // setLoadSpin(false);
            console.log("error: " + error.message);
         } else if (myRes) {
            if (myRes.message !== "success") {
               console.log(myRes);
               emptyRes();
               navigate(`/msg-box/${user.id}`);
            }
         }
      }
      // eslint-disable-next-line
   }, [error, myRes]);

   return loading ? (
      <Spinner />
   ) : (
      <div className="min-h-screen flex flex-col justify-between">
         <Header showProfile={true} />
         <Toast />
         <ReactToPrint
            trigger={() => (
               <button
                  style={{ position: "absolute", top: 160, right: 30 }}
                  className={` bg-primary text-white py-1 px-4 rounded-md text-xl font-semibold hover:cursor-pointer hover:bg-neutral-600 duration-200`}
               >
                  Print this!
               </button>
            )}
            content={() => printComponentRef.current}
         />
         <div
            ref={printComponentRef}
            className=" flex flex-grow min-h-full justify-center items-center flex-col p-10 sm:pt-32 px8:pt-10"
         >
            {user.role === "OWNER" && locations ? (
               <>
                  <select
                     name="location"
                     onChange={(e) => setStore(e.target.value)}
                     value={store}
                     className=" py-2 px-2 sm:w-4/6 sm-md:w-1/3 border-2 border-neutral-500 rounded-md"
                  >
                     <option value="">Select a Store</option>
                     {locations.map((loc) => (
                        <option key={loc.id} value={loc.id}>
                           {loc.userName}
                        </option>
                     ))}
                  </select>
                  {store !== null ? (
                     <>
                        <h1 className="sm:text-[4vw] sm-md:text-xl font-semibold text-neutral-600 sm-md:mb-10 sm:my-5">
                           Please Scan the QR code to write messages on this
                           store
                        </h1>
                        <QRCodeSVG
                           value={CLIENT_URL + `#/anonymous-msg/${store}`}
                           size={Width > 670 ? 300 : 200}
                        />
                     </>
                  ) : (
                     <h1 className="sm:text-[4vw] sm-md:text-xl font-semibold text-neutral-600 sm-md:mb-10 sm:my-5">
                        Please Select a Store
                     </h1>
                  )}
               </>
            ) : (
               <></>
            )}
            {isAuthenticated && user.role === "LOCATION" ? (
               <>
                  <div className="flex flex-col w-full justify-center items-center gap-y-5">
                     <img
                        src={LOCAL_SERVER + user.Img}
                        alt="display"
                        className="sm:h-2/6 sm:w-2/6 sm:mt-20 md:mt-0 md:h-[15vh] md:w-[10vw]  rounded-xl"
                     />
                     <h1 className="text-5xl font-semibold text-primary ">
                        {user.userName}
                     </h1>
                  </div>
                  <h1 className="sm:text-md md:text-xl sm:py-2 md:py-0 font-semibold text-neutral-600 mb-10">
                     Please Scan the QR code to write messages on this store
                  </h1>
                  <QRCodeSVG
                     value={CLIENT_URL + `#/anonymous-msg/${user.id}`}
                     size={Width > 670 ? 249 : 200}
                  />
               </>
            ) : (
               <></>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

QRPage.propTypes = {
   loading: PropTypes.bool,
   isAuthenticated: PropTypes.bool,
   user: PropTypes.object,
   locations: PropTypes.array,
   loadLocations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.auth.userLoading,
   isAuthenticated: state.auth.isAuthenticated,
   user: state.auth.user,
   locations: state.locations.locations,
});

export default connect(mapStateToProps, { loadLocations })(QRPage);

import { useState } from "react";
import socket from "../connections/socket";

const useSocket = () => {
   const [error, setError] = useState(null);
   const [myRes, setRes] = useState(null);
   const [msg, setMsg] = useState(null);

   socket.on("error", (obj) => {
      // console.log(obj);

      setError(obj);
   });

   socket.on("message", (obj) => {
      // console.log(obj);
      setRes(obj);
      setError(null);
   });

   socket.on("msg", (obj) => {
      // console.log(obj);
      setMsg(obj);
      setError(null);
   });

   // Function to emit events
   const emitEvent = (eventName, data) => {
      socket.emit(eventName, data);
   };

   const emptyRes = () => {
      setRes(null);
   };

   // You can add more functions here for emitting other events as needed

   return { error, myRes, msg, emitEvent, emptyRes };
};

export default useSocket;

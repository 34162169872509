import React from "react";
import { RiCloseCircleFill } from "react-icons/ri";

const CustomModal = ({ isOpen, onClose, children, closeBtn }) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50 py-32"
          onClick={onClose}
        />
      )}

      {/* Modal */}
      {/* <div> */}
      <div
        className={`fixed flex flex-col flex-grow  sm:w-[90vw] md:w-auto md:p-10 justify-center items-center  md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-white rounded-lg shadow-lg z-50 sm:px-5   ${
          isOpen ? "opacity-100 scale-100" : "opacity-0 scale-0"
        }  transition-transform`}
      >
        {closeBtn ? (
          <div className="text-neutral-500 text-xl font-semibold w-full flex justify-end hover:cursor-pointer hover:text-rose-800 duration-200 sm:mt-2">
            <RiCloseCircleFill onClick={onClose} className=" h-10 w-10 " />
          </div>
        ) : (
          <></>
        )}
        {children}
        <div className="flex flex-grow justify-center">
          {/* Button to close the modal */}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CustomModal;

import React from "react";
import Header from "../components/Header";
import PaypalButton from "../components/PaypalButton";
import SmallFooter from "../components/SmallFooter";

import logo from "../Assets/images/completeLogo.png";
import Toast from "../components/Toast";

const Payment = () => {
   return (
      <div className="h-screen w-screen flex flex-col overflow-x-hidden">
         <Header />
         <Toast />
         <div className="flex flex-col bg-login bg-cover bg-no-repeat h-full w-full flex-grow">
            <div className="flex w-full justify-center items-center py-[2vh] flex-col sm:gap-5 md:gap-0">
               <img
                  src={logo}
                  alt="logo"
                  className="sm:w-[50vw] sm:h-[15vw] sm-md:w-[30vw] sm-md:h-[10vw]  md:w-[30vw] md:h-[9vw]  xl:w-[20vw] xl:h-[7vw]    px15:w-[13vw] px15:h-[4vw]"
               />
               <h1 className="sm:text-[10vw] sm-md:text-[7vw] md:text-[6vw] xl:text-[4vw] px15:text-[3vw] font-bold text-primary font-sans">
                  Package Plans
               </h1>
            </div>

            <div className="flex sm:flex-col sm-md:flex-row justify-center items-center flex-grow gap-[5vw] sm:p-[2vh]">
               {/* monthly */}
               <div className="flex flex-col w-80 bg-white shadow-lg pb-5 rounded-3xl">
                  <div className="flex flex-col justify-center items-center text-white rounded-3xl bg-primary p-8">
                     <h1 className="font-bold text-xl">Monthly Plan</h1>
                     <h1 className="font-thin text-sm">(Pay for The Month)</h1>
                     <h1>
                        <span className="text-yellow_clr">£ 20</span> / Month
                     </h1>
                  </div>

                  <div className="flex flex-col p-7 gap-y-3">
                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">
                           Message from any Mobile
                        </p>
                     </div>

                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">
                           Write in multiple Languages
                        </p>
                     </div>
                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">
                           Detailed Message Reports
                        </p>
                     </div>
                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">Free Management Portal</p>
                     </div>
                  </div>

                  <div className="flex flex-col px-5 gap-y-2">
                     <PaypalButton plan_id={"P-93B09523CB730750VMVEMAYQ"} />
                  </div>
               </div>

               {/* yearly */}

               <div className="flex flex-col w-80 bg-white shadow-lg pb-5 rounded-3xl">
                  <div className="flex flex-col justify-center items-center text-white rounded-3xl bg-primary p-8">
                     <h1 className="font-bold text-xl">Daily Plan</h1>
                     <h1 className="font-thin text-sm">(Pay for The Month)</h1>
                     <h1>
                        <span className="text-yellow_clr">£ 10</span> / Month
                     </h1>
                  </div>

                  <div className="flex flex-col p-7 gap-y-3">
                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">
                           Message from any Mobile
                        </p>
                     </div>

                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">
                           Write in multiple Languages
                        </p>
                     </div>
                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">
                           Detailed Message Reports
                        </p>
                     </div>
                     <div className="flex flex-row gap-x-5  items-center">
                        <span className="h-2 w-2 bg-primary rounded-full"></span>
                        <p className="text-gray2_clr">Free Management Portal</p>
                     </div>
                  </div>

                  <div className="flex flex-col px-5 gap-y-2">
                     <PaypalButton plan_id={"P-8XB814746J848260LMVEORYA"} />
                  </div>
               </div>
            </div>
         </div>

         <SmallFooter />
      </div>
   );
};

export default Payment;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useWindowSize } from "../Hooks/useWindowSize";
import hamburger from "../Assets/images/Hamburger.svg";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const { Width } = useWindowSize();
  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      {Width > 1250 ? (
        <>
          <div className="min-h-full w-1/5 flex flex-col bg-primary text-white">
            <NavLink
              exact="true"
              to="/shapes"
              className={({ isActive }) =>
                `py-6 text-center text-2xl font-semibold hover:bg-neutral-300 hover:text-primary ${
                  isActive ? " text-neutral-800" : ""
                }`
              }
            >
              Shapes
            </NavLink>
            <NavLink
              exact="true"
              to="/numbers"
              className={({ isActive }) =>
                `py-6 text-center text-2xl font-semibold hover:bg-neutral-300 hover:text-primary ${
                  isActive ? " text-neutral-800" : ""
                }`
              }
            >
              Numbers
            </NavLink>
            <NavLink
              exact="true"
              to="/alphabets"
              className={({ isActive }) =>
                `py-6 text-center text-2xl font-semibold hover:bg-neutral-300 hover:text-primary ${
                  isActive ? " text-neutral-800" : ""
                }`
              }
            >
              Alphabets
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="">
            <img
              className=" ml-3 h-[50px] w-[35px] mt-0 hover:cursor-pointer"
              src={hamburger}
              onClick={handleClick}
              alt="logo"
            />
            {open ? (
              <div
                className={`transition-all duration-500 ease-in-out absolute right-0 flex flex-col justify-center items-center top-36 w-full bg-neutral-400 bg-opacity-90 py-2 rounded-md z-50 ${
                  open ? "opacity-100 scale-100" : "opacity-0 scale-0"
                }`}
                style={{ transformOrigin: "top" }}
              >
                {" "}
                <NavLink
                  exact="true"
                  to="/shapes"
                  className={({ isActive }) =>
                    `py-6 text-center text-2xl font-semibold hover:bg-neutral-300 hover:text-primary ${
                      isActive ? " text-neutral-800" : ""
                    }`
                  }
                >
                  Shapes
                </NavLink>
                <NavLink
                  exact="true"
                  to="/numbers"
                  className={({ isActive }) =>
                    `py-6 text-center text-2xl font-semibold hover:bg-neutral-300 hover:text-primary ${
                      isActive ? " text-neutral-800" : ""
                    }`
                  }
                >
                  Numbers
                </NavLink>
                <NavLink
                  exact="true"
                  to="/alphabets"
                  className={({ isActive }) =>
                    `py-6 text-center text-2xl font-semibold hover:bg-neutral-300 hover:text-primary ${
                      isActive ? " text-neutral-800" : ""
                    }`
                  }
                >
                  Alphabets
                </NavLink>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Sidebar;

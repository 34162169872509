import React from "react";

import logo from "../Assets/images/Logo.png";
import call from "../Assets/icons/call.png";
import locPin from "../Assets/icons/locPin.png";
import mail from "../Assets/icons/mail.png";

function Footer() {
  return (
    <>
      <section
        className="bg-waves grid  justify-items-center
         sm:grid-cols-1 
         md:grid-cols-3 md:p-10 md:ps-32"
      >
        <div
          className=" flex flex-col gap-y-10 justify-center items-center
            sm:py-10"
        >
          <img src={logo} className="h-[15vmax] w-[15vmax]" alt="site-logo" />
          <h3 className="text-white font-semibold text-center text-xl w-2/3">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </div>
        <div className="flex flex-col gap-y-7 justify-center items-start sm:py-5">
          <h1 className="text-primary text-3xl font-semibold">Quick Links</h1>
          <h1 className="text-white text-2xl font-semibold">Home</h1>
          <h1 className="text-white text-2xl font-semibold">Pricing</h1>
          <h1 className="text-white text-2xl font-semibold">Why choose us</h1>
          <h1 className="text-white text-2xl font-semibold">Contact Us</h1>
        </div>
        <div className="h-1 bg-white w-60 rounded-md md:hidden"></div>
        <div className="flex flex-col gap-y-7 justify-center items-start sm:py-5 mb-9 sm:mr-5">
          <h1 className="text-blue text-3xl text-primary font-semibold">
            Contact Us
          </h1>
          <div className="flex flex-row gap-x-4">
            <img className="h-10 w-10" src={call} alt="location" />
            <h1 className="text-white text-lg font-semibold">Phone No</h1>
          </div>
          <div className="flex flex-row gap-x-4">
            <img className="h-10 w-10" src={locPin} alt="location" />
            <h1 className="text-white text-lg font-semibold">Address</h1>
          </div>
          <div className="flex flex-row gap-x-4">
            <img className="h-10 w-10" src={mail} alt="location" />
            <h1 className="text-white text-lg font-semibold">Email</h1>
          </div>
        </div>
      </section>
      <div className="bg-waves flex justify-center items-center">
        <div className="bg-zinc-500 px-5 flex justify-center py-2 rounded-md bottom-[-100] text-gray-300 transform skew-x-12 pr-6 pl-5 items-center">
          All Rights Reversed @CopyRight 2023
        </div>
        <div
          className="bg-zinc-500 px-5 py-2 rounded-md bottom-[-100] text-zinc-500 transform translate-x-5 -skew-x-12 right-1/2 mr-40 absolute
             sm:py-5 sm:px-3"
        >
          {""}
        </div>
      </div>
    </>
  );
}

export default Footer;

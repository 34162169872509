import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useWindowSize } from "../Hooks/useWindowSize";
import MenuAssets from "./MenuAssets";
import SmallHeader from "./SmallHeader";
import { useLocation } from "react-router-dom";

const AuthOwnerlinks = [
  {
    link: "/owner-dash",
    name: "Dashboard",
  },
  {
    link: "/owner-reports",
    name: "Reports",
  },
  {
    link: "/qr-page",
    name: "QR Code",
  },
];

const AuthAdminlinks = [
  {
    link: "/admin-dash",
    name: "Dashboard",
  },
  {
    link: "/users",
    name: "Users",
  },
  {
    link: "/stores",
    name: "Stores",
  },
  {
    link: "/franchises",
    name: "Franchises",
  },
  {
    link: "/alphabets",
    name: "SVGs",
  },
  {
    link: "/messages",
    name: "Messages",
  },
];

const AuthFranlinks = [
  {
    link: "/franchise/mylocs",
    name: "My Stores",
  },
  {
    link: "/franchise/msgs",
    name: "My Store's Messages",
  },
];

const Header = ({
  isAuthenticated,
  user,
  profile,
  userLoading,
  showProfile,
  scrollFunction,
}) => {
  const links = [
    {
      link: "/",
      name: "Home",
      onclick: () => {
        scrollFunction("homeref");
      },
    },
    {
      link: "/",
      name: "Pricing",
      onclick: () => {
        scrollFunction("pricingref");
      },
    },
    {
      link: "/",
      name: "Why Choose us",
      onclick: () => {
        scrollFunction("whychoseusref");
      },
    },
    {
      link: "/",
      name: "Contact Us",
      onclick: () => {
        scrollFunction("contectusref");
      },
    },
  ];
  let AuthLoclinks = [];
  if (isAuthenticated) {
    AuthLoclinks = [
      {
        link: "/profiles",
        name: "Home",
      },
      {
        link: "/shapes",
        name: "Cutting",
      },
      {
        link: "/qr-page",
        name: "QR Code",
      },
      {
        link: "/msgs-location",
        name: "Reports",
      },
    ];
  }

  const { Width } = useWindowSize();
  const [status, setStatus] = useState("noAuth");

  useEffect(() => {
    if (!userLoading) {
      if (isAuthenticated) {
        if (user.role === "OWNER") {
          setStatus("owner");
        } else if (user.role === "ADMIN") {
          setStatus("admin");
        } else if (user.role === "LOCATION") {
          setStatus("location");
        } else if (user.role === "FRANCHISE") {
          setStatus("FRANCHISE");
        }
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  const [showHeader, setShowHeader] = useState(true);
  const [yscrool, setyscrool] = useState(0);

  useEffect(() => {
    let prevScrollPos = window.scrollY;
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setyscrool(currentScrollPos);
      setShowHeader(prevScrollPos > currentScrollPos);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const location=useLocation();


  return (
    <>
      <div
  className={`${
    location.pathname === "/" ||  location.pathname ===  "admin-dash"
      ? yscrool
        ? `fixed top-0 left-0 right-0 bg-white z-10 transition-transform ${
            showHeader
              ? "transform translate-y-0"
              : "transform -translate-y-full"
          }`
        : ""
      : ""
  }`}
>

        {Width > 670 ? (
          <MenuAssets
            links={
              status === "noAuth"
                ? links
                : status === "owner"
                ? AuthOwnerlinks
                : status === "admin"
                ? AuthAdminlinks
                : status === "FRANCHISE"
                ? AuthFranlinks
                : AuthLoclinks
            }
            isAuthenticated={isAuthenticated}
            user={user}
            profile={profile}
            showProfile={showProfile}
          />
        ) : (
          <SmallHeader
            links={
              status === "noAuth"
                ? links
                : status === "owner"
                ? AuthOwnerlinks
                : status === "admin"
                ? AuthAdminlinks
                : status === "FRANCHISE"
                ? AuthFranlinks
                : AuthLoclinks
            }
            isAuthenticated={isAuthenticated}
            user={user}
          />
        )}
      </div>
    </>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  userLoading: PropTypes.bool,
  user: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userLoading: state.auth.userLoading,
  user: state.auth.user,
  profile: state.profiles.currentProfile,
});
export default connect(mapStateToProps)(Header);

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { BiSolidInfoCircle } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import { LOCAL_SERVER } from "../../utils/URL";
import Spinner from "../../components/Spinner";
import CustomModal from "../../components/Modal";
import TextInput from "../../components/TextInput";
// import TextInput from "../../components/TextInput";
import SmallFooter from "../../components/SmallFooter";
import { loadLocations, changePasswordOwner } from "../../tasks/locationT";

const LocationInfo = ({
   loading,
   locations,
   loadLocations,
   changePasswordOwner,
}) => {
   const { locationId } = useParams();
   const navigate = useNavigate();
   const [currLoc, setCurrLoc] = useState();
   const [password, setPasword] = useState();
   const [passCon, setPassCon] = useState();
   const [modal, setModal] = useState(false);
   useEffect(() => {
      loadLocations();
   }, [loadLocations]);

   useEffect(() => {
      if (locations) {
         //eslint-disable-next-line
         const loc = locations.find((x) => x.id == locationId);
         setCurrLoc(loc);
      }
      //eslint-disable-next-line
   }, [locations]);
   const handleSubmit = (e) => {
      if (password === passCon) {
         changePasswordOwner(currLoc.id, password, navigate);
         setModal(false);
      } else {
         toast.error("Your passwords Do not match!");
      }
   };
   return loading ? (
      <Spinner />
   ) : (
      <div className="min-h-screen flex flex-col ">
         <Header />
         <div className="bg-login bg-cover flex flex-grow justify-center items-center ">
            <Toast />
            <Tooltip id="review-tooltip" />
            <CustomModal
               isOpen={modal}
               onClose={(e) => setModal(false)}
               closeBtn={true}
            >
               <h1 className="my-10 text-4xl font-semibold text-primary">
                  Change Passsword
               </h1>
               <div className="w-full flex flex-row justify-center items-center gap-x-10">
                  <div className="flex w-1/2 flex-col justify-center items-center gap-y-10">
                     <h3 className="text-xl font-semibold">New Password :</h3>
                     <h3 className="text-xl font-semibold">
                        Conform Password :
                     </h3>
                  </div>
                  <div className="flex w-1/2 flex-col justify-center items-center gap-y-10">
                     <TextInput
                        hidden={true}
                        name={"newPassword"}
                        placeholder={"*****"}
                        required={true}
                        type={"password"}
                        value={password}
                        onChange={(e) => setPasword(e.target.value)}
                     />
                     <TextInput
                        hidden={true}
                        name={"conformPassword"}
                        placeholder={"*****"}
                        required={true}
                        type={"password"}
                        value={passCon}
                        onChange={(e) => setPassCon(e.target.value)}
                     />
                  </div>
               </div>
               <button
                  onClick={(e) => handleSubmit(e)}
                  className="bg-primary text-white py-2 px-5 text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300 mt-10"
               >
                  Submit
               </button>
            </CustomModal>
            {currLoc ? (
               <>
                  <div className="flex bg-red-20 flex-col sm:py-5 w-full justify-center items-center sm:gap-y-[4.5vh] px15:gap-y[12vh]">
                     <img
                        src={LOCAL_SERVER + currLoc.Img}
                        alt="location"
                        className="md:h-[11vw] md:w-[11vw] sm:h-[19vh] sm:w-[19vh] rounded-full"
                     />

                     <table className="md:text-xl px15:text-2xl md:font-semibold sm-md:w-2/6 sm:w-3/5 justify-center items-center ">
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-20 font-bold ">Name</td>
                           <td className="md:pl-20   sm:pl-20">
                              {" "}
                              {currLoc.userName}
                           </td>
                        </tr>
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-20 font-bold">Email</td>
                           <td className="md:pl-20 sm:pl-20 ">
                              {currLoc.email}
                           </td>
                        </tr>
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-20 font-bold ">Phone</td>
                           <td className="md:pl-20 sm:pl-20 ">
                              {currLoc.phone}
                           </td>
                        </tr>
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-20 font-bold ">Address</td>
                           <td className="md:pl-20 sm:pl-20 ">
                              {currLoc.address}
                           </td>
                        </tr>
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-9 font-bold flex flex-row items-center gap-x-3">
                              <BiSolidInfoCircle
                                 data-tooltip-id="review-tooltip"
                                 data-tooltip-content="How to get review Link for Your Business."
                                 data-tooltip-place="top"
                                 onClick={(e) => navigate("/instructions")}
                                 className="text-primary cursor-pointer"
                                 size={30}
                              />
                              Review Link
                           </td>
                           <td className="md:pl-20  sm:pl-20">
                              {" "}
                              {currLoc.reviewLink}
                           </td>
                        </tr>
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-20 font-bold"> Organization</td>
                           <td className="md:pl-20 sm:pl-20 ">
                              {currLoc.organization}
                           </td>
                        </tr>
                        <tr className="hover:bg-gray-300">
                           <td className="md:pl-20 font-bold"> Created On</td>
                           <td className="md:pl-20 sm:pl-20">
                              {" "}
                              {format(
                                 new Date(currLoc.createdAt),
                                 "dd/MM/yyyy hh:mm"
                              )}
                           </td>
                        </tr>
                     </table>

                     {/* 
                     <div className="w-full bg-green-200 flex flex-row justify-center items-center  ">
                        <div className="flex bg-blue-200 flex-col justify-start items-start h-full w-full gap-y-[2vh]">
                           <h3 className="text-xl font-semibold">Name :</h3>
                           <h3 className="text-xl font-semibold">Email :</h3>
                           <h3 className="text-xl font-semibold">Phone :</h3>
                           <h3 className="text-xl font-semibold">Address :</h3>
                           <h3 className="text-xl font-semibold">Review Link :</h3>
                           <h3 className="text-xl font-semibold">
                              Organization :
                           </h3>
                           <h3 className="text-xl font-semibold">Created On :</h3>
                        </div>
                        <div className="flex bg-red-200 flex-col justify-start items-start h-full w-full  gap-y-[2vh]">
                           <h3 className="text-xl font-semibold">
                              {currLoc.userName}
                           </h3>
                           <h3 className="text-xl font-semibold">
                              {currLoc.email}
                           </h3>
                           <h3 className="text-xl font-semibold">
                              {currLoc.phone}
                           </h3>
                           <h3 className="text-xl font-semibold">
                              {currLoc.address}
                           </h3>
                           <h3 className="text-xl font-semibold">
                              {currLoc.reviewLink}
                           </h3>
                           <h3 className="text-xl font-semibold">
                              {currLoc.organization}
                           </h3>

                           <h3 className="text-xl font-semibold">
                              {format(
                                 new Date(currLoc.createdAt),
                                 "dd/MM/yyyy hh:mm"
                              )}
                           </h3>
                        </div>
                     </div> */}

                     <div className="flex flex-row items-center justify-center sm:gap-x-3 md:gap-x-5 ">
                        <button
                           onClick={(e) => setModal(true)}
                           className="bg-primary text-white p-1 px-2 md:text-xl px15:text-2xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300"
                        >
                           {" "}
                           Change Password
                        </button>
                        <button
                           onClick={(e) => navigate(-1)}
                           className="bg-red-800 text-white p-1 px-4 px15:text-2xl md:text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300"
                        >
                           Cancel
                        </button>
                        <button
                           onClick={(e) =>
                              navigate("/edit-loc-info", { state: { currLoc } })
                           }
                           className="bg-gray-500 text-white p-1 px-4 px15:text-2xl md:text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300"
                        >
                           Edit Info
                        </button>
                     </div>
                  </div>
               </>
            ) : (
               <div>Working</div>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

LocationInfo.propTypes = {
   loading: PropTypes.bool,
   locations: PropTypes.array,
   loadLocations: PropTypes.func.isRequired,
   changePasswordOwner: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.locations.looading,
   locations: state.locations.locations,
});

export default connect(mapStateToProps, { loadLocations, changePasswordOwner })(
   LocationInfo
);

const Testimonial = ({ image, name, designation, description }) => {
  return (
    <>
      <div
        className="shadow-lg  md:w-5xl sm:w-[550px] md:h-64 sm:h-56  bg-blue-800 text-white sm:rounded-none md:rounded-3xl
            m-5 flex md:flex-row sm:flex-col md:py-11 sm:py-3 md:pl-5 sm:pl-0"
      >
        <div className="flex md:flex-col sm:flex-row items-center h-full">
          <img
            src={image}
            alt="images"
            className="md:h-24 md:w-24 md:rounded-2xl sm:h-16 sm:w-16 sm:rounded-full sm:ml-5"
          />
          <div className="flex flex-col sm:ml-5 md:ml-16 ">
            <h1 className="text-xl">{name}</h1>
            <p className="text-sm ">{designation}</p>
          </div>
        </div>
        <div className=" h-full md:w-4/6 ml-5 overflow-hidden md:px-6">
          {description}
        </div>
      </div>
    </>
  );
};

export default Testimonial;

import React, { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";

const TextInputLogos = ({
  icon: Icon,
  required,
  type,
  placeholder,
  onChange,
  name,
  value,
  hidden,
}) => {
  const [hide, setHide] = useState(true);

  const onHideClicked = (e) => {
    setHide(!hide);
  };
  return (
    <>
      <div className="flex flex-row w-full justify-center items-center bg-neutral-200 border-primary border-2 rounded-md">
        {Icon && <Icon className="h-6 w-6 m-2 text-primary font-bold" />}
        <input
          type={hidden ? (hide ? type : "text") : type}
          required={required}
          onChange={(e) => onChange(e)}
          name={name}
          value={value}
          placeholder={placeholder}
          className="flex w-full outline-none bg-neutral-200 py-3 px-1 placeholder:text-neutral-600"
        />
        {hidden ? (
          hide ? (
            <BiShow
              onClick={(e) => onHideClicked(e)}
              className="h-6 w-6 m-2 hover:cursor-pointer"
            />
          ) : (
            <BiHide
              onClick={(e) => onHideClicked(e)}
              className="h-6 w-6 m-2 hover:cursor-pointer"
            />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TextInputLogos;

import { io } from "socket.io-client";
import store from "../store";
import { setConn } from "../tasks/connectionT";
import { SOCKET_URL } from "../utils/URL";

const socket = io(SOCKET_URL, {
   transports: ["websocket"],
   reconnection: true,
   reconnectionDelay: 100,
});

// socket.on()

// socket.on("connect", () => {
//    console.log("connected");
//    const state = store.getState();
//    if (state.auth.user) {
//       const user = state.auth.user;
//       if (user.role === "LOCATION") {
//          socket.emit("login", { locationId: user.id });
//          store.dispatch(setConn(true));
//          console.log("connected + login");
//       }
//    }
// });

function setupSocketConnection() {
   const state = store.getState();

   if (state.auth.loading) {
      const unsubscribe = store.subscribe(() => {
         const updatedState = store.getState();
         if (!updatedState.auth.loading) {
            unsubscribe();

            if (updatedState.auth.user) {
               const user = updatedState.auth.user;
               if (user.role === "LOCATION") {
                  socket.emit("login", { locationId: user.id });
                  store.dispatch(setConn(true));
                  console.log("connected + login");
               }
            }
         }
      });
   } else if (state.auth.user && state.auth.user.role === "LOCATION") {
      socket.emit("login", { locationId: state.auth.user.id });
      store.dispatch(setConn(true));
      console.log("connected + login");
   }
}

socket.on("connect", () => {
   setupSocketConnection();
});

socket.on("reconnect", (attemptNumber) => {
   console.log("reconnected " + attemptNumber);
   // store.dispatch(setConn(false));
});

socket.on("disconnect", () => {
   console.log("disconnect");
   store.dispatch(setConn(false));
});

export default socket;

import React from "react";

function SmallFooter() {
  return (
    <div className="flex w-full justify-center items-center p-3 text-lg bg-neutral-700 text-neutral-400 font-kodch">
      All Rights Reserved Copyright@2023
    </div>
  );
}

export default SmallFooter;

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";

import { useState } from "react";
const Table = ({ columns, data, eventHandler, numberOptions }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    // pageOptions,
    previousPage,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [on, setOn] = useState(true);
  const filterfun = () => {
    setOn(!on);
  };

  const [Headers] = useState(["MSG", "SHAPE", "NUMBER", "ALPHABET"]);
  const [Messenger] = useState(["LOCATION"]);

  return (
    <>
      <div className="w-screen h-full  md:px-16 sm:px-3 flex flex-col ">
        <div className="flex w-full justify-start items-start py-2">
          <button
            onClick={filterfun}
            className="text-white px-4 py-1 bg-gray-600 font-bold rounded-md"
          >
            Filter
          </button>
        </div>
        <div className="w-full h-full  overflow-y-scroll px14:overflow-x-hidden">
          <table {...getTableProps()} className="w-full">
            {on ? (
              <>
                <thead
                  className="bg-gray-400 border-b-4 border-white
                        text-black py-3"
                >
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th className="pb-2" {...column.getHeaderProps()}>
                          {column.render("Header") === "Id" ||
                          column.render("Header") === "Image" ||
                          column.render("Header") === "Img"
                            ? ""
                            : column.render("Header")}
                          <span>
                            {column.render("Header") === "id" ||
                            column.render("Header") === "Id" ||
                            column.render("Header") === "Img" ||
                            column.render("Header") === "Image" ? (
                              ""
                            ) : column.render("Header") === "Type" ? (
                              <>
                                <br />
                                <select
                                  onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                  }}
                                  className="text-black rounded-md text-[13px]  px-2 py-1 mt-2"
                                >
                                  {Headers.map((item, index) => (
                                    <option key={index}>{item}</option>
                                  ))}
                                </select>
                              </>
                            ) : column.render("Header") === "Messenger" ? (
                              <>
                                <br />
                                <select
                                  onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                  }}
                                  className="text-black rounded-md text-[13px] px-2 py-1 mt-2"
                                >
                                  {Messenger.map((item, index) => (
                                    <option key={index}>{item}</option>
                                  ))}
                                </select>
                              </>
                            ) : column.render("Header") === "Profile" ||
                              column.render("Header") === "Message" ||
                              column.render("Header") === "Location Id" ||
                              column.render("Header") === "Username" ||
                              column.render("Header") === "Address" ||
                              column.render("Header") === "Guest Email" ||
                              column.render("Header") === "Shape Name" ||
                              column.render("Header") === "Name" ||
                              column.render("Header") === "Email" ||
                              column.render("Header") === "Contact No" ||
                              column.render("Header") === "Status" ? (
                              <>
                                <br />
                                <input
                                  type="text"
                                  placeholder={column.render("Header")}
                                  onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                  }}
                                  className="px-4 py-1 mt-2 text-[13px] sm:w-28 px15:w-36 rounded-md text-black "
                                />
                              </>
                            ) : column.render("Header") === "Time" ||
                              column.render("Header") === "Date" ||
                              column.render("Header") === "Submitted date" ? (
                              <>
                                <br />
                                <input
                                  placeholder={column.render("Header")}
                                  onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                  }}
                                  className="p-1 mt-2 w-32 text-[13px] text-black rounded-md"
                                  type="date"
                                />
                              </>
                            ) : column.render("Header") === "Phone" ||
                              column.render("Header") === "Owner Id" ? (
                              <>
                                <br />
                                <input
                                  placeholder={column.render("Header")}
                                  onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                  }}
                                  className="px-4 py-1 mt-2 text-[13px] sm:w-28 px15:w-36 rounded-md text-black"
                                  type="number"
                                />
                              </>
                            ) : column.render("Header") === "Training" ? (
                              <>
                                <br />
                                <select className="text-black rounded-md text-[13px] px-2 py-1 mt-2">
                                  <option>true</option>
                                  <option>false</option>
                                </select>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              </>
            ) : (
              ""
            )}

            <thead className=" bg-primary  text-white mt-4">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="p-3 sm:text-sm md:text-xl"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    className="hover:bg-gray-300 hover:cursor-pointer  "
                    // onClick={eventHandler}
                    onClick={() => {
                      eventHandler(index);
                    }}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        className="sm:text-sm md:text-xl t xxl:text-2xl h-10 text-center sm:w-20  "
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}

                        {/* {index === 1 && !Headers.includes(cell.render("Cell")) ? Headers.push(cell.render("Cell")) : ""}
                                    {index === 2 && Messenger.push(cell.render("Cell"))} */}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br></br>
        </div>
        <div className="flex justify-center gap-x-[1vw] py-[1.5vw] ">
          <span className=" sm:text-sm px8:text-xl">
            Page{" "}
            <strong>
              {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
            </strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              const newSize = Number(e.target.value);
              // Manually set pageSize and reset pageIndex when changing page size
              setPageSize(newSize);
            }}
          >
            {numberOptions.map((size) => (
              <option
                key={size}
                value={size}
                className="sm:text-sm px8:text-xl"
              >
                {size}
              </option>
            ))}
          </select>
          <button
            onClick={() => previousPage()}
            className="border-gray-900 hover:bg-primary bg-gray-300 rounded-lg p-1  w-20 sm:text-sm px8:text-xl"
          >
            Previous
          </button>
          {/* <div className="border-gray-900 hover:bg-green-400 bg-gray-300 rounded-lg p-1  w-10 flex justify-center">1</div>
                <div className="border-gray-900 hover:bg-green-400 bg-gray-300 rounded-lg p-1  w-10 flex justify-center">{pageOptions.length}</div> */}
          <button
            onClick={() => nextPage()}
            className="border-gray-900 hover:bg-primary bg-gray-300 rounded-lg p-1 w-20 sm:text-sm px8:text-xl"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default Table;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../tasks/authT";
import Header from "../components/Header";
import Toast from "../components/Toast";
import { LOCAL_SERVER } from "../utils/URL";
import Spinner from "../components/Spinner";
import success from "../Assets/images/success.png";
import SmallFooter from "../components/SmallFooter";
import complete from "../Assets/images/completeLogo.png";

const Verification = ({ logout }) => {
   const { token } = useParams();
   const [email, setEmail] = useState(" ");
   const [res, setRes] = useState(null);
   //    const [active, setActive] = useState(true);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      logout();
      if (token !== "false") {
         console.log(token);
         setLoading(true);
         async function api() {
            try {
               await axios.put(LOCAL_SERVER + `api/auth/verifyEmail/${token}`);
               setRes("verified");
               setLoading(false);
            } catch (error) {
               setRes("error");
               setLoading(false);
               if (error.response) {
                  console.log(error.response.data.message);
                  toast.error(error.response.data.message);
               } else {
                  console.log(error.message);
                  toast.error(error.message);
               }
            }
         }
         api();
      } else {
         setLoading(false);
      }
      //   eslint-disable-next-line
   }, []);

   const handleAgain = async (e) => {
      e.preventDefault();
      try {
         setLoading(true);
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };

         const body = JSON.stringify({ email });
         await axios.put(
            LOCAL_SERVER + `api/users/email/email/sendAgain`,
            body,
            config
         );
         setLoading(false);
         //  setActive(false);
      } catch (error) {
         setLoading(false);
         if (error.response) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
         } else {
            console.log(error.message);
            toast.error(error.message);
         }
      }
   };

   return loading ? (
      <Spinner />
   ) : (
      <div className="h-screen w-screen flex flex-col">
         <Header />
         <Toast />
         <div className="bg-login py-10 flex flex-1 bg-cover sm:p-8 md:p-0 justify-center items-center">
            <div className="bg-neutral-100 bg-opacity-80 shadow-2xl text-4xl text-center font-semibold text-neutral-800 p-20 w-[70%] flex flex-col justify-center items-center gap-y-14 rounded-lg">
               <img src={complete} alt="write-message" />
               {res === "verified" ? (
                  <>
                     <img src={success} alt="success" />
                     Your email has been verified!
                  </>
               ) : res === "error" ? (
                  <div className="flex flex-col gap-y-5">
                     <>Your email could not be verified! </>
                     <br />
                     <>Please try again!</>
                     <label> Your Account Email Address</label>
                     <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border-1 border-neutral-600 py-1 px-4"
                     />
                     <button>Send Again</button>
                  </div>
               ) : (
                  <div className="flex flex-col gap-y-5 justify-center items-center">
                     <h6 className="text-2xl font-medium">
                        Your Email is not verified Yet, Please verify your email
                        address to access your account.
                     </h6>
                     <h6 className="text-2xl font-medium mb-10">
                        If you did not recieve any email check your spam folder
                        or if your want to get another verification link provide
                        Your email!
                     </h6>
                     <label className="text-xl font-medium">
                        Enter your Account Email Address below
                     </label>
                     <form
                        onSubmit={(e) => handleAgain(e)}
                        className="flex flex-col w-1/2 "
                     >
                        <input
                           type="email"
                           name="email"
                           required
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           className="border-2 border-neutral-800 py-3 px-6 rounded text-xl font-medium"
                        />
                        <input
                           type="submit"
                           value={"Send Email Again"}
                           className="mt-10 py-3 text-3xl text-white hover:bg-pr bg-primary rounded-lg text-center hover:cursor-pointer"
                        />
                        {/* <button
                        onClick={(e) => handleAgain(e)}
                        className="mt-10 py-3 text-3xl text-white hover:bg-pr bg-primary rounded-lg"
                     >
                        Send Email Again
                     </button> */}
                     </form>
                  </div>
               )}
            </div>
         </div>
         <SmallFooter />
      </div>
   );
};

Verification.propTypes = {
   logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(Verification);

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { BiSolidInfoCircle } from "react-icons/bi";

import Header from "../../components/Header";
import TextInput from "../../components/TextInput";
import { changeLocation } from "../../tasks/locationT";
import SmallFooter from "../../components/SmallFooter";
import { useNavigate, useLocation } from "react-router-dom";

const EditInfo = ({ changeLocation }) => {
   const [img, setImg] = useState(null);
   const { state } = useLocation();
   const { currLoc } = state;
   const navigate = useNavigate();
   const [newInfo, setNewInfo] = useState({
      phone: "",
      status: "",
      address: "",
      reviewLink: "",
   });

   useEffect(() => {
      if (currLoc) {
         setNewInfo({
            ...newInfo,
            phone: currLoc.phone ? currLoc.phone : "",
            status: currLoc.status ? currLoc.status : "",
            address: currLoc.address ? currLoc.address : "",
            reviewLink: currLoc.reviewLink ? currLoc.reviewLink : " ",
         });
      }
      //eslint-disable-next-line
   }, [currLoc]);

   const onChange = (e) => {
      setNewInfo({ ...newInfo, [e.target.name]: e.target.value });
   };

   const handleFileChange = (e) => {
      setImg(e.target.files[0]);
   };

   const { phone, status, address, reviewLink } = newInfo;

   const data = new FormData();
   const onSubmit = (e) => {
      e.preventDefault();
      if (img) {
         data.append("Img", img);
      }
      data.append("phone", newInfo.phone);
      data.append("status", newInfo.status);
      data.append("address", newInfo.address);
      data.append("reviewLink", newInfo.reviewLink);

      changeLocation(currLoc.id, data, navigate);
   };

   return (
      <>
         <div className="flex flex-col min-h-screen w-full">
            <Header />
            <Tooltip id="review-tooltip" />
            <div className="bg-login h-full w-full bg-cover flex-col flex flex-grow justify-center items-center p-5">
               <h1 className="text-4xl py-3 font-bold"> {currLoc.userName}</h1>
               <form
                  className="flex h-full w-full justify-center items-center "
                  onSubmit={(e) => onSubmit(e)}
               >
                  <table className="bg-gray-200 px15:py-10 px15:h-full shadow-md flex flex-col gap-5  p-5 font-semibold md:text-lg sm:w-full px8:w-4/12 rounded-xl">
                     <tr className="flex gap-2 px-3 sm:flex-col lg:flex-row justify-between w-full">
                        <td>Add Logo:</td>
                        <td className="flex justify-end items-end sm:w-full lg:w-5/12">
                           <input
                              type="file"
                              accept="image/*"
                              name="Img"
                              onChange={(e) => handleFileChange(e)}
                              className=""
                           />
                        </td>
                     </tr>
                     <tr className="flex gap-2 px-3 sm:flex-col lg:flex-row justify-between w-full">
                        <td>Status:</td>
                        <td className="sm:w-full md:w-60 ">
                           <select
                              onChange={(e) => onChange(e)}
                              value={status}
                              name="status"
                              className="flex w-full p-3 py-2 px-3 bg-white justify-center items-center border-neutral-400 border-2 rounded-md focus:outline-2"
                           >
                              <option className="" value={"ACTIVE"}>
                                 ACTIVE
                              </option>
                              <option className="" value={"BLOCKED"}>
                                 BLOCKED
                              </option>
                           </select>
                        </td>
                     </tr>

                     <tr className="flex gap-2 px-3 sm:flex-col lg:flex-row justify-between w-full">
                        <td>Phone:</td>
                        <td>
                           <TextInput
                              type="text"
                              name={"phone"}
                              value={phone}
                              onChange={(e) => onChange(e)}
                              placeholder={"Enter Phone"}
                           />
                        </td>
                     </tr>

                     <tr className="flex gap-2 px-3 sm:flex-col md:flex-row  justify-between w-full">
                        <td className="flex flex-row">
                           Review Link:
                           <BiSolidInfoCircle
                              data-tooltip-id="review-tooltip"
                              data-tooltip-content="How to get review Link for Your Business."
                              data-tooltip-place="top"
                              onClick={(e) => navigate("/instructions")}
                              className="text-primary cursor-pointer ml-5"
                              size={30}
                           />
                        </td>
                        <td>
                           <TextInput
                              type="text"
                              name={"reviewLink"}
                              value={reviewLink}
                              onChange={(e) => onChange(e)}
                              placeholder={"Enter Review Link"}
                           />
                        </td>
                     </tr>
                     <tr className="flex gap-2 px-3 sm:flex-col lg:flex-row justify-between w-full">
                        <td>Address:</td>
                        <td className="sm:w-full md:w-60">
                           <textarea
                              className="flex w-full p-3 py-2 px-3 bg-white outline-none justify-center items-center border-neutral-400 border-2 rounded-md focus:outline-2 focus:border-neutral-400"
                              name={"address"}
                              value={address}
                              type={"text"}
                              onChange={(e) => onChange(e)}
                              placeholder={"Enter Address"}
                           />
                        </td>
                     </tr>
                     <tr className="flex  gap-5 px-3 flex-row  justify-center w-full">
                        <td>
                           <button
                              onClick={(e) => navigate(-1)}
                              className="bg-red-800 text-white p-1 px-4 px15:text-2xl md:text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300"
                           >
                              Cancel
                           </button>
                        </td>
                        <td>
                           <button className=" bg-gray-500 text-white p-1 px-4 px15:text-2xl md:text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300">
                              Update
                           </button>
                        </td>
                     </tr>
                  </table>
               </form>
            </div>
            <SmallFooter />
         </div>
      </>
   );
};

EditInfo.propTypes = {
   changeLocation: PropTypes.func.isRequired,
};

export default connect(null, { changeLocation })(EditInfo);

import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "../../tasks/authT";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import useSocket from "../../Hooks/useSocket";
import Spinner from "../../components/Spinner";
import { LOCAL_SERVER } from "../../utils/URL";
import TextInput from "../../components/TextInput";
import { useWindowSize } from "../../Hooks/useWindowSize";

const ChangePassword = ({ user, logout }) => {
   const { emitEvent } = useSocket();
   const { Width } = useWindowSize();
   const [loading, setLoading] = useState(false);
   const [password, setPassword] = useState("");
   const [passwordNew, setPasswordNew] = useState("");
   const [conformPassword, setConformPassword] = useState("");
   const navigate = useNavigate();

   const onSubmit = (e) => {
      e.preventDefault();
      if (passwordNew !== conformPassword) {
         toast.error("Your passwords donot match!");
      } else {
         changePassword(password, passwordNew);
      }
   };

   const changePassword = async (password, passwordNew) => {
      try {
         setLoading(true);
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };

         const body = JSON.stringify({ password, passwordNew });
         await axios.put(
            LOCAL_SERVER + `api/auth/changePassword/`,
            body,
            config
         );
         setLoading(false);
         toast.success("password Updated");
         logout(user.role, emitEvent, user.id);
      } catch (error) {
         setLoading(false);
         if (error.response) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
         } else {
            console.log(error.message);
            toast.error(error.message);
         }
      }
   };

   return loading ? (
      <Spinner />
   ) : (
      <div className="min-h-screen flex flex-col justify-between">
         <Header showProfile={true} />
         <div className="bg-login bg-cover sm:py-5 md:py-20 flex flex-grow flex-col sm:gap-y-5 md:gap-y-20 min-h-full md:justify-center md:items-center">
            <Toast />
            <form
               className="flex w-full flex-col justify-center items-center gap-y-20"
               onSubmit={(e) => onSubmit(e)}
            >
               <div className="w-full flex flex-row justify-center items-center">
                  {Width > 1000 ? (
                     <>
                        <div className="flex w-1/4 flex-col justify-center items-end gap-y-14">
                           <h3 className="text-xl font-semibold">
                              Current Password :
                           </h3>

                           <h3 className="text-xl font-semibold">
                              New Password :
                           </h3>
                           <h3 className="text-xl font-semibold">
                              Conform New Password :
                           </h3>
                        </div>
                        <div className="flex w-1/3 flex-col justify-center items-center px-10 gap-y-10">
                           <TextInput
                              hidden={true}
                              name={"password"}
                              placeholder={"*****"}
                              required={true}
                              type={"password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                           />
                           <TextInput
                              hidden={true}
                              name={"passwordNew"}
                              placeholder={"*****"}
                              required={true}
                              type={"password"}
                              value={passwordNew}
                              onChange={(e) => setPasswordNew(e.target.value)}
                           />
                           <TextInput
                              hidden={true}
                              name={"conformPassword"}
                              placeholder={"*****"}
                              required={true}
                              type={"password"}
                              value={conformPassword}
                              onChange={(e) =>
                                 setConformPassword(e.target.value)
                              }
                           />
                        </div>
                     </>
                  ) : (
                     <div className="flex w-5/6 flex-col justify-center items-start gap-y-5">
                        <h3 className="mt-5 md:text-xl font-semibold">
                           Current Password :
                        </h3>
                        <TextInput
                           hidden={true}
                           name={"password"}
                           placeholder={"*****"}
                           required={true}
                           type={"password"}
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                        />
                        <h3 className="mt-5 md:text-xl font-semibold">
                           New Password :
                        </h3>

                        <TextInput
                           hidden={true}
                           name={"passwordNew"}
                           placeholder={"*****"}
                           required={true}
                           type={"password"}
                           value={passwordNew}
                           onChange={(e) => setPasswordNew(e.target.value)}
                        />
                        <h3 className="mt-5 md:text-xl font-semibold">
                           Conform New Password :
                        </h3>
                        <TextInput
                           hidden={true}
                           name={"conformPassword"}
                           placeholder={"*****"}
                           required={true}
                           type={"password"}
                           value={conformPassword}
                           onChange={(e) => setConformPassword(e.target.value)}
                        />
                     </div>
                  )}
               </div>
               <div className="flex w-1/3 flex-row items-center justify-center gap-x-10">
                  <input
                     type="submit"
                     className="bg-primary text-white py-2 px-5 text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300"
                  />
                  <button
                     onClick={(e) => navigate(-1)}
                     className="bg-red-800 text-white py-2 px-5 text-xl font-semibold rounded-lg hover:cursor-pointer hover:bg-neutral-700 duration-300"
                  >
                     Cancel
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
};

ChangePassword.propTypes = {
   user: PropTypes.object,
   logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(ChangePassword);

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../Hooks/useWindowSize";

import {
   Chart as Chartjs,
   LineElement,
   CategoryScale,
   LinearScale,
   PointElement,
   Legend,
   Filler,
   Tooltip,
} from "chart.js";

import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Add from "../../Assets/images/Add.png";
import { LOCAL_SERVER } from "../../utils/URL";
import { getDashData } from "../../tasks/messageT";
import { loadLocations } from "../../tasks/locationT";
import SmallFooter from "../../components/SmallFooter";
import CustomModal from "../../components/Modal";

Chartjs.register(
   LineElement,
   CategoryScale,
   LinearScale,
   PointElement,
   Legend,
   Filler,
   Tooltip
);

export const OwnerDashboard = ({
   user,
   loadLocations,
   locations,
   dashData,
   dashLoading,
   getDashData,
}) => {
   const [totalData, setTotalData] = useState([]);
   const [msgData, setMsgData] = useState([]);
   const [chartRows, setChartRows] = useState([]);
   const [chartsData, setChartsData] = useState([]);
   const [chartsDataLoad, setChartsDataLoad] = useState(true);
   const [qtyModel, setQtyModel] = useState(false);
   useEffect(() => {
      loadLocations();
      getDashData();
   }, [loadLocations, getDashData]);

   useEffect(() => {
      if (!dashLoading) {
         setTotalData(dashData.totalYearMsgs);
         setMsgData(dashData.locationsWithDataArray);
         // console.log(dashData.locationsWithDataArray);
      }
      //eslint-disable-next-line
   }, [dashLoading]);

   const navigate = useNavigate();

   const onAddLocation = (e) => {
      if (user.sub_status === "ACTIVE") {
         if (locations.length >= user.noOfLocations) {
            console.log(locations.length);
            // navigate("/payments");
            // setQtyModel(true);
         } else {
            console.log(user.noOfLocations);
            navigate("/storeReg");
         }
      } else {
         navigate("/payments");
      }
   };

   const onCloseQtyModel = () => setQtyModel(false);

   const onLocationSelection = (e, loc) => {
      navigate(`/location-info/${loc.id}`);
   };

   const { Width } = useWindowSize();

   let Rows = [];
   let row = [];
   if (!dashLoading && dashData.locations) {
      const num = dashData.locations.length;

      // Iterate through the images and create rows of four images each
      for (let i = 0; i < num; i++) {
         row.push(dashData.locations[i]);
         if (i === num - 1) {
            row.push({ id: "end" });
         }
         if (Width > 1500) {
            if ((i + 1) % 7 === 0 || i === num - 1) {
               Rows.push(row);
               row = [];
            }
         } else if (Width > 1100) {
            if ((i + 1) % 6 === 0 || i === num - 1) {
               Rows.push(row);
               row = [];
            }
         } else if (Width > 900) {
            if ((i + 1) % 5 === 0 || i === num - 1) {
               Rows.push(row);
               row = [];
            }
         } else if (Width > 750) {
            if ((i + 1) % 4 === 0 || i === num - 1) {
               Rows.push(row);
               row = [];
            }
         } else if (Width > 400) {
            if ((i + 1) % 3 === 0 || i === num - 1) {
               Rows.push(row);
               row = [];
            }
         } else {
            if ((i + 1) % 2 === 0 || i === num - 1) {
               Rows.push(row);
               row = [];
            }
         }
      }
   }

   useEffect(() => {
      // console.log(msgData);
      if (Array.isArray(msgData)) {
         console.log(msgData);
         for (let i = 0; i < msgData.length; i++) {
            const data = {
               labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "June",
                  "July",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
               ],
               datasets: [
                  {
                     label: `Total Msgs of ${msgData[i].locationName} in 2023`,
                     data: msgData[i].charArray,
                     fill: true,
                     borderColor: "#9b9b9b",
                     backgroundColor: "#d5d5d5",
                     pointBorderColor: "#194FB7",
                     pointBackgroundColor: "#194FB7",
                     tension: 0.4,
                  },
               ],
            };
            let a = chartsData;
            a.push(data);
            setChartsData(a);
         }
         setChartsDataLoad(false);
      }
      //eslint-disable-next-line
   }, [msgData]);

   useEffect(() => {
      if (!chartsDataLoad) {
         let chartRow = [];
         const num = chartsData.length;

         // Iterate through the images and create rows of four images each
         for (let i = 0; i < num; i++) {
            chartRow.push(chartsData[i]);
            if ((i + 1) % 2 === 0 || i === num - 1) {
               let ar = chartRows;
               ar.push(chartRow);
               setChartRows(ar);
               chartRow = [];
            }
         }
         setChartsDataLoad(true);
      }
      //eslint-disable-next-line
   }, [chartsDataLoad]);

   const yearlyData = {
      labels: [
         "Jan",
         "Feb",
         "Mar",
         "Apr",
         "May",
         "June",
         "July",
         "Aug",
         "Sep",
         "Oct",
         "Nov",
         "Dec",
      ],
      datasets: [
         {
            label: "Total Msgs in 2023",
            data: totalData,
            fill: true,
            borderColor: "#9b9b9b",
            backgroundColor: "#d5d5d5",
            pointBorderColor: "#194FB7",
            pointBackgroundColor: "#194FB7",
            tension: 0.4,
         },
      ],
   };

   const yearlyOptions = {
      plugin: {
         legend: true,
      },
      scales: {
         y: {
            min: 0,
            max: 200,
         },
      },
   };

   return dashLoading ? (
      <></>
   ) : (
      <div className="min-h-screen flex flex-col justify-between">
         <Header />
         <div className="bg-login bg-cover py-10 flex flex-grow flex-col min-h-full items-center sm:p-[10vw] md:p-20">
            <Toast />
            <CustomModal
               isOpen={qtyModel}
               closeBtn={true}
               onClose={onCloseQtyModel}
            >
               <div>
                  <h1> Change quantity in subscription</h1>
               </div>
            </CustomModal>
            <div className="bg-primary text-white px-10 py-2 rounded-3xl m-2">
               <h1 className="font-semibold text-xl">My Stores</h1>
            </div>
            <div className="bg-neutral-100 flex-col rounded-lg shadow-2xl w-full flex justify-center items-center">
               <div className=" pt-[2vw] flex flex-row">
                  <h1 className=" text-xl font-semibold text-neutral-700 ">
                     Subscription Status :
                  </h1>
                  <h1
                     className={` px-2 text-xl font-semibold ${
                        user.sub_status === "ACTIVE"
                           ? "text-green-600"
                           : "text-rose-800"
                     }`}
                  >
                     {user.sub_status}
                  </h1>
               </div>
               <div className=" p-[3vw] ">
                  {dashData.locations && dashData.locations.length > 0 ? (
                     Rows.map((Row, rowIndex) => (
                        <div
                           key={rowIndex}
                           className="flex h-full items-center justify-center mb-4 gap-x-[5vw]"
                        >
                           {Row.map((loc) => (
                              <>
                                 {loc.id === "end" ? (
                                    <div
                                       key={loc.id}
                                       className="p-2 flex flex-col gap-y-2 justify-center items-center hover:cursor-pointer"
                                    >
                                       <img
                                          src={Add}
                                          onClick={(e) => onAddLocation(e)}
                                          alt="add location"
                                          className="h-20 w-20 rounded-lg hover:cursor-pointer"
                                       />
                                       <h1 className="text-neutral-700 text-xl font-semibold">
                                          Add New
                                       </h1>
                                    </div>
                                 ) : (
                                    <>
                                       <div
                                          key={loc.id}
                                          onClick={(e) =>
                                             onLocationSelection(e, loc)
                                          }
                                          className="p-2 flex flex-col gap-y-2 justify-center items-center hover:cursor-pointer"
                                       >
                                          <img
                                             src={LOCAL_SERVER + loc.Img}
                                             alt={`location`}
                                             className="h-20 w-20 rounded-lg"
                                          />
                                          <h1 className="text-neutral-700 text-xl font-semibold">
                                             {loc.userName}
                                          </h1>
                                       </div>
                                    </>
                                 )}
                              </>
                           ))}
                        </div>
                     ))
                  ) : (
                     <div
                        onClick={(e) => onAddLocation(e)}
                        className="bg-white text-neutral-700 border-4 border-primary px-8 py-1 rounded-3xl w-fit hover:cursor-pointer hover:bg-neutral-300 duration-300"
                     >
                        <h1 className="font-semibold text-xl">Add Store</h1>
                     </div>
                  )}
               </div>
            </div>
            {/* <div className="bg-primary text-white px-10 py-2 rounded-3xl mt-8">
               <h1 className="font-semibold text-xl">My Stores graphs</h1>
            </div> */}
            <div className="w-full flex justify-center">
               {" "}
               <div className="bg-neutral-100 sm:p-5 flex justify-center md:p-10 rounded-xl shadow-2xl sm:w-full md:w-1/2 mt-8">
                  <Line data={yearlyData} options={yearlyOptions} />
               </div>
            </div>

            {chartRows.length > 0 &&
               chartRows.map((Row, rowIndex) => (
                  <div
                     key={rowIndex}
                     className="w-full flex flex-row gap-x-5 px-5"
                  >
                     {Row.map((chart) => (
                        <div className="bg-neutral-100 p-10 rounded-xl shadow-2xl w-1/2 flex justify-center items-center mt-8">
                           <Line data={chart} options={yearlyOptions} />
                        </div>
                     ))}
                  </div>
               ))}
         </div>
         <SmallFooter />
      </div>
   );
};

OwnerDashboard.propTypes = {
   msgData: PropTypes.array,
   totalData: PropTypes.array,
   locations: PropTypes.array,
   user: PropTypes.object,
   // loading: PropTypes.bool,
   dashLoading: PropTypes.bool,
   loadLocations: PropTypes.func.isRequired,
   getDashData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   // loading: state.messages.loading,
   locations: state.locations.locations,
   user: state.auth.user,
   dashLoading: state.messages.dashLoading,
   dashData: state.messages.dashData,
});

export default connect(mapStateToProps, {
   loadLocations,
   getDashData,
})(OwnerDashboard);

import axios from "axios";
import {
   LOCATIONS,
   LOCATIONS_ERROR,
   // LOCATIONS_INIT_STATE,
   LOGIN_FAILED,
   LOGOUT,
} from "./types";
import { setAlert } from "./alertT";
import { LOCAL_SERVER } from "../utils/URL";

// looad all locations admin
export const loadAllLocations = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/locations");
      dispatch({
         type: LOCATIONS,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: LOCATIONS_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const loadLocations = () => async (dispatch) => {
   try {
      const res = await axios.get(
         LOCAL_SERVER + "api/locations/user/self/mine/"
      );
      dispatch({
         type: LOCATIONS,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: LOCATIONS_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

//location addition
export const addLocation = (data, navigate) => async (dispatch) => {
   try {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      };

      await axios.post(
         LOCAL_SERVER + `api/locations/addLocation`,
         data,
         config
      );

      dispatch(setAlert("Location Added!", "success"));
      navigate("/owner-dash");
   } catch (err) {
      console.log(err.response.data.message);
      const errors = err.response.data.message;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: LOCATIONS_ERROR,
         payload: {
            msg: err.response.data.message,
            status: err.response.status,
         },
      });
   }
};

// Change Password
export const changePassword =
   (password, newPassword, navigate) => async (dispatch) => {
      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };

      const body = JSON.stringify({ password, newPassword });
      try {
         await axios.put(
            LOCAL_SERVER + "api/locations/changePassword",
            body,
            config
         );

         dispatch(
            setAlert(
               "Passsword Changed Successfully. You Have to login again!",
               "success"
            )
         );
         dispatch({
            type: LOGOUT,
         });
         navigate("/");
      } catch (err) {
         const errors = err.response.data;
         if (errors) {
            dispatch(setAlert(errors, "danger"));
            dispatch({
               type: LOCATIONS_ERROR,
            });
         }
         dispatch({
            type: LOGIN_FAILED,
         });
         navigate("/");
      }
   };

export const changePasswordOwner =
   (id, newPassword, navigate) => async (dispatch) => {
      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };

      const body = JSON.stringify({ newPassword });
      try {
         await axios.put(
            LOCAL_SERVER + `api/locations/changePassword/owner/${id}`,
            body,
            config
         );

         dispatch(setAlert("Passsword Changed Successfully.", "success"));

         navigate(-1);
      } catch (err) {
         console.log(err);
         const errors = err.response.data;
         if (errors) {
            dispatch(setAlert(errors, "danger"));
            dispatch({
               type: LOCATIONS_ERROR,
            });
         }
         dispatch({
            type: LOGIN_FAILED,
         });
         navigate("/");
      }
   };

//Chnage Location
export const changeLocation = (id, data, navigate) => async (dispatch) => {
   try {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      };

      await axios.put(
         LOCAL_SERVER + `api/locations/changeLocation/${id}`,
         data,
         config
      );

      dispatch(setAlert("Location Changed!", "success"));
      navigate("/owner-dash");
   } catch (err) {
      console.log(err.response.data.message);
      const errors = err.response.data.message;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: LOCATIONS_ERROR,
         payload: {
            msg: err.response.data.message,
            status: err.response.status,
         },
      });
   }
};

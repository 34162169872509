import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadFranchises } from "../../tasks/franchiseT";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import Toast from "../../components/Toast";
import Table from "../../components/Table";

import { useNavigate } from "react-router";

const COLUMNS = [
   {
      Header: "Id",
      accessor: "id",
   },
   {
      Header: "Name",
      accessor: "name",
   },
   {
      Header: "Email",
      accessor: "email",
   },
   {
      Header: "Phone",
      accessor: "phone",
   },
   {
      Header: "Status",
      accessor: "status",
   },
];

const Franchises = ({ loading, franchises, loadFranchises }) => {
   const [numberOptions] = useState([10, 15, 20, 30]);
   const columns = useMemo(() => COLUMNS, []);
   const data = useMemo(
      () => franchises,
      // eslint-disable-next-line
      [loading]
   );

   useEffect(() => {
      loadFranchises();
   }, [loadFranchises]);

   const navigate = useNavigate();
   const eventHandler = (index) => {
      navigate("/franchises/data", { state: data[index] });
   };

   return loading ? (
      <Spinner />
   ) : (
      <div className="h-screen  flex flex-col ">
         <Header />
         <Toast />
         <div className="h-full  flex flex-grow py-5">
            {franchises ? (
               <Table
                  columns={columns}
                  data={data}
                  numberOptions={numberOptions}
                  eventHandler={eventHandler}
               />
            ) : (
               <>No data!</>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

Franchises.propTypes = {
   loading: PropTypes.bool,
   franchises: PropTypes.array,
   loadFranchises: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.franchise.loading,
   franchises: state.franchise.franchises,
});
export default connect(mapStateToProps, { loadFranchises })(Franchises);

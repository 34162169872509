import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Chart as Chartjs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Filler,
  Tooltip,
} from "chart.js";

import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import SmallFooter from "../../components/SmallFooter";
import { getAdminDashData } from "../../tasks/messageT";

Chartjs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Filler,
  Tooltip
);

export const AdminDashbboard = ({ loading, dashData, getAdminDashData }) => {
  const [totalData, setTotalData] = useState([]);
  const [chartRows, setChartRows] = useState([]);
  const [chartsData, setChartsData] = useState([]);
  const [chartsDataLoad, setChartsDataLoad] = useState(true);
  const [typeRows, setTypeRows] = useState([]);
  const [typesData, setTypesData] = useState([]);
  const [typesDataLoad, setTypesDataLoad] = useState(true);
  useEffect(() => {
    getAdminDashData();
  }, [getAdminDashData]);

  useEffect(() => {
    if (!loading) {
      setTotalData(dashData.totalYearMsgs);
    }
    //eslint-disable-next-line
  }, [loading]);

  const yearlyData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Total Msgs in 2023",
        data: totalData,
        fill: true,
        borderColor: "#9b9b9b",
        backgroundColor: "#d5d5d5",
        pointBorderColor: "#194FB7",
        pointBackgroundColor: "#194FB7",
        tension: 0.4,
      },
    ],
  };

  const yearlyOptions = {
    plugin: {
      legend: true,
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };

  useEffect(() => {
    if (!loading && dashData.messengerArray) {
      for (let i = 0; i < dashData.messengerArray.length; i++) {
        const data = {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: `Total Msgs from ${
                dashData.messengerArray[i].messenger === "LOCATION"
                  ? "locations"
                  : "guests"
              } in 2023`,
              data: dashData.messengerArray[i].charArray,
              fill: true,
              borderColor: "#9b9b9b",
              backgroundColor: "#d5d5d5",
              pointBorderColor: "#194FB7",
              pointBackgroundColor: "#194FB7",
              tension: 0.4,
            },
          ],
        };
        let a = chartsData;
        a.push(data);
        setChartsData(a);
      }
      setChartsDataLoad(false);
    }
    //eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!chartsDataLoad) {
      let chartRow = [];
      const num = chartsData.length;

      // Iterate through the images and create rows of four images each
      for (let i = 0; i < num; i++) {
        chartRow.push(chartsData[i]);
        if ((i + 1) % 2 === 0 || i === num - 1) {
          let ar = chartRows;
          ar.push(chartRow);
          setChartRows(ar);
          chartRow = [];
        }
      }
      setChartsDataLoad(true);
    }
    //eslint-disable-next-line
  }, [chartsDataLoad]);

  useEffect(() => {
    if (!loading) {
      for (let i = 0; i < dashData.typeArray.length; i++) {
        const data = {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: `Total Msgs containing ${
                dashData.typeArray[i].type === "MSG"
                  ? "text messages"
                  : dashData.typeArray[i].type === "NUMBER"
                  ? "numbers"
                  : dashData.typeArray[i].type === "ALPHABET"
                  ? "alphabets"
                  : "shapes"
              } in 2023`,
              data: dashData.typeArray[i].charArray,
              fill: true,
              borderColor: "#9b9b9b",
              backgroundColor: "#d5d5d5",
              pointBorderColor: "#194FB7",
              pointBackgroundColor: "#194FB7",
              tension: 0.4,
            },
          ],
        };
        let a = typesData;
        a.push(data);
        setTypesData(a);
      }
      setTypesDataLoad(false);
    }
    //eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!typesDataLoad) {
      let chartRow = [];
      const num = typesData.length;

      // Iterate through the images and create rows of four images each
      for (let i = 0; i < num; i++) {
        chartRow.push(typesData[i]);
        if ((i + 1) % 2 === 0 || i === num - 1) {
          let ar = typeRows;
          ar.push(chartRow);
          setTypeRows(ar);
          chartRow = [];
        }
      }
      setTypesDataLoad(true);
    }
    //eslint-disable-next-line
  }, [typesDataLoad]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="min-h-screen flex flex-col justify-between">
      <Header />
      <div className="bg-login bg-cover py-20 flex flex-grow flex-col md:gap-y-20 sm:gap-y-10 min-h-full justify-center items-center">
        <div className="w-full flex justify-center">
          <div className="bg-neutral-100 sm:p-5 md:p-10 rounded-xl shadow-2xl sm:w-5/6 sm:h-full md:h-auto md:w-1/2 mt-8 items-center justify-center flex">
            <Line data={yearlyData} options={yearlyOptions} />
          </div>
        </div>
        {chartRows.length > 0 &&
          chartRows.map((Row, rowIndex) => (
            <div
              key={rowIndex}
              className="w-full flex sm:flex-col sm:justify-center sm:items-center md:flex-row gap-x-5 px-5"
            >
              {Row.map((chart) => (
                <div className="bg-neutral-100 sm:p-5 md:p-10 rounded-xl shadow-2xl sm:w-5/6 sm:h-full md:w-1/2 flex justify-center items-center mt-8">
                  <Line data={chart} options={yearlyOptions} />
                </div>
              ))}
            </div>
          ))}
        {typeRows.length > 0 &&
          typeRows.map((Row, rowIndex) => (
            <div
              key={rowIndex}
              className="w-full flex sm:flex-col md:flex-row gap-x-5 px-5 sm:justify-center sm:items-center"
            >
              {Row.map((chart) => (
                <div className="bg-neutral-100 sm:p-5 md:p-10 rounded-xl shadow-2xl sm:w-5/6 sm:h-full md:w-1/2 flex justify-center items-center mt-8">
                  <Line data={chart} options={yearlyOptions} />
                </div>
              ))}
            </div>
          ))}
      </div>
      <SmallFooter />
    </div>
  );
};

AdminDashbboard.propTypes = {
  dashData: PropTypes.array,
  loading: PropTypes.bool,
  getAdminDashData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashData: state.messages.dashData,
  loading: state.messages.dashLoading,
});

export default connect(mapStateToProps, { getAdminDashData })(AdminDashbboard);

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const blacklist = [
  "/msg-template",
  "/qr-page",
  "/template-msg",
  "/msgs-location",
  "/location/change-password",
  "/custom-msg/:LocationId",
  "/alphabets",
  "/numbers",
  "/shapes",
];

const InActivityMoniter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(null);

  // Function to start the timer
  const startTimer = () => {
    clearTimeout(timerRef.current);
    const newTimer = setTimeout(logout, 35 * 60 * 1000); // 10 minutes in milliseconds
    setTimer(newTimer);
  };

  // Function to handle logout
  const logout = () => {
    // Perform your logout logic here
    navigate("/profiles");
    //   console.log("User has been logged out due to inactivity.");
  };

  useEffect(() => {
    let reset = false;
    for (const path of blacklist) {
      if (path === location.pathname) {
        if (localStorage.getItem("user")) {
          if (localStorage.getItem("user") === "LOCATION") {
            reset = true;
          }
        }
      }
    }

    if (!reset) {
      return;
    }

    startTimer();

    // Reset the timer when there is user activity
    const resetTimerOnActivity = () => {
      clearTimeout(timer);
      startTimer();
    };

    // Add event listeners for user activity (e.g., mousemove and keydown)
    window.addEventListener("mousemove", resetTimerOnActivity);
    window.addEventListener("keydown", resetTimerOnActivity);

    // Cleanup the event listeners when the component unmounts
    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", resetTimerOnActivity);
      window.removeEventListener("keydown", resetTimerOnActivity);
    };
    //eslint-disable-next-line
  }, [location.pathname]);

  return <>{children}</>;
};

export default InActivityMoniter;

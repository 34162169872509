import { SET_CONNECTION } from "../tasks/types";

const initialState = {
   connection: false,
};

export default function foo(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case SET_CONNECTION:
         return {
            ...state,
            connection: payload.connection,
         };
      default:
         return state;
   }
}

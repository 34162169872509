import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdMailOutline } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { RiLockPasswordLine } from "react-icons/ri";

import { setAlert } from "../../tasks/alertT";
import CustomModal from "../../components/Modal";
import { login, logout } from "../../tasks/authT";
import { setConn } from "../../tasks/connectionT";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import TextInput from "../../components/TextInputLogos";
// import { useWindowSize } from "../../Hooks/useWindowSize";
import logo from "../../Assets/images/Write Message 1.svg";
import useSocket from "../../Hooks/useSocket";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { LOCAL_SERVER } from "../../utils/URL";

function Login({
  isAuthenticated,
  user,
  login,
  setAlert,
  logout,
  authError,
  setConn,
}) {
  // const { Width } = useWindowSize();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const { error, myRes, emitEvent, emptyRes } = useSocket();

  const { email, password } = formdata;

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password, setLoading);
    setLoading(true);
  };

  if (user) {
    if (user.role === "ADMIN") {
      navigate("/admin-dash");
    } else if (user.role === "OWNER") {
      if (user.verifiedEmail) {
        navigate("/owner-dash");
      } else {
        navigate(`/verify/${false}`);
      }
    } else if (user.role === "FRANCHISE") {
      navigate("/franchise/mylocs");
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === "LOCATION") {
        emitEvent("login", { locationId: user.id });
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (myRes) {
      console.log(myRes);
      setConn(true);
      emptyRes();
      navigate("/profiles");
    } else if (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.message);
      logout(navigate);
    }
    // eslint-disable-next-line
  }, [error, myRes]);

  useEffect(() => {
    console.log("setting false");
    if (authError) {
      setLoading(false);
      toast.error(authError);
    }
    //eslint-disable-next-line
  }, [loading]);

  const handlePaswordReset = async (e) => {
    try {
      await axios.put(LOCAL_SERVER + `api/auth/email/forgotPassword/${email}`);
      toast.success("An email with new info has been sent to your account!");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        console.log(error.message);
        toast.error(error.message);
      }
    }
  };

  const onClose = () => {
    setModal(false);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="flex h-screen w-screen flex-col overflow-x-hidden">
      <Header />

      <>
        <div className=" flex justify-center items-center h-full w-full bg-login bg-cover md:px-20">
          <ToastContainer />
          <CustomModal closeBtn={true} isOpen={modal} onClose={onClose}>
            <div className="flex flex-row p-16">
              <div className="w-[500px] gap-y-10 flex flex-col justify-center items-center">
                <h1 className="text-5xl font-semibold text-primary">
                  Provide Your email
                </h1>
                <h1 className="text-xl font-medium text-center text-neutral-800">
                  Your password will be reset and an email will sent to your
                  inbox.
                </h1>
                <TextInput
                  icon={MdMailOutline}
                  type="email"
                  value={email}
                  required={true}
                  placeholder="johndoe@gmail.com"
                  hidden={false}
                  name={"email"}
                  onChange={handleChange}
                />
                <button
                  onClick={(e) => handlePaswordReset(e)}
                  className="bg-primary text-2xl text-white px-5 py-1.5 rounded-md font-semibold"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </CustomModal>

          <div className="bg-white flex justify-center items-center rounded-md shadow-2xl md:p-20 sm:w-5/6  md:w-2/4 h-4/5">
            <form
              className="flex flex-col w-full gap-y-10 justify-center items-center"
              onSubmit={(e) => onSubmit(e)}
            >
              <img src={logo} alt="logo" className="my-5" />
              <div className="flex flex-col w-3/4 gap-y-5">
                <TextInput
                  icon={MdMailOutline}
                  type="email"
                  value={email}
                  required={true}
                  placeholder="johndoe@gmail.com"
                  hidden={false}
                  name={"email"}
                  onChange={handleChange}
                />
                <TextInput
                  icon={RiLockPasswordLine}
                  type="password"
                  value={password}
                  required={true}
                  placeholder="*****"
                  hidden={true}
                  name={"password"}
                  onChange={handleChange}
                />
              </div>
              <h3
                className="text-neutral-700 text-sm hover:text-primary hover:cursor-pointer duration-200"
                onClick={(e) => setModal(true)}
              >
                Forgot Password?
              </h3>
              <input
                type="submit"
                className="bg-primary px-5 py-2 text-white text-base rounded-lg hover:bg-blue-500 hover:cursor-pointer"
              />
            </form>
          </div>
        </div>
      </>

      <SmallFooter />
    </div>
  );
}

Login.propTypes = {
  // loading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  authError: PropTypes.string,
  setConn: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  // loading: state.auth.loading,
  user: state.auth.user,
  authError: state.auth.error,
});

export default connect(mapStateToProps, { login, logout, setAlert, setConn })(
  Login
);

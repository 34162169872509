import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../../components/Spinner";
import { loadMessages } from "../../tasks/messageT";
import Header from "../../components/Header";
import SmallFooter from "../../components/SmallFooter";
import Table from "../../components/Table";
import { useState } from "react";

const COLUMNS = [
   {
      Header: "Id",
      accessor: "id",
   },
   {
      Header: "Type",
      accessor: "type",
   },
   {
      Header: "Messenger",
      accessor: "messenger",
   },
   {
      Header: "Message",
      accessor: "msg",
   },
   // {
   //    Header: "Guest Email",
   //    accessor: "guestEmail",
   // },
   {
      Header: "Location Id",
      accessor: "LocationId",
   },
];

const OwnerReports = ({ loading, msgs, loadMessages }) => {
   const [numberOptions] = useState([10, 15, 20, 30]);
   const columns = useMemo(() => COLUMNS, []);
   const data = useMemo(
      () => msgs,
      // eslint-disable-next-line
      [loading]
   );
   useEffect(() => {
      loadMessages();
   }, [loadMessages]);
   // const onclick = (e) => {
   //    console.log(e.id);
   // };
   const eventHandler=()=>{}
   return loading ? (
      <Spinner />
   ) : (
      <div className="w-screen h-screen flex flex-col flex-grow overflow-x-hidden">
         <Header />
         <div className=" flex h-full flex-col justify-between py-5 overflow-y-scrool">
            {msgs ? (
               <Table
                  columns={columns}
                  data={data}
                  numberOptions={numberOptions}
                  eventHandler={eventHandler}
               />
            ) : (
               <h1> No data</h1>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

OwnerReports.propTypes = {
   loading: PropTypes.bool,
   msgs: PropTypes.array,
   loadMessages: PropTypes.func.isRequired,
};

const mapStateToProops = (state) => ({
   loading: state.messages.loading,
   msgs: state.messages.msgs,
});

export default connect(mapStateToProops, { loadMessages })(OwnerReports);

import axios from "axios";
import { FIGURES, FIGURES_ERROR, FIGURES_INIT_STATE } from "./types";
// import { setAlert } from "./alertT";
import { LOCAL_SERVER } from "../utils/URL";
import { setAlert } from "./alertT";

export const loadANS = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/anss/");
      dispatch({
         type: FIGURES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: FIGURES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const loadShapes = () => async (dispatch) => {
   try {
      const res = await axios.get(LOCAL_SERVER + "api/anss/shapes/");
      dispatch({
         type: FIGURES,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: FIGURES_ERROR,
         payload: {
            msg: error.response.data.message,
            status: error.response.status,
         },
      });
   }
};

export const addShape = (formdata, navigate, where) => async (dispatch) => {
   try {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      };

      const res = await axios.post(
         LOCAL_SERVER + `api/anss/`,
         formdata,
         config
      );
      console.log(res);

      dispatch(setAlert("SVG Added!", "success"));
      dispatch({
         type: FIGURES_INIT_STATE,
      });
      navigate(where);
   } catch (err) {
      console.log(err.response.data.message);
      const errors = err.response.data.message;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: FIGURES_ERROR,
         payload: {
            msg: err.response.data.message,
            status: err.response.status,
         },
      });
   }
};

export const removeShape = (id, navigate, where) => async (dispatch) => {
   try {
      const con = window.confirm(
         "Are you sure you want to delete this profile?"
      );
      if (con) {
         await axios.delete(LOCAL_SERVER + `api/anss/${id}`);
         dispatch({
            type: FIGURES_INIT_STATE,
         });
         dispatch(setAlert("Shape Removed!", "success"));
         navigate(where);
      }
   } catch (err) {
      console.log(err.response.data.message);
      const errors = err.response.data.message;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: FIGURES_ERROR,
         payload: {
            msg: err.response.data.message,
            status: err.response.status,
         },
      });
   }
};

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";

import { loadLocMessages } from "../../tasks/messageT";
import SmallFooter from "../../components/SmallFooter";
import Spinner from "../../components/Spinner";
import useSocket from "../../Hooks/useSocket";
import Header from "../../components/Header";
import Toast from "../../components/Toast";
import Table from "../../components/Table";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const COLUMNS = [
   {
      Header: "Id",
      accessor: "id",
   },
   {
      Header: "Type",
      accessor: "type",
   },
   {
      Header: "Messenger",
      accessor: "messenger",
   },
   {
      Header: "Message",
      accessor: "msg",
   },
   {
      Header: "Profile",
      accessor: "profileName",
   },
   
   {
      Header: "Time",
      accessor: "createdAt",
      Cell: ({ value }) => {
         return format(new Date(value), "dd/MM/yyyy hh:mm");
      },
   },
];

const Messages = ({ loading, msgs, loc, loadLocMessages }) => {
   const [numberOptions] = useState([10, 15, 20, 30]);
   const { error, myRes, emptyRes } = useSocket();
   const navigate = useNavigate();

   useEffect(() => {
      loadLocMessages();
   }, [loadLocMessages]);

   const columns = useMemo(() => COLUMNS, []);
   const data = useMemo(
      () => msgs,
      // eslint-disable-next-line
      [loading]
   );

   useEffect(() => {
      if (error) {
         toast.error(error.message);
         // setLoadSpin(false);
         console.log("error: " + error.message);
      } else if (myRes) {
         if (myRes.message !== "success") {
            console.log(myRes);
            emptyRes();
            navigate(`/msg-box/${loc.id}`);
         }
      }
      // eslint-disable-next-line
   }, [error, myRes]);
const eventHandler=()=>{}
   return loading ? (
      <Spinner />
   ) : (
      <div className="w-screen h-screen flex flex-col overflow-hidden">
         <Header />
         <Toast />
         <div className="pb-0 flex flex-col justify-between flex-grow py-10 overflow-hidden">
            {msgs ? (
               <Table
                  columns={columns}
                  data={data}
                  numberOptions={numberOptions}
                  eventHandler={eventHandler}
               />
            ) : (
               <div className="h-full w-full justify-center items-center flex pt-44">
                  <h1 className="text-3xl text-primary font-semibold font-maven">
                     Data Not available
                  </h1>
               </div>
            )}
         </div>
         <SmallFooter />
      </div>
   );
};

Messages.propTypes = {
   loading: PropTypes.bool,
   msgs: PropTypes.array,
   loc: PropTypes.object,
   loadLocMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.messages.loading,
   msgs: state.messages.msgs,
   loc: state.auth.user,
});

export default connect(mapStateToProps, { loadLocMessages })(Messages);

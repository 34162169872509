import React, { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";

const TextInput = ({
  name,
  placeholder,
  type,
  value,
  onChange,
  required,
  hidden,
  classnames,
}) => {
  const [hide, setHide] = useState(true);

  const onHideClicked = (e) => {
    setHide(!hide);
  };
  return (
    <>
      <div className="flex flex-row w-full px-3 py-2 bg-white justify-center items-center border-neutral-400 border-2 rounded-md focus:outline-2 focus:outline-primary">
        <input
          type={hidden ? (hide ? type : "text") : type}
          required={required}
          onChange={onChange}
          name={name}
          value={value}
          placeholder={placeholder}
          className={`flex w-full outline-none placeholder:text-neutral-600 ${classnames}`}
        />
        {hidden ? (
          hide ? (
            <BiShow onClick={(e) => onHideClicked(e)} className="h-5 w-5" />
          ) : (
            <BiHide onClick={(e) => onHideClicked(e)} className="h-5 w-5" />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TextInput;

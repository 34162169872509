import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadAllLocations } from "../../tasks/locationT";
import SmallFooter from "../../components/SmallFooter";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import Toast from "../../components/Toast";
import Table from "../../components/Table";

const COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "userName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Owner Id",
    accessor: "UserId",
  },
];

const Stores = ({ loading, locations, loadAllLocations }) => {
  const [numberOptions] = useState([10, 15, 20, 30]);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(
    () => locations,
    // eslint-disable-next-line
    [loading]
  );
  useEffect(() => {
    loadAllLocations();
  }, [loadAllLocations]);
  const eventHandler = () => {};
  return loading ? (
    <Spinner />
  ) : (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <Toast />
      <div className="h-full overflow-y-scroll overflow-x-hidden  flex flex-grow py-5">
        {/* {msgs ? ( */}
        <Table
          columns={columns}
          data={data}
          numberOptions={numberOptions}
          eventHandler={eventHandler}
        />
        {/* ) : (
               <h1> No data</h1>
            )} */}
      </div>
      <SmallFooter />
    </div>
  );
};

Stores.propTypes = {
  loading: PropTypes.bool,
  locations: PropTypes.array,
  loadAllLocations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.locations.loading,
  locations: state.locations.locations,
});

export default connect(mapStateToProps, { loadAllLocations })(Stores);

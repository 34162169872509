import axios from "axios";
import {
   LOGIN_FAILED,
   LOGIN_SUCCESS,
   USER_LOADED,
   SET_LOADING,
   LOGOUT,
   AUTH_ERROR,
   USER_ERROR,
} from "./types";
import { setAlert } from "./alertT";
import setAuthToken from "../utils/setAuthToken";
import { LOCAL_SERVER } from "../utils/URL";

export const setLoading = (loading) => (dispatch) => {
   dispatch({
      type: SET_LOADING,
      payload: { loading },
   });
};

// User Login
export const login = (email, password, setLoading) => async (dispatch) => {
   try {
      setLoading(true);
      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };

      const body = JSON.stringify({ email, password });

      const res = await axios.post(LOCAL_SERVER + "api/auth/", body, config);

      dispatch({
         type: LOGIN_SUCCESS,
         payload: res.data,
      });
      // dispatch(setAlert(res.data.message, "success"));

      if (localStorage.token) {
         setAuthToken(localStorage.token);
      }
   } catch (err) {
      setLoading(false);
      if (err.response) {
         const errors = err.response.data.message;
         if (errors) {
            dispatch(setAlert(errors, "danger"));
            dispatch({
               type: AUTH_ERROR,
               payload: errors,
            });
         }
      } else {
         dispatch(setAlert(err.message, "danger"));
      }
   }
};

export const loadUser = () => async (dispatch) => {
   try {
      const role = localStorage.getItem("user");
      const res = await axios.get(LOCAL_SERVER + `api/auth/self/${role}`);
      dispatch({
         type: USER_LOADED,
         payload: res.data,
      });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: USER_ERROR,
            payload: error.response.data.message,
         });
      } else {
         dispatch({
            type: USER_ERROR,
            payload: error.message,
         });
         // dispatch(setAlert(error.message, "danger"));
      }
   }
};

//owner signup
export const addUser = (formdata, navigate, setLoading) => async (dispatch) => {
   try {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      };

      await axios.post(LOCAL_SERVER + `api/users/userSignUp`, formdata, config);

      dispatch(
         setAlert(
            "Your account is created please Login to continue!",
            "success"
         )
      );
      setLoading(false);
      navigate("/login");
   } catch (err) {
      const errors = err.response.data.message;
      if (errors) {
         setLoading(false);
         console.log(errors);
         dispatch(setAlert(errors, "danger"));
      }
      dispatch({
         type: AUTH_ERROR,
         payload: {
            msg: errors,
            status: err.response.status,
         },
      });
   }
};

// Change Password
export const changePassword = (passwordOld, password) => async (dispatch) => {
   const config = {
      headers: {
         "Content-Type": "application/json",
      },
   };

   const body = JSON.stringify({ password, passwordOld });
   try {
      await axios.put(
         "https://simphoneserver.alliancetechltd.com/api/users/changepassword",
         body,
         config
      );

      dispatch(
         setAlert(
            "Passsword Changed Successfully. You Have to login again!",
            "success"
         )
      );
      dispatch({
         type: LOGOUT,
      });
   } catch (err) {
      const errors = err.response.data;
      if (errors) {
         dispatch(setAlert(errors, "danger"));
         dispatch({
            type: AUTH_ERROR,
         });
      }
      dispatch({
         type: LOGIN_FAILED,
      });
   }
};

export const logout = (role, emitEvent, locationId) => (dispatch) => {
   if (role === "LOCATION") {
      emitEvent("logout", { locationId: locationId });
   }
   localStorage.removeItem(localStorage.token);
   setAuthToken(null);
   dispatch({
      type: LOGOUT,
   });
   // navigate("/");
};
